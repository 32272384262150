import { FC, useEffect, useRef, useState } from "react";
import { useStyles } from "./ShareholderBoardResolution.styles";
import { Button } from "@funded-here-interface/common";
import { Center, Loader, Text } from "@mantine/core";
import VQRVerifyPage from "../../components/VQR/VQRVerifyPage";
import useCompleteOnboarding from "../../hooks/useCompleteOnboarding";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";
import { REST_SERVER, WEB_DOMAIN } from "../../constants/domain";
import { useBoardResolutionSign } from "../../hooks/useDocuSign";
import DocViewer from "@cyntler/react-doc-viewer";
import { useDisclosure } from "@mantine/hooks";
import SignQRModal from "../../components/SignQRModal/SignQRModal";
import useSignedDocument from "../../hooks/useSignedDocument";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

const DirectorBoardResolution: FC = () => {
  const { classes } = useStyles();
  const location = useLocation();
  const skipDidVerify = location.state?.skipDidVerify;
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    skipDidVerify ?? false
  );
  const useCompleteOnboardingMutation = useCompleteOnboarding();
  const { token, orgId, userEmail } = useSelector(
    (state: RootState) => state.auth
  );
  const navigate = useNavigate();
  const boardResolutionMutation = useBoardResolutionSign();
  const signedDocument = useSignedDocument();
  const [directCreateDocusign, setDirectCreateDocusign] =
    useState<boolean>(false);

  const [realUrl, setRealUrl] = useState("");
  const [documentId, setDocumentId] = useState(0);
  const [completedAt, setCompletedAt] = useState("");

  const [opened, { open, close }] = useDisclosure(false);

  const docs = [
    {
      uri: realUrl,
      fileType: "pdf",
    },
  ];

  const [viewerKey, setViewerKey] = useState(0);

  const getBoardResolutionDoc = () => {
    toast.info("Document is loading");

    boardResolutionMutation.mutate(
      {
        token,
        webDomain: WEB_DOMAIN,
        redirectUrl: `${REST_SERVER}/document-management/docusign/webhook`,
        directCreateDocusign,
      },
      {
        onSuccess: (data) => {
          setRealUrl(data.url);
          setDocumentId(data.documentId);
          setCompletedAt(data.completedAt);
        },
        onError: (e) => {
          toast.error((e as Error).message);
        },
      }
    );
  };

  useEffect(() => {
    // loadDocuSignScript();
    if (skipDidVerify) {
      getBoardResolutionDoc();
      setViewerKey((prevKey) => prevKey + 1);
    }
  }, []);

  useEffect(() => {
    if (directCreateDocusign) {
      getBoardResolutionDoc();
    }
  }, [directCreateDocusign]);

  const handleNext = () => {
    useCompleteOnboardingMutation.mutate(
      { token },
      {
        onSuccess: () => {
          // if (ENV === "production") {
          //   navigate("/registration-completed");
          // } else {
          toast.success("Document Signed Successfully");
          // }
        },
        onError: (e) => {
          toast.error((e as Error).message);
        },
      }
    );
  };

  const moveNext = () => {
    navigate("/registration-completed");
  };

  const handleSign = (isAuthenticated: boolean) => {
    if (isAuthenticated) {
      close();
      setRealUrl("");
      signedDocument.mutate(
        { documentId, token },
        {
          onSuccess: (data) => {
            // Trigger complete onboarding
            useCompleteOnboardingMutation.mutate(
              { token },
              {
                onSuccess: () => {
                  toast.success("Document Signed Successfully");
                  setRealUrl(data.url);
                  setDocumentId(data.documentId);
                  setCompletedAt(data.completedAt);
                  setViewerKey((prevKey) => prevKey + 1);
                },
                onError: (e) => {
                  toast.error((e as Error).message);
                },
              }
            );
          },
          onError: (e) => {
            toast.error((e as Error).message);
          },
        }
      );
    }
  };

  const navigateNext = () => {
    navigate("/registration-completed");
  };

  const handleAuthenticatedChange = (isAuthenticated: boolean) => {
    setIsAuthenticated(isAuthenticated);
    if (isAuthenticated) {
      getBoardResolutionDoc();
    }
  };

  // incase verify me dont work
  const handleOnVerifyMeNext = () => {
    setIsAuthenticated(true);
  };

  const openModal = () => {
    setViewerKey((prevKey) => prevKey + 1);
    open();
  };

  const closeModal = () => {
    setViewerKey((prevKey) => prevKey + 1);
    close();
  };

  return (
    <>
      <ToastContainer />
      {!isAuthenticated && (
        <VQRVerifyPage
          onAuthenticatedChange={handleAuthenticatedChange}
          onNext={handleOnVerifyMeNext}
        />
      )}

      {isAuthenticated && (
        <>
          <div>
            <Text className={classes.heading}>
              Please complete the online board resolution to authorize the
              designated signatory for document signing
            </Text>

            {realUrl !== "" && (
              <>
                <DocViewer
                  documents={docs}
                  style={{ width: "100%", height: 500, overflow: "auto" }}
                  key={viewerKey}
                  className={classes.docviewer}
                  config={{
                    header: {
                      disableHeader: false,
                      disableFileName: true,
                      retainURLParams: false,
                    },
                    pdfVerticalScrollByDefault: true, // false as default
                  }}
                />

                {completedAt === "" && (
                  <div className={classes.buttonContainer}>
                    <Button
                      onClick={openModal}
                      textColor="white"
                      backgroundColor="#338F86"
                      children="Proceed to Sign"
                    />
                  </div>
                )}

                {completedAt !== "" && (
                  <div className={classes.buttonContainer}>
                    <Button
                      onClick={moveNext}
                      textColor="white"
                      backgroundColor="#338F86"
                      children="Okay"
                    />
                  </div>
                )}
              </>
            )}
            {realUrl == "" && (
              <div>
                <Center maw={400} h={500} mx="auto">
                  <Loader />
                </Center>
              </div>
            )}
          </div>

          <SignQRModal
            opened={opened}
            close={closeModal}
            onSignVerify={handleSign}
          ></SignQRModal>
        </>
      )}
    </>
  );
};

export default DirectorBoardResolution;
