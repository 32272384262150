import { FC, useEffect, useRef, useState } from "react";
import { useStyles } from "./ShareholderSPVBoardResolution.styles";
import { Button } from "@funded-here-interface/common";
import { Center, Loader, Text } from "@mantine/core";
import VQRVerifyPage from "../../components/VQR/VQRVerifyPage";
import useSignedBoardResolution from "../../hooks/useSignedSPVBoardResolution";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { useSpvBoardResolutionSign } from "../../hooks/useDocuSign";
import { REST_SERVER, WEB_DOMAIN } from "../../constants/domain";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";
import { COLORS } from "../../constants/colors";
import { DocuSignEvent, DocuSignEventHandler } from "../../constants/docuSign";
import { ENV } from "@funded-here-interface/common/src/constant/constant";
import { useDisclosure } from "@mantine/hooks";
import useSignedDocument from "../../hooks/useSignedDocument";
import DocViewer from "@cyntler/react-doc-viewer";
import SignQRModal from "../../components/SignQRModal/SignQRModal";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

const DirectorSPVBoardResolution: FC = () => {
  const { classes } = useStyles();
  const location = useLocation();
  const skipDidVerify = location.state?.skipDidVerify;
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    skipDidVerify ?? false
  );
  const useSignedBoardResolutionMutation = useSignedBoardResolution();
  const { token, orgId, userEmail } = useSelector(
    (state: RootState) => state.auth
  );
  const navigate = useNavigate();
  const spvBoardResolutionMutation = useSpvBoardResolutionSign();
  const signedDocument = useSignedDocument();
  const boardResolutionRef = useRef(null);
  const [directCreateDocusign, setDirectCreateDocusign] =
    useState<boolean>(false);

  const [realUrl, setRealUrl] = useState("");
  const [documentId, setDocumentId] = useState(0);
  const [completedAt, setCompletedAt] = useState("");

  const [opened, { open, close }] = useDisclosure(false);
  const docs = [
    {
      uri: realUrl,
      fileType: "pdf",
    },
  ];

  const [viewerKey, setViewerKey] = useState(0);

  const getSpvBoardResolutionDoc = () => {
    toast.info("Document is loading");
    spvBoardResolutionMutation.mutate(
      {
        token,
        webDomain: WEB_DOMAIN,
        redirectUrl: `${REST_SERVER}/document-management/docusign/webhook`,
        directCreateDocusign,
      },
      {
        onSuccess: async (data) => {
          // if (ENV === "production") {
          //   setDirectCreateDocusign(false);
          //   // const { url, docuSignEnvelopeId } = data;
          //   // Handle error if docusign not create document
          //   if (data.docuSignEnvelopeId === "Source/Unique id not found") {
          //     setDirectCreateDocusign(true);
          //     return;
          //   }

          //   let url = "";
          //   for (const signerData of data.signerUrls) {
          //     if (signerData.email.toLowerCase() == userEmail?.toLowerCase()) {
          //       url = signerData.url;
          //       break;
          //     }
          //   }

          //   window.DocuSign.loadDocuSign(data.docuSignEnvelopeId)
          //     .then((docusign: any) => {
          //       const signing = docusign.signing({
          //         url: url,
          //         displayFormat: "focused",
          //         style: {
          //           branding: {
          //             primaryButton: {
          //               backgroundColor: COLORS.primaryButtonBackground,
          //               color: COLORS.primaryButtonText,
          //             },
          //           },
          //           signingNavigationButton: {
          //             finishText: "Finish Signing",
          //             position: "bottom-center",
          //           },
          //         },
          //       });
          //       signing.on(DocuSignEventHandler.READY, () => {
          //         toast.info("Sign the Document using E-signature");
          //       });

          //       signing.on(
          //         DocuSignEventHandler.SESSION_END,
          //         async (event: any) => {
          //           if (
          //             event.sessionEndType === DocuSignEvent.SIGNING_COMPLETE
          //           ) {
          //             navigate("/registration-completed");
          //             // await handleNext();
          //           } else {
          //             toast.error("Signing incomplete, please try again.");
          //           }
          //         }
          //       );

          //       if (boardResolutionRef.current) {
          //         signing.mount(boardResolutionRef.current);
          //       } else {
          //         toast.error("Agreement container not found");
          //       }
          //     })
          //     .catch((e: any) => {
          //       const message = getApiResponseErrorMsg(e);
          //       toast.error(message);
          //     });
          // } else {
          setRealUrl(data.url);
          setDocumentId(data.documentId);
          setCompletedAt(data.completedAt);
          // }
        },
        onError: (e) => {
          toast.error((e as Error).message);
        },
      }
    );
  };

  useEffect(() => {
    if (skipDidVerify) {
      getSpvBoardResolutionDoc();
      setViewerKey((prevKey) => prevKey + 1);
    }
  }, []);

  useEffect(() => {
    if (directCreateDocusign) {
      getSpvBoardResolutionDoc();
    }
  }, [directCreateDocusign]);

  const handleNext = () => {
    useSignedBoardResolutionMutation.mutate(
      { token, orgId: orgId as number },
      {
        onSuccess: () => {
          toast.success("Document Signed Successfully");
        },
        onError: (e) => {
          toast.error((e as Error).message);
        },
      }
    );
  };

  const navigateNext = () => {
    navigate("/registration-completed");
  };

  const handleSign = (isAuthenticated: boolean) => {
    if (isAuthenticated) {
      close();
      setRealUrl("");
      signedDocument.mutate(
        { documentId, token },
        {
          onSuccess: (data) => {
            toast.success("Document Signed Successfully");
            setRealUrl(data.url);
            setDocumentId(data.documentId);
            setCompletedAt(data.completedAt);
            setViewerKey((prevKey) => prevKey + 1);
          },
          onError: (e) => {
            toast.error((e as Error).message);
          },
        }
      );
    }
  };

  const handleAuthenticatedChange = (isAuthenticated: boolean) => {
    setIsAuthenticated(isAuthenticated);
    if (isAuthenticated) {
      getSpvBoardResolutionDoc();
    }
  };

  // incase verify me dont work
  const handleOnVerifyMeNext = () => {
    setIsAuthenticated(true);
  };

  const openModal = () => {
    setViewerKey((prevKey) => prevKey + 1);
    open();
  };

  const closeModal = () => {
    setViewerKey((prevKey) => prevKey + 1);
    close();
  };

  return (
    <>
      <ToastContainer />
      {!isAuthenticated && (
        <VQRVerifyPage
          onAuthenticatedChange={handleAuthenticatedChange}
          onNext={handleOnVerifyMeNext}
        />
      )}
      {isAuthenticated && (
        <>
          <div>
            <Text className={classes.heading}>
              Please complete the online board resolution to authorize the
              designated signatory for document signing
            </Text>
            {realUrl !== "" && (
              <>
                <DocViewer
                  documents={docs}
                  style={{ width: "100%", height: 500, overflow: "auto" }}
                  key={viewerKey}
                  className={classes.docviewer}
                  prefetchMethod="GET"
                  config={{
                    header: {
                      disableHeader: false,
                      disableFileName: true,
                      retainURLParams: false,
                    },
                    pdfVerticalScrollByDefault: true, // false as default
                  }}
                />
                {completedAt === "" && (
                  <div className={classes.buttonContainer}>
                    <Button
                      onClick={openModal}
                      textColor="white"
                      backgroundColor="#338F86"
                      children="Proceed to Sign"
                    />
                  </div>
                )}

                {completedAt !== "" && (
                  <div className={classes.buttonContainer}>
                    <Button
                      onClick={navigateNext}
                      textColor="white"
                      backgroundColor="#338F86"
                      children="Okay"
                    />
                  </div>
                )}
              </>
            )}
            {realUrl == "" && (
              <div>
                <Center maw={400} h={500} mx="auto">
                  <Loader />
                </Center>
              </div>
            )}
          </div>

          <SignQRModal
            opened={opened}
            close={closeModal}
            onSignVerify={handleSign}
          ></SignQRModal>
        </>
      )}
    </>
  );
};

export default DirectorSPVBoardResolution;
