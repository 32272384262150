import axios from "axios";
import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";

export interface WithdrawalFundType {
  token: string | null;
  currency: string;
}

export const getWithdrawalFund = async ({
  token,
  currency,
}: WithdrawalFundType) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/sku-finance/withdrawal-fund?currency=${currency}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);

    throw new Error(message);
  }
};

export const confirmWithdrawalFund = async ({
  token,
  currency,
}: WithdrawalFundType) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/sku-finance/confirm-withdrawal-fund`,
      { currency },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );
    return response.data;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);

    throw new Error(message);
  }
};
