import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";
import axios from "axios";

interface GetMerchantDashboardOverallRequest {
  token: string;
}

interface GetMerchantDashboardOverallResponse {
  fundingLimit: string;
  loanAmount: string;
  repaidAmount: string;
}

const getMerchantDashboardOverall = async ({
  token,
}: GetMerchantDashboardOverallRequest): Promise<GetMerchantDashboardOverallResponse> => {
  try {
    const response = await axios.get(
      `${BASE_URL}/sku-finance/merchant-dashboard-overall`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );

    return response.data;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);

    throw new Error(message);
  }
};

export default getMerchantDashboardOverall;
