import React, { useEffect } from "react";
import GraphAmountSec from "../components/GraphAmountSec";
import ReturnAnalysis from "../components/ReturnAnalysis";
import DataTableown from "../components/DataTableown";
import HttpClientXml from "../../../../Utils/HttpClientXmInv";
import "./dashboard.css";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store";

async function loginDashBoard(token: any) {
  await HttpClientXml.loginAs(token);
}
const InvestorDashboard = () => {
  const token = useSelector((state: RootState) => state.auth.token);
  useEffect(() => {
    loginDashBoard(token);
  }, []);

  return (
    <div>
      <div className="RestPage">
        <div className="container-fluid">
          <div className="invertorFirstRow mb-3">
            <GraphAmountSec />
            <ReturnAnalysis />
          </div>
          <div className="row" style={{ marginTop: 28 }}>
            <div className="col">
              <div className="eStateTableDiv">
                <p className="eStateHead">E - Statement</p>
                <div className="dataTableDiv">
                  <DataTableown />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvestorDashboard;
