const protocol = window.location.protocol;
const hostname = window.location.hostname;
const port = window.location.port ? `:${window.location.port}` : "";

export const WEB_DOMAIN = `${protocol}//${hostname}${port}`;
export const REST_SERVER = "https://api-newdev.fundedhere.com";
export const BASE_LUMINOR_ID_URL = "https://luminorid.page.link";

export const REGISTER_LUMINOR_DID_QUERY_ID = "65ae70c88506520061f1e3f9";
export const VERIFY_LUMINOR_DID_QUERY_ID = "65ae77cb8fbddd006121c1a8";
export const QUERY_OWNER_LUMINOR_DID =
  "shareledger19x2d5tkmyvn923wg0sxgp3yxcnluv5ahtl56hh";
export const CLIENT_ID_LUMINOR_DID = "6617836d2d4969cd7e3abed9";
export const PASSPORT_LUMINOR_DID_QUERY_ID = "668cdf1b931975154101e3ed";
export const QUERY_NEW_OWNER_LUMINOR_DID =
  "shareledger1dq9x4numzw4trnc3hvw56hn5pytp3km9lutt49";
