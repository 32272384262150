import moneyimg1 from "@funded-here-interface/common/src/dashboard/Images/Moneyimg1.PNG";
import moneyimg2 from "@funded-here-interface/common/src/dashboard/Images/Moneyimg2.PNG";
import { useSelector } from "react-redux";
import useVaBalance from "../../../hooks/useVaBalance";
import { toast } from "react-toastify";
import useMerchantDashboardOverall from "../../../hooks/useMerchantDashboardOverall";
import { Box, LoadingOverlay } from "@mantine/core";

const Graphamount = () => {
  const token = useSelector((state) => state.auth.token);
  const { data: vaBalance = {}, error, isloading } = useVaBalance(token);
  const {
    data: merchantOverall = {},
    error: merchantOverallError,
    isLoading: merdchantOverallLoading,
  } = useMerchantDashboardOverall(token);

  if (error || merchantOverallError) {
    toast.error(error?.message || merchantOverallError?.message);
  }

  const loop = [
    {
      id: 1,
      head: "Funding Limit",
      num: merchantOverall?.fundingLimit ?? "0",
    },
    { id: 2, head: "Loan Amount", num: merchantOverall?.loanAmount ?? "0" },
    { id: 3, head: "Repaid Amount", num: merchantOverall?.repaidAmount ?? "0" },
    {
      id: 4,
      head: "Balance Amount",
      num: merchantOverall?.balanceAmount ?? 0,
    },
  ];

  const loop2 = [
    { id: 1, head: "Qualifying Capital", num: vaBalance?.qc ?? "0" },
    { id: 2, head: "Sinking Fund", num: vaBalance?.sf ?? "0" },
    {
      id: 3,
      head: "Additional Sinking Fund",
      num: vaBalance?.asf ?? "0",
    },
  ];

  return (
    <>
      <Box className="graphAmtSec" pos="relative">
        <LoadingOverlay visible={merdchantOverallLoading || isloading} />

        <div className="graphAmtDiv">
          <div className="graphInner">
            {loop.map((details, index) => (
              <div className="graphAmtDivInn" key={index}>
                <h5 className="graphPoint">{details.head}</h5>
                <p className="graphNum">{details.num}</p>
              </div>
            ))}
          </div>
          <figure className="graphamtFig">
            <img src={moneyimg1} alt="img" />
          </figure>
        </div>

        <div className="graphAmtDiv">
          <div className="graphInner">
            {loop2.map((details, index) => (
              <div className="graphAmtDivInn" key={index}>
                <h5 className="graphPoint">{details.head}</h5>
                <p className="graphNum">{details.num}</p>
              </div>
            ))}
          </div>
          <figure className="graphamtFig">
            <img src={moneyimg2} alt="img" />
          </figure>
        </div>
      </Box>
    </>
  );
};

export default Graphamount;
