import HttpClientXml from "../Utils/HttpClientXml";

const getAllSKUdropdown = async (token) => {
  let endPoint = "view-drop-down-all-sku";
  return HttpClientXml.get(endPoint, undefined, token);
};

const dailySalesReport = async (data, id, token) => {
  // let endPoint = `sales-comparison-report-inventory-turn-over-type-byMarchent/${id}`
  let endPoint = "daily-sales-report-bySku";
  return HttpClientXml.post(endPoint, data, token);
};

const amountdue = async (token) => {
  // let endPoint = `sales-comparison-report-inventory-turn-over-type-byMarchent/${id}`
  let endPoint = "ammout-due-analysis-report-byMarchant";
  return HttpClientXml.get(endPoint, undefined, token);
};

const activePayment = async (token) => {
  let endPoint = "view-payment-tracking-byMarchent";
  return HttpClientXml.post(endPoint, undefined, token);
};

const closedPayment = async (token) => {
  try {
    const requestBody = {
      status: "closed",
    };
    const res = await HttpClientXml.post(
      "view-payment-tracking-byMarchent",
      requestBody,
      token
    );
    return res;
  } catch (error) {
    // Handle any errors
    throw error;
  }
};

const defaultPayment = async (id, token) => {
  let endPoint = `merchant-default-payment-in-merchant-summary/${id}`;
  return HttpClientXml.get(endPoint, undefined, token);
};

export default {
  getAllSKUdropdown,
  dailySalesReport,
  amountdue,
  activePayment,
  defaultPayment,
  closedPayment,
};
