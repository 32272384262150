import React from "react";
import uploadIcon from "../../assets/cloud_upload_24px.png";
import { Text } from "@mantine/core";
import { Dispatch, SetStateAction, useState } from "react";
import { UploadOptions } from "@funded-here-interface/investor-onboarding/src/types/uploadtype";
import { color } from "../../constant/color";

type FileUploadComponentProps = {
  fileId: string;
  classes: any; // You should replace 'any' with the actual type for classes
  uploadedFile: string;
  setSelectedFile: React.Dispatch<SetStateAction<File | null>>;
  setUploadedFiles: Dispatch<SetStateAction<{ [key: string]: File }>>;
  token: string | null;
  onChangeHandler: ({ e, fileId, token }: UploadOptions) => Promise<void>;
  subText?: string;
};

function FileUploadText({
  classes,
  fileId,
  uploadedFile,
  setSelectedFile,
  setUploadedFiles,
  token,
  onChangeHandler,
  subText,
}: FileUploadComponentProps) {
  const [selectedFiles, setSelectedFiles] = useState<{
    [key: string]: File[] | null;
  }>({});
  const onchangeFunc = (
    e: React.ChangeEvent<HTMLInputElement>,
    fileId: string,
    setSelectedFile: React.Dispatch<SetStateAction<File | null>>,
    setUploadedFiles: Dispatch<SetStateAction<{ [key: string]: File }>>,
    token: string | null
  ) => {
    onChangeHandler({
      e,
      fileId,
      setSelectedFile,
      setUploadedFiles,
      token,
    });
  };

  return (
    <div style={{ display: "flex", alignItems: "center", marginBottom: 20 }}>
      <div className={classes.uploadButtonContainer}>
        <label
          htmlFor={fileId}
          style={{
            display: "block",
            width: "210px",
            height: "18px",
            paddingTop: "0px",
            paddingLeft: "0px",
            fontSize: "16px",
            fontWeight: 400,
            color: color.FHGREEN,
            cursor: "pointer",
          }}
        >
          <input
            type="file"
            name={fileId}
            id={fileId}
            accept=".csv"
            style={{ display: "none" }}
            onChange={(e) => {
              if (e.target.files && e.target.files.length > 0) {
                const selectedFile = e.target.files[0];
                if (selectedFile) {
                  const fileSizeInBytes = selectedFile.size;
                  const fileSizeInKB = fileSizeInBytes / 1024;
                  const fileSizeInMB = fileSizeInKB / 1024;

                  if (fileSizeInMB < 4) {
                    setSelectedFiles({
                      ...selectedFiles,
                      [fileId]: [
                        ...(selectedFiles[fileId] || []),
                        e.target.files[0],
                      ],
                    });
                  }
                }
              }
              onchangeFunc(e, fileId, setSelectedFile, setUploadedFiles, token);
            }}
          />
          <span>{subText}</span>
        </label>
      </div>
      <div>
        {/* Display the uploaded files' names */}
        {selectedFiles[fileId] &&
          selectedFiles[fileId]?.map((file, index) => (
            <p style={{ marginBottom: 0, marginTop: 0 }} key={index}>
              {
                index === 0
                  ? file.name.slice(0, 23)
                  : "..." /* Show only the first file name */
              }
            </p>
          ))}
      </div>
    </div>
  );
}

export default FileUploadText;
