import { ChangeEvent, FC, useEffect, useState, useRef } from "react";
import successIcon from "../../assets/Group 37153.png";
import { Text } from "@mantine/core";
import { AcknowledgementLayout } from "@funded-here-interface/common";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useOtpMutation from "../../hooks/useVerifyOtp";
import { useStyles } from "./OtpVerification.styles";
import useResendOtp from "../../hooks/useResendOtp";

const OtpVerification: FC = () => {
  const location = useLocation();
  const { email } = location.state || {};
  const { classes } = useStyles();
  const [isOtpVerified, setOtpVerified] = useState(false);
  const [otpAttempted, setOtpAttempted] = useState(false);
  const [otp, setOtp] = useState("");
  const [firstDigit, setFirstDigit] = useState("");
  const [secondDigit, setSecondDigit] = useState("");
  const [thirdDigit, setThirdDigit] = useState("");
  const [fourthDigit, setFourthDigit] = useState("");
  const [fifthDigit, setFifthDigit] = useState("");
  const Navigate = useNavigate();
  const mutation = useOtpMutation();
  const useResendOtpMutation = useResendOtp();
  const [countdown, setCountdown] = useState(0);

  const firstDigitRef = useRef<HTMLInputElement>(null);
  const secondDigitRef = useRef<HTMLInputElement>(null);
  const thirdDigitRef = useRef<HTMLInputElement>(null);
  const fourthDigitRef = useRef<HTMLInputElement>(null);
  const fifthDigitRef = useRef<HTMLInputElement>(null);

  const handleInputChange = (
    e: ChangeEvent<HTMLInputElement>,
    nextRef: React.RefObject<HTMLInputElement>
  ) => {
    if (e.target.value && nextRef.current) {
      nextRef.current.focus();
    }
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement>,
    setCurrentValue: React.Dispatch<React.SetStateAction<string>>,
    prevRef?: React.RefObject<HTMLInputElement>
  ) => {
    if (
      (e.key === "Backspace" || e.key === "Delete") &&
      !e.currentTarget.value
    ) {
      if (prevRef && prevRef.current) {
        prevRef.current.focus();
        setCurrentValue("");
      }
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text/plain");
    if (pastedData.length === 5 && /^[0-9]{5}$/.test(pastedData)) {
      const digits = pastedData.split("");
      setFirstDigit(digits[0]);
      setSecondDigit(digits[1]);
      setThirdDigit(digits[2]);
      setFourthDigit(digits[3]);
      setFifthDigit(digits[4]);
    }
  };

  const handleOnSuccessClose = () => {
    Navigate("/login");
  };

  const handleResendOtp = () => {
    if (countdown === 0) {
      useResendOtpMutation.mutate(
        { email },
        {
          onSuccess: () => {
            setCountdown(60);
          },
          onError: (e) => {
            toast.error((e as Error).message);
            setCountdown(60);
          },
        }
      );
    }
  };

  useEffect(() => {
    if (firstDigit && secondDigit && thirdDigit && fourthDigit && fifthDigit) {
      const otp = `${firstDigit}${secondDigit}${thirdDigit}${fourthDigit}${fifthDigit}`;
      setOtpAttempted(false);

      setOtp(otp);
    } else {
      setOtp("");
    }
  }, [firstDigit, secondDigit, thirdDigit, fourthDigit, fifthDigit]);

  useEffect(() => {
    if (otp.length === 5 && !otpAttempted) {
      setOtpAttempted(true);

      mutation.mutate(
        { otp },
        {
          onSuccess: () => {
            setOtpVerified(true);
            setTimeout(() => {
              Navigate("/login");
            }, 4000);
          },
          onError: (e) => {
            toast.error((e as Error).message);
            setOtp("");
          },
        }
      );
    }
  }, [otp, otpAttempted, mutation, Navigate]);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (countdown > 0) {
      timer = setInterval(() => {
        setCountdown((prev) => prev - 1);
      }, 1000);
    }

    return () => clearInterval(timer);
  }, [countdown]);

  return (
    <AcknowledgementLayout
      content={
        !isOtpVerified ? (
          <div className={classes.loginContainer}>
            <Text className={classes.loginHeading}>OTP Verification</Text>
            <ToastContainer />
            <Text className={classes.interaction}>
              We have sent a verification code to your registered <br /> email.
              The code is valid for 5 minutes.
            </Text>
            <Text className={classes.subHeading}>Enter OTP</Text>
            <div className={classes.otpWrapper}>
              <input
                value={firstDigit}
                ref={firstDigitRef}
                maxLength={1}
                pattern="\d*"
                className={classes.otpDigit}
                onChange={(e) => {
                  setFirstDigit(e.target.value);
                  handleInputChange(e, secondDigitRef);
                }}
                onKeyDown={(e) =>
                  handleKeyDown(e, setFifthDigit, firstDigitRef)
                }
                onPaste={handlePaste}
              />
              <input
                value={secondDigit}
                ref={secondDigitRef}
                maxLength={1}
                pattern="\d*"
                className={classes.otpDigit}
                onChange={(e) => {
                  setSecondDigit(e.target.value);
                  handleInputChange(e, thirdDigitRef);
                }}
                onKeyDown={(e) =>
                  handleKeyDown(e, setSecondDigit, firstDigitRef)
                }
              />
              <input
                value={thirdDigit}
                ref={thirdDigitRef}
                maxLength={1}
                pattern="\d*"
                className={classes.otpDigit}
                onChange={(e) => {
                  setThirdDigit(e.target.value);
                  handleInputChange(e, fourthDigitRef);
                }}
                onKeyDown={(e) =>
                  handleKeyDown(e, setThirdDigit, secondDigitRef)
                }
              />
              <input
                value={fourthDigit}
                ref={fourthDigitRef}
                maxLength={1}
                pattern="\d*"
                className={classes.otpDigit}
                onChange={(e) => {
                  setFourthDigit(e.target.value);
                  handleInputChange(e, fifthDigitRef);
                }}
                onKeyDown={(e) =>
                  handleKeyDown(e, setFourthDigit, thirdDigitRef)
                }
              />
              <input
                value={fifthDigit}
                ref={fifthDigitRef}
                maxLength={1}
                pattern="\d*"
                className={classes.otpDigit}
                onChange={(e) => setFifthDigit(e.target.value)}
                onKeyDown={(e) =>
                  handleKeyDown(e, setFifthDigit, fourthDigitRef)
                }
              />
            </div>
            {email && (
              <>
                <Text className={classes.footerText}>
                  Didn’t receive the code?{" "}
                </Text>
                <Text
                  className={
                    countdown > 0 ? classes.disableResend : classes.resendText
                  }
                  onClick={handleResendOtp}
                >
                  {countdown > 0 ? `Resend OTP in ${countdown}s` : "Resend OTP"}
                </Text>
              </>
            )}
          </div>
        ) : (
          <div
            className={classes.verificationContainer}
            style={{ marginTop: "0px", marginLeft: "0px" }}
          >
            <div onClick={handleOnSuccessClose} className={classes.close}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z"
                  fill="black"
                  fillOpacity="0.54"
                />
              </svg>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                height: "100%",
                marginTop: "10px",
              }}
            >
              <Text className={classes.verifiedHeading}>OTP Verification</Text>

              <div className={classes.imgWrapper}>
                <img src={successIcon} alt="" />
              </div>
              <Text className={classes.redirectionMssg}>
                The verification was successful! <br /> Redirecting to the Login
                page.....
              </Text>
            </div>
          </div>
        )
      }
    />
  );
};

export default OtpVerification;
