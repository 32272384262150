import { createStyles } from "@mantine/styles";

export const useStyle = createStyles(() => ({
  headinfo: {
    marginTop: "100px",
    width: "100vw",
    fontSize: "24px",
    fontWeight: 400,
  },

  topDiv: {
    marginTop: "50px",
  },

  topVerifyDiv: {
    marginTop: "100px",
  },

  linkText: {
    color: "#338F86",
    cursor: "pointer",
    fontWeight: "bold",
    width: "full",
  },

  document: {
    background: "white",
    // padding: "20px",
    borderRadius: "5px",
    maxHeight: "80vh",
    overflowY: "auto",
    height: "700px",
  },
  tempWideDisplay: {
    position: "absolute",
    width: "800px",
  },
  buttonContainer: {
    margin: "20px 50px",
    paddingBottom: "50px",
  },
  docviewer: {
    "#msdoc-renderer": {
      height: "450px",
    },

    "#pdf-controls": {
      display: "none",
    },
  },
}));
