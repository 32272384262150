import { createSlice } from "@reduxjs/toolkit";

const bizBorSlice = createSlice({
  name: "borBiz",
  initialState: {
    company: null,
    regNumber: null,
    legalStructure: null,
    legalStructureOthers: null,
    industryGroup: null,
    industryDropdownValues: null,
    typeOfInstitute: null,
    industryOthers: null,
    countryOfIncorp: null,
    countryOfDomicile: null,
    address: null,
    postal: null,
    website: null,
    turnover: null,
    phoneNumber: null,
  },
  reducers: {
    setCompanyRedux: (state, { payload: { company } }) => {
      state.company = company;
    },
    setRegNumberRedux: (state, { payload: { regNumber } }) => {
      state.regNumber = regNumber;
    },
    setLegalStructureRedux: (state, { payload: { legalStructure } }) => {
      state.legalStructure = legalStructure;
    },
    setLegalStructureOthersRedux: (
      state,
      { payload: { legalStructureOthers } }
    ) => {
      state.legalStructureOthers = legalStructureOthers;
    },
    setIndustryGroupRedux: (state, { payload: { industryGroup } }) => {
      state.industryGroup = industryGroup;
    },
    setIndustryDropdownValuesRedux: (state, { payload: { industryDropdownValues } }) => {
      state.industryDropdownValues = industryDropdownValues;
    },
    setIndustryOthersRedux: (state, { payload: { industryOthers } }) => {
      state.industryOthers = industryOthers;
    },
    setTypeOfInstituteRedux: (state, { payload: { typeOfInstitute } }) => {
      state.typeOfInstitute = typeOfInstitute;
    },
    setAddressRedux: (state, { payload: { address } }) => {
      state.address = address;
    },
    setCountryOfIncorpRedux: (state, { payload: { countryOfIncorp } }) => {
      state.countryOfIncorp = countryOfIncorp;
    },
    setCountryOfDomicileRedux: (state, { payload: { countryOfDomicile } }) => {
      state.countryOfDomicile = countryOfDomicile;
    },
    setPostalRedux: (state, { payload: { postal } }) => {
      state.postal = postal;
    },
    setWebsiteRedux: (state, { payload: { website } }) => {
      state.website = website;
    },
    setTurnOverRedux: (state, { payload: { turnover } }) => {
      state.turnover = turnover;
    },
    setPhoneNumber: (state, { payload: { phoneNumber } }) => {
      state.phoneNumber = phoneNumber;
    },
  },
});

export const {
  setCompanyRedux,
  setRegNumberRedux,
  setLegalStructureRedux,
  setLegalStructureOthersRedux,
  setTypeOfInstituteRedux,
  setIndustryGroupRedux,
  setIndustryDropdownValuesRedux,
  setIndustryOthersRedux,
  setCountryOfIncorpRedux,
  setCountryOfDomicileRedux,
  setPostalRedux,
  setWebsiteRedux,
  setTurnOverRedux,
  setAddressRedux,
  setPhoneNumber,
} = bizBorSlice.actions;

export default bizBorSlice.reducer;
