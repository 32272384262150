import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import axios from "axios";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";
import { CreateOrgRequest } from "../types/org.type";

const createOrg = async ({
  companyName,
  companyRegistrationNo,
  countryISOCode,
  industryGroup,
  industry,
  legalEntityAddress,
  legalStructure,
  onBoardingUserType,
  postalCode,
  bank,
  token,
  firstName,
  lastName,
  nameOfAuthorisedRepresentiative,
  occupation,
  numberofYears,
  nationality,
  gender,
  employmentStatus,
  employmentPosition,
  dateOfBirth,
  countryOfBirth,
  investmentPreferences,
  documentsUpload,
  companyPhoneNumber,
  titleOfAuthorisedRepresentiative,
  countryOfIncorporation,
  previousYearClosingInventory,
  website,
  shareHolders,
  boardOfDirectors,
  emailOfAuthorisedRepresentiative,
  jobTitle,
  companyStartDate,
}: CreateOrgRequest) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/trade-directory/organization`,
      {
        companyName,
        companyRegistrationNo,
        countryISOCode,
        industryGroup,
        industry,
        legalEntityAddress,
        legalStructure,
        onBoardingUserType,
        postalCode,
        bank,
        firstName,
        lastName,
        nameOfAuthorisedRepresentiative,
        occupation,
        numberofYears,
        nationality,
        gender,
        employmentStatus,
        employmentPosition,
        dateOfBirth,
        countryOfBirth,
        investmentPreferences,
        documentsUpload,
        companyPhoneNumber,
        titleOfAuthorisedRepresentiative,
        countryOfIncorporation,
        previousYearClosingInventory,
        website,
        shareHolders,
        boardOfDirectors,
        emailOfAuthorisedRepresentiative,
        jobTitle,
        companyStartDate,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        withCredentials: true,
      }
    );

    return response.data;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);

    throw new Error(message);
  }
};

export default createOrg;
