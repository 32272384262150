import { useSelector } from "react-redux";
import { reactLocalStorage } from "reactjs-localstorage";
// const BASE_URL = 'http://65.2.10.224:4049/api/admin/';
// const BASE_URL = 'https://admin.deshvidesh.ca/login'; newUrl
const BASE_URL = "https://api-uat.dashboard.fundedhere.com/api/user/";
const ALLOW_ORIGIN = "13.127.222.180:5041";
const USER_TYPE = "Merchant";

// >>>>LiveUrl = http://13.127.222.180:4030/<<<<

// import { BASE_URL, API_ENDPOINT_PATH } from "../constants/config";

function get(endpoint, params, token) {
  return requestData(endpoint, params, token);
}

function post(endpoint, params, token) {
  return requestData(endpoint, params, token, "POST");
}

function put(endpoint, params, token) {
  return requestData(endpoint, params, token, "PUT");
}

function deletemethod(endpoint, params) {
  return requestData(endpoint, params, "DELETE");
}

async function requestData(url, data = {}, token = "", method = "GET") {
  let xhr = new XMLHttpRequest();
  xhr.open(method, BASE_URL + url);
  if (CheckingAuth(token))
    xhr.setRequestHeader("authorization", "Bearer " + CheckingAuth(token));
  xhr.setRequestHeader("Content-Type", "application/json");
  xhr.setRequestHeader("Access-Control-Allow-Origin", ALLOW_ORIGIN);
  xhr.setRequestHeader("userType", USER_TYPE);

  return new Promise((resolve, reject) => {
    // console.log("string mal", JSON.stringify(data));
    if (method == "GET") {
      xhr.send();
    } else {
      xhr.send(JSON.stringify(data));
    }
    xhr.onload = () => {
      console.log(xhr.status);
      // console.log("jdjgfdgdfgdgfdgfdgdf", xhr);
      let response = JSON.parse(xhr.response);
      // console.log(response ,"sdgdgdfgdfgdf");

      if (xhr.status >= 200 && xhr.status < 300) {
        resolve(response);
      } else {
        reject(new Error(`Request failed with status ${xhr.status}`));
      }
    };
  });
}

async function fileUplode(
  url,
  method,
  file,
  object_get = {},
  tokenCustom = null
) {
  let token = "";
  let user = reactLocalStorage.get("Auth");

  if (user && user != null && Object.keys(user).length > 0) {
    token = user;
  }
  // let bash_url = "http://13.127.101.102:5011/api/v1/";
  let apiUrl = BASE_URL + url;

  if (token != "") {
    var toooo = token;
  }
  //  console.log("tokenn",toooo);
  let headers = {
    // 'Accept': 'application/json',
    // "Content-Type": "multipart/form-data",
    // "Access-Control-Allow-Origin": "http://13.127.101.102:5008",
    authorization: toooo,
    userType: "Admin",
    // 'Authorization': 'Bearer ' + login_status,
  };

  return await fetch(apiUrl, {
    method: method,
    body: file,
    redirect: "follow",
    headers: headers,
  })
    .then((response) => {
      return response.json();
    })
    .then(
      (result) => {
        return result;
      },
      (error) => {
        return error;
      }
    );
}

async function fileVideoUplode(
  url,
  method,
  file,
  object_get = {},
  tokenCustom = null
) {
  let token = "";
  let user = reactLocalStorage.get("Auth");
  // console.log("UserTok", user);
  if (user && user != null && Object.keys(user).length > 0) {
    token = user;
  }
  // // let bash_url = "http://13.127.101.102:5011/api/v1/";
  let apiUrl = BASE_URL + url;
  // let data = new FormData();
  // data.append("image", file);
  // Object.keys(object_get).forEach(function (key) {
  //   let ddd = object_get[key];
  //   data.append(key, ddd);
  // });

  if (token != "") {
    var toooo = token;
  }
  //  console.log("tokenn",toooo);
  let headers = {
    // 'Accept': 'application/json',
    // "Content-Type": "multipart/form-data",
    // "Access-Control-Allow-Origin": "http://13.127.101.102:5008",
    authorization: toooo,
    userType: "Admin",
    // 'Authorization': 'Bearer ' + login_status,
  };

  return await fetch(apiUrl, {
    method: method,
    body: file,
    redirect: "follow",
    headers: headers,
  })
    .then((response) => {
      // console.log("responseee", response, headers);
      return response.json();
    })
    .then(
      (result) => {
        return result;
      },
      (error) => {
        return error;
      }
    );
}

function CheckingAuth(token) {
  if (token) {
    return token;
  }
  return false;
}

export default {
  requestData,
  get,
  post,
  put,
  deletemethod,
  fileUplode,
  fileVideoUplode,
};
