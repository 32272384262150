import React from "react";
import { useStyle } from "./SFpayment.style";
import {
  setDecimalPlaces,
  formatPercentageFromRatio,
} from "../../Utils/formatter";

interface TableRow {
  skuId: string;
  description: string;
  loanAmount2: number;
  moe: string;
  sinkingFund: string;
  additionalSinkingFund: string;
  loanApplicationCurrency: string;
  interestRate: string;
}

type CurrencyData = {
  moe: string;
  sinkingFund: string;
  additionalSinkingFund: string;
};

type TotalType = {
  SGD: CurrencyData;
  USD: CurrencyData;
};
interface MyTableProps {
  data: TableRow[];
  total: TotalType;
}

const MyTable: React.FC<MyTableProps> = ({ data, total }) => {
  const { classes } = useStyle();

  return (
    <table className={classes.tableWithoutBorder}>
      <thead>
        <tr>
          <th className={classes.thh}>SKU ID</th>
          <th className={classes.thh}>Description</th>
          <th className={classes.thh}>Loan Amount</th>
          <th className={classes.thh}>Indicative Interest Rate p.m</th>
          <th className={classes.thh}>Qualifying Capital</th>
          <th className={classes.thh}>Sinking Fund</th>
          <th className={classes.thh}>Additional Sinking Fund </th>
        </tr>
      </thead>
      <tbody>
        {data.map((row) => (
          <tr key={row.skuId}>
            <td className={classes.th}>{row.skuId}</td>
            <td className={classes.th}>{row.description}</td>
            <td className={classes.th}>
              {row.loanApplicationCurrency + " " + row.loanAmount2}
            </td>
            <td className={classes.th}>
              {formatPercentageFromRatio(row.interestRate) + "%"}
            </td>
            <td className={classes.th}>
              {row.loanApplicationCurrency + " " + row.moe}
            </td>
            <td className={classes.th}>
              {row.loanApplicationCurrency} {row.sinkingFund}
            </td>
            <td className={classes.th}>
              {" "}
              {row.loanApplicationCurrency} {row.additionalSinkingFund}
            </td>
          </tr>
        ))}

        <tr>
          <td className={classes.th}></td>
          <td className={classes.th}></td>
          <td className={classes.th}></td>
          <td className={classes.th}>Total</td>
          <td className={classes.th}>
            SGD {setDecimalPlaces(total.SGD.moe, 2)}
          </td>
          <td className={classes.th}>
            <span className={classes.total}>
              SGD {setDecimalPlaces(total.SGD.sinkingFund, 2)}
            </span>
            <br />
            <span className={classes.total}>
              {" "}
              USD {setDecimalPlaces(total.USD.sinkingFund, 2)}
            </span>
          </td>
          <td className={classes.th}>
            <span className={classes.total}>
              SGD {setDecimalPlaces(total.SGD.additionalSinkingFund, 2)}
            </span>
            <br />
            <span className={classes.total}>
              USD {setDecimalPlaces(total.USD.additionalSinkingFund, 2)}
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default MyTable;
