export enum IndustryGroup {
  ENERGY = "Energy",
  MATERIALS = "Materials",
  CAPITAL = "Capital Goods",
  COMMERCIAL = "Commercial & Professional Services",
  TRANSPORTATION = "Transportation",
  AUTOMOBILES = "Automobiles & Components",
  CONSUMER_DURABLES = "Consumer Durables & Apparel",
  CONSUMER_SERVICES = "Consumer Services",
  CONSUMER_DISCRETIONARY = "Consumer Discretionary Distribution & Retail",
  CONSUMER_STAPLES = "Consumer Staples Distribution & Retail",
  FOOD = "Food, Beverage & Tobacco",
  HOUSEHOLD = "Household & Personal Products",
  HEALTHCARE = "Health Care Equipment & Services",
  PHARMACEUTICALS = "Pharmaceuticals, Biotechnology & Life Sciences",
  BANKS = "Banks",
  FINANCIAL = "Financial Services",
  INSURANCE = "Insurance",
  TECHNOLOGY = "Technology Hardware & Equipment",
  SEMICONDUCTORS = "Semiconductors & Semiconductor Equipment",
  TELECOMMUNICATION = "Telecommunication Services",
  MEDIA = "Media & Entertainment",
  UTILITIES = "Utilities",
  EQUITY_REAL_ESTATE = "Equity Real Estate Investment Trusts (REITs)",
  REAL_ESTATE = "Real Estate Management & Development",
}

export enum Industry {
  ENERGY_EQUIPMENT_AND_SERVICES = "Energy Equipment & Services",
  OIL_GAS_AND_CONSUMABLE_FUELS = "Oil, Gas & Consumable Fuels",
  CHEMICALS = "Chemicals",
  CONSTRUCTION_MATERIALS = "Construction Materials",
  CONTAINERS_AND_PACKAGING = "Containers & Packaging",
  METALS_AND_MINING = "Metals & Mining",
  PAPER_AND_FOREST_PRODUCTS = "Paper & Forest Products",
  AEROSPACE_AND_DEFENSE = "Aerospace & Defense",
  BUILDING_PRODUCTS = "Building Products",
  CONSTRUCTION_AND_ENGINEERING = "Construction & Engineering",
  ELECTRICAL_EQUIPMENT = "Electrical Equipment",
  INDUSTRIAL_CONGLOMERATES = "Industrial Conglomerates",
  MACHINERY = "Machinery",
  TRADING_COMPANIES_AND_DISTRIBUTORS = "Trading Companies & Distributors",
  COMMERCIAL_SERVICES_AND_SUPPLIES = "Commercial Services & Supplies",
  PROFESSIONAL_SERVICES = "Professional Services",
  AIR_FREIGHT_AND_LOGISTICS = "Air Freight & Logistics",
  PASSENGER_AIRLINES = "Passenger Airlines",
  MARINE_TRANSPORTATION = "Marine Transportation",
  GROUND_TRANSPORTATION = "Ground Transportation",
  TRANSPORTATION_INFRASTRUCTURE = "Transportation Infrastructure",
  AUTOMOBILE_COMPONENTS = "Automobile Components",
  AUTOMOBILES = "Automobiles",
  HOUSEHOLD_DURABLES = "Household Durables",
  LEISURE_PRODUCTS = "Leisure Products",
  TEXTILES_APPAREL_AND_LUXURY_GOODS = "Textiles, Apparel & Luxury Goods",
  HOTELS_RESTAURANTS_AND_LEISURE = "Hotels, Restaurants & Leisure",
  DIVERSIFIED_CONSUMER_SERVICES = "Diversified Consumer Services",
  DISTRIBUTORS = "Distributors",
  BROADLINE_RETAIL = "Broadline Retail",
  SPECIALTY_RETAIL = "Specialty Retail",
  CONSUMER_STAPLES_DISTRIBUTION_AND_RETAIL = "Consumer Staples Distribution & Retail",
  BEVERAGES = "Beverages",
  FOOD_PRODUCTS = "Food Products",
  TOBACCO = "Tobacco",
  HOUSEHOLD_PRODUCTS = "Household Products",
  PERSONAL_CARE_PRODUCTS = "Personal Care Products",
  HEALTH_CARE_EQUIPMENT_AND_SUPPLIES = "Health Care Equipment & Supplies",
  HEALTH_CARE_PROVIDERS_AND_SERVICES = "Health Care Providers & Services",
  HEALTH_CARE_TECHNOLOGY = "Health Care Technology",
  BIOTECHNOLOGY = "Biotechnology",
  PHARMACEUTICALS = "Pharmaceuticals",
  LIFE_SCIENCES_TOOLS_AND_SERVICES = "Life Sciences Tools & Services",
  BANKS = "Banks",
  FINANCIAL_SERVICES = "Financial Services",
  CONSUMER_FINANCE = "Consumer Finance",
  CAPITAL_MARKETS = "Capital Markets",
  MORTGAGE_REAL_ESTATE_INVESTMENT_TRUSTS_REITS = "Mortgage Real Estate Investment Trusts (REITs)",
  INSURANCE = "Insurance",
  IT_SERVICES = "IT Services",
  SOFTWARE = "Software",
  COMMUNICATIONS_EQUIPMENT = "Communications Equipment",
  TECHNOLOGY_HARDWARE_STORAGE_AND_PERIPHERALS = "Technology Hardware, Storage & Peripherals",
  ELECTRONIC_EQUIPMENT_INSTRUMENTS_AND_COMPONENTS = "Electronic Equipment, Instruments & Components",
  SEMICONDUCTORS_AND_SEMICONDUCTOR_EQUIPMENT = "Semiconductors & Semiconductor Equipment",
  DIVERSIFIED_TELECOMMUNNICATION_SERVICES = "Diversified Telecommunication Services",
  WIRELESS_TELECOMMUNICATION_SERVICES = "Wireless Telecommunication Services",
  MEDIA = "Media",
  ENTERTAINMENT = "Entertainment",
  INTERACTIVE_MEDIA_AND_SERVICES = "Interactive Media & Services",
  ELECTRIC_UTILITIES = "Electric Utilities",
  GAS_UTILITIES = "Gas Utilities",
  MULTI_UTILITIES = "Multi-Utilities",
  WATER_UTILITIES = "Water Utilities",
  INDEPENDENT_POWER_AND_RENEWABLE_ELECTRICITY_PRODUCERS = "Independent Power and Renewable Electricity Producers",
  DIVERSIFIED_REITS = "Diversified REITs",
  INDUSTRAIL_REITS = "Industrial REITs",
  HOTEL_AND_RESORT_REITS = "Hotel & Resort REITs",
  OFFICE_REITS = "Office REITs",
  HEALTH_CARE_REITS = "Health Care REITs",
  RESIDENTIAL_REITS = "Residential REITs",
  RETAIL_REITS = "Retail REITs",
  SPECIALIZED_REITS = "Specialized REITs",
  REAL_ESTATE_MANAGEMENT_AND_DEVELOPMENT = "Real Estate Management & Development",
  OTHERS = "Others",
}

export enum EmploymentStatus {
  EMPLOYED = "Employed",
  SELF_EMPLOYED = "Self-Employed",
  STUDENT = "Student",
  UNEMPLOYED = "Unemployed",
}

export enum EmploymentPosition {
  ENTRY_LEVEL = "Entry Level",
  MID_LEVEL_SUPERVISORY_MANAGEMENT = "Mid-Level/Supervisory/Management",
  SENIOR_LEVEL_EXECUTIVE = "Senior Level/Executive",
  OWNER = "Owner",
}

export enum LegalStructure {
  ASSOCIATION_OR_NONPROFIT_ORGANIZATION = "Association or Nonprofit Organization",
  COOPERATIVE = "Cooperative",
  CORPORATION_INC_OR_LTD = "Corporation (Inc. or Ltd.)",
  FOUNDATION = "Foundation",
  LICENSED_FUND = "Licensed Fund",
  LIMITED_LIABILITY_COMPANY_LLC = "Limited Liability Company (LLC)",
  LIMITED_LIABILITY_PARTNERSHIP_LLP = "Limited Liability Partnership (LLP)",
  PRIVATE_LIMITED = "Pte Ltd",
  LIMITED_PARTNERSHIP_LP = "Limited Partnership (LP)",
  REAL_ESTATE_INVESTMENT_TRUST_REIT = "Real Estate Investment Trust (REIT)",
  SOVEREIGN_WEALTH_FUND = "Sovereign Wealth Fund",
  TRUST = "Trust",
  OTHERS = "Others",
}

export enum BankAccount {
  CURRENT = "Current",
  JOINT = "Joint",
  SAVING = "Saving",
}
