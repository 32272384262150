import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import moment from "moment";
import AuthServices from "../../../services/AuthServices";
import CustomLoader from "@funded-here-interface/common/src/dashboard/components/CustomComponents/loader/CustomLoader";

const Chart = () => {
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [chartData, setChartData] = useState({
    series: [
      {
        name: "Sales Proceeds",
        data: [],
      },
    ],
    options: {
      chart: {
        height: 350,
        zoom: {
          enabled: false,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        width: 4,
        curve: "straight",
        colors: ["#92bbac"],
      },
      title: {
        text: "Daily Sales Report",
        align: "left",
        style: {
          fontSize: "10px",
        },
      },
      xaxis: {
        categories: [],
        title: {
          text: "Date",
        },
      },
      legend: {
        show: "true",
        showForSingleSeries: true,
        showForNullSeries: true,
        showForZeroSeries: true,
        position: "top",
        horizontalAlign: "left",
        offsetX: -15,
        fontWeight: "bold",
        fontSize: "8px",
        markers: {
          fillColors: ["#92bbac"],
        },
      },
      yaxis: {
        show: true,
        labels: {
          formatter: function (val, index) {
            return val;
          },
        },
      },
    },
  });

  const [selectedSKU, setSelectedSKU] = useState("");
  const [skuOptions, setSkuOptions] = useState([]);
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoadingData(true);
      try {
        const res = await AuthServices.getAllSKUdropdown(token);
        setSkuOptions(res.data);
      } catch (error) {
        console.error("Error fetching SKU data:", error);
      }
      setIsLoadingData(false);
    };

    fetchData();
  }, []);

  const fetchDailySales = async () => {
    setIsLoadingData(true);
    try {
      let res;
      if (selectedSKU) {
        res = await AuthServices.dailySalesReport(
          { skuId: selectedSKU },
          undefined,
          token
        );
      } else {
        res = await AuthServices.dailySalesReport({}, undefined, token);
      }
      if (res && res.status && res.data.length > 0) {
        const categories = [];
        const seriesData = [];
        res.data.forEach((item) => {
          categories.push(moment(item.date).format("YYYY-MM-DD"));
          seriesData.push(item.totalSalesProceed);
        });
        setChartData({
          ...chartData,
          series: [{ name: "Sales Proceeds", data: seriesData }],
          options: {
            ...chartData.options,
            xaxis: {
              ...chartData.options.xaxis,
              categories: categories,
            },
          },
        });
      } else {
        // Handle case when no data is returned
        setChartData({
          ...chartData,
          series: [{ name: "Sales Proceeds", data: [] }],
          options: {
            ...chartData.options,
            xaxis: {
              ...chartData.options.xaxis,
              categories: [],
            },
          },
        });
      }
    } catch (error) {
      console.error("Error fetching daily sales report:", error);
    }
    setIsLoadingData(false);
  };

  useEffect(() => {
    fetchDailySales();
  }, [selectedSKU]);

  useEffect(() => {
    fetchDailySales();
  }, []);

  const handleSelectChange = (event) => {
    setSelectedSKU(event.target.value);
  };

  return (
    <>
      <CustomLoader isLoading={isLoadingData} />
      <section className="myChartSec">
        <div className="chart" style={{ position: "relative" }}>
          <ReactApexChart
            options={chartData.options}
            series={chartData.series}
            type="line"
            height={300}
          />
          <div
            className="dropdown"
            style={{ position: "absolute", top: "10px", right: "10px" }}
          >
            <label
              htmlFor="sku"
              style={{ fontSize: "12px", marginRight: "5px" }}
            >
              Select SKU:
            </label>
            <select
              id="sku"
              value={selectedSKU}
              onChange={handleSelectChange}
              style={{ fontSize: "12px", padding: "4px" }}
            >
              <option value="">All SKUs</option>
              {skuOptions.map((sku) => (
                <option key={sku._id} value={sku.skuId}>
                  {sku.skuId}
                </option>
              ))}
            </select>
          </div>
        </div>
      </section>
    </>
  );
};

export default Chart;
