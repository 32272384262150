import { FC, useMemo } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import useGetPendingPmVerify from "@funded-here-interface/common/src/hooks/useGetSkuPendingPmVerify";
import { toast } from "react-toastify";
import { GetSkuPendingPmVerifyResponse } from "@funded-here-interface/common/src/services/getSkuPendingPmVerify";
import {
  MantineReactTable,
  MRT_ColumnDef,
  useMantineReactTable,
} from "mantine-react-table";
import { Space } from "@mantine/core";

const SkuLoanApplicationSubmitted: FC = () => {
  const { token } = useSelector((state: RootState) => state.auth);
  const { data = [], error, isLoading } = useGetPendingPmVerify(token);

  if (error) {
    toast.error((error as Error).message);
  }

  const columns = useMemo<MRT_ColumnDef<GetSkuPendingPmVerifyResponse>[]>(
    () => [
      {
        accessorKey: "skuId",
        id: "skuId",
        header: "SKU ID",
        Cell: ({ cell }) => <p>{cell.getValue<string>() || "-"}</p>,
      },
      {
        accessorKey: "upc",
        id: "upc",
        header: "UPC",
        Cell: ({ cell }) => <p>{cell.getValue<string>() || "-"}</p>,
      },
      {
        accessorKey: "status",
        id: "status",
        header: "Status",
        Cell: ({ cell }) => <p>{cell.getValue<string>() || "-"}</p>,
      },
      {
        accessorKey: "description",
        id: "description",
        header: "Description",
        Cell: ({ cell }) => <p>{cell.getValue<string>() || "-"}</p>,
      },
      {
        accessorKey: "poNumber",
        id: "poNumber",
        header: "PO Number",
        Cell: ({ cell }) => <p>{cell.getValue<string>() || "-"}</p>,
      },
      {
        accessorKey: "poDate",
        id: "poDate",
        header: "PO Date",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? new Date(cell.getValue<string>()).toISOString().split("T")[0]
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "deliveryDate",
        id: "deliveryDate",
        header: "Delivery Date",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? new Date(cell.getValue<string>()).toISOString().split("T")[0]
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "expectedPaymentDate",
        id: "expectedPaymentDate",
        header: "Expected Payment Date",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? new Date(cell.getValue<string>()).toISOString().split("T")[0]
              : "-"}
          </p>
        ),
      },
      {
        accessorKey: "purchasePricePerUnit",
        id: "purchasePricePerUnit",
        header: "Purchase Price Per Unit",
        Cell: ({ cell }) => <p>{cell.getValue<string>() || "-"}</p>,
      },
      {
        accessorKey: "sellingPricePerUnit",
        id: "sellingPricePerUnit",
        header: "Selling Price Per Unit",
        Cell: ({ cell }) => <p>{cell.getValue<string>() || "-"}</p>,
      },
      {
        accessorKey: "quantityPurchased",
        id: "quantityPurchased",
        header: "Quantity Ordered",
        Cell: ({ cell }) => <p>{cell.getValue<string>() || "-"}</p>,
      },
      {
        accessorKey: "discount",
        id: "discount",
        header: "Discount",
        Cell: ({ cell }) => <p>{cell.getValue<string>() || "-"}</p>,
      },
      {
        accessorKey: "otherCosts",
        id: "otherCosts",
        header: "Other Costs",
        Cell: ({ cell }) => <p>{cell.getValue<string>() || "-"}</p>,
      },
      {
        accessorKey: "tax",
        id: "tax",
        header: "Tax",
        Cell: ({ cell }) => <p>{cell.getValue<string>() || "-"}</p>,
      },
      {
        accessorKey: "nettInvoiceAmount",
        id: "nettInvoiceAmount",
        header: "Total Amount",
        Cell: ({ cell }) => <p>{cell.getValue<string>() || "-"}</p>,
      },
      {
        accessorKey: "paymentTerm",
        id: "paymentTerm",
        header: "Payment Term",
        Cell: ({ cell }) => <p>{cell.getValue<string>() || "-"}</p>,
      },
      {
        accessorKey: "partialPaymentFirstPaymentPercentage",
        id: "partialPaymentFirstPaymentPercentage",
        header: "Partial Payment's First Payment %",
        Cell: ({ cell }) => <p>{cell.getValue<string>() || "-"}</p>,
      },
      {
        accessorKey: "skuReturnPercentage",
        id: "skuReturnPercentage",
        header: "Return %",
        Cell: ({ cell }) => <p>{cell.getValue<string>() || "-"}</p>,
      },
      {
        accessorKey: "skuReturnMoreThanLimitReason",
        id: "skuReturnMoreThanLimitReason",
        header: "Return Reason",
        Cell: ({ cell }) => <p>{cell.getValue<string>() || "-"}</p>,
      },
    ],
    []
  );

  const table = useMantineReactTable({
    enableColumnActions: false,
    enableColumnFilters: false,
    paginationDisplayMode: "pages",
    state: { isLoading },
    columns,
    data: data,
    initialState: { density: "xs" },
    mantineTableProps: {
      withBorder: true,
    },
  });

  return (
    <div>
      <Space h="md" />
      <MantineReactTable table={table} />
    </div>
  );
};

export default SkuLoanApplicationSubmitted;
