import moment from "moment";
import React, { useEffect, useState } from "react";
import { useTable, useSortBy } from "react-table";
import toast from "react-hot-toast";
import CustomLoader from "@funded-here-interface/common/src/dashboard/components/CustomComponents/loader/CustomLoader";
import { useSelector } from "react-redux";
import HttpClientXmInv from "../../../../Utils/HttpClientXmInv";
import {
  IconArrowsDownUp,
  IconArrowDown,
  IconArrowUp,
} from "@tabler/icons-react";

const DataTableownInvest = ({ columns, data }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);
  return (
    <table {...getTableProps()} style={{ borderSpacing: "0", width: "100%" }}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                style={{
                  borderBottom: "1px solid black",
                  background: "#f2f2f2",
                  padding: "8px",
                  cursor: "pointer",
                }}
              >
                {column.render("Header")}
                <span>
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <IconArrowDown height={12} width={24} />
                    ) : (
                      <IconArrowUp height={12} width={24} />
                    )
                  ) : (
                    <IconArrowsDownUp height={12} width={24} />
                  )}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              style={{ borderBottom: "1px solid black", padding: "8px" }}
            >
              {row.cells.map((cell) => (
                <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const App2 = () => {
  const [AllData, setAllData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const token = useSelector((state) => state.auth.token);

  const columns = [
    { Header: "NOTE ID", accessor: "snId" },

    { Header: "Sector", accessor: "sector" },

    { Header: "Country", accessor: "country" },

    {
      Header: "Invested Amount",
      accessor: "investorDeposit",
      Cell: ({ value }) => value,
    },

    {
      Header: "Interest Earned",
      accessor: "interestEarned",
      Cell: ({ value }) => value,
    },

    {
      Header: "Total Earned",
      accessor: "totalEarned",
      Cell: ({ value }) => value,
    },

    { Header: "Tenure", accessor: "tenure" },

    {
      Header: "Effective ROR",
      accessor: "effectiveROR",
      Cell: ({ value }) => `${value}%`,
    },
  ];

  const getData = async () => {
    setIsLoading(true);
    try {
      const res = await HttpClientXmInv.requestData(
        `view-closed-investment-byInvester`,
        {},
        token,
        "GET"
      );
      if (res && res.status) {
        setIsLoading(false);
        setAllData(
          res.data.map((item) => ({
            ...item,
            maturityDate: moment(item.maturityDate).format("DD-MM-YYYY"),
          }))
        );
      } else {
        setAllData([]);
        toast.error(res?.message || "Something Wrong");
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Failed to fetch data");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <CustomLoader loading={isLoading} />{" "}
      <DataTableownInvest columns={columns} data={AllData} />
    </>
  );
};

export default App2;
