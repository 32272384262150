import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTable } from "react-table";
import {
  IconArrowsDownUp,
  IconArrowDown,
  IconArrowUp,
} from "@tabler/icons-react";
import useGetActivePaymentTracking from "../../../../hooks/useGetActivePaymentTracking";
import { toast } from "react-toastify";
import { Box, LoadingOverlay, Select, TextInput, Text } from "@mantine/core";
import ReactPaginate from "react-paginate";
import { useStyles } from "./RepaymentTracking.styles";
import useDebounce from "@funded-here-interface/common/src/hooks/useDebounce";

const DataTableown = ({ columns, data, onSortChange, sortBy, sortOrder }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <div className="DataTableInn">
      <div className="tableWrapper">
        <table
          {...getTableProps()}
          style={{ borderSpacing: "0", width: "100%" }}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => {
                  const isLiveDoi = column.id === "liveDoi";
                  const isSorted = sortBy === column.id;

                  return (
                    <th
                      {...column.getHeaderProps()}
                      style={{
                        cursor: isLiveDoi ? "default" : "pointer",
                      }}
                      onClick={() => {
                        if (!isLiveDoi && onSortChange) {
                          onSortChange({ id: column.id });
                        }
                      }}
                    >
                      {column.render("Header")}
                      {!isLiveDoi && (
                        <span>
                          {isSorted ? (
                            sortOrder === "DESC" ? (
                              <IconArrowDown height={12} width={24} />
                            ) : (
                              <IconArrowUp height={12} width={24} />
                            )
                          ) : (
                            <IconArrowsDownUp height={12} width={24} />
                          )}
                        </span>
                      )}
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} style={{ padding: "8px" }}>
                  {row.cells.map((cell) => (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const App = () => {
  const { classes } = useStyles();
  const token = useSelector((state) => state.auth.token);
  const [page, setPage] = useState("1");
  const [limit, setLimit] = useState("20");
  const [search, setSearch] = useState("");
  const limits = ["20", "50", "100"];
  const debouncedSearch = useDebounce(search, 1000);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const { data, error, isLoading } = useGetActivePaymentTracking(
    token,
    limit,
    page,
    debouncedSearch,
    sortBy,
    sortOrder
  );

  if (error) {
    toast.error(error?.message);
  }

  useEffect(() => {
    setPage("1");
  }, [debouncedSearch]);

  const columns = [
    { Header: "SKU ID", accessor: "skuId" },
    { Header: "SKU Name", accessor: "skuName" },
    { Header: "Sector", accessor: "sector" },
    { Header: "Country", accessor: "country" },
    { Header: "Days of Inventory (DOI)", accessor: "doi" },
    { Header: "SKU Live DOI", accessor: "liveDoi" },
    { Header: "Tenure", accessor: "tenure" },
    { Header: "Days to Maturity", accessor: "daysToMaturity" },
    {
      Header: "Projected Sales (Moving average last 5 days)",
      accessor: "projectedSales",
    },
    { Header: "Loan Amount", accessor: "loanAmount" },
    { Header: "Repaid Amount", accessor: "repaidAmount" },
    { Header: "Balance Amount", accessor: "balanceAmount" },
    { Header: "Total Sales Proceed", accessor: "totalSalesProceed" },
    { Header: "Repayment Breakeven Unit", accessor: "rbu" },
    { Header: "Quantity Sold (in units)", accessor: "quantitySold" },
    { Header: "Selling Price", accessor: "sellingPrice" },
    { Header: "Topup Amount", accessor: "topUpAmount" },
  ];

  const formattedData =
    data?.data?.map((data) => ({
      skuId: data?.skuId || "-",
      skuName: data?.skuName || "-",
      country: data?.country || "-",
      sector: data?.sector || "-",
      doi: data?.doi || "0",
      liveDoi: data?.liveDoi || "0",
      tenure: data?.tenure || "0",
      daysToMaturity: data?.daysToMaturity || "0",
      projectedSales: data?.projectedSales || "0",
      loanAmount: data?.loanAmount || "0",
      repaidAmount: data?.repaidAmount || "0",
      balanceAmount: data?.balanceAmount || "0",
      totalSalesProceed: data?.totalSalesProceed || "0",
      rbu: data?.rbu || "0",
      quantitySold: data?.quantitySold || "0",
      sellingPrice: data?.sellingPrice || "0",
      topUpAmount: data?.topUpAmount || "0",
    })) ?? [];

  const handlePageChange = async (event) => {
    const pageNo = event.selected + 1;
    setPage(`${pageNo}`);
  };

  const handleLimitChange = async (limit) => {
    setPage("1");
    setLimit(limit);
  };

  const handleSearchChange = (e) => {
    const searchedTerm = e.target.value;
    setSearch(searchedTerm);
  };

  const handleSortChange = ({ id }) => {
    if (id === sortBy) {
      if (sortOrder === "ASC") {
        setSortOrder("DESC");
      } else if (sortOrder === "DESC") {
        setSortBy("");
        setSortOrder("");
      }
    } else {
      setSortBy(id);
      setSortOrder("ASC");
    }
  };

  return (
    <Box pos="relative">
      <div className={classes.search}>
        <TextInput
          mt="md"
          label="Search"
          onChange={handleSearchChange}
          placeholder="Search SKU ID"
        />
      </div>
      <LoadingOverlay visible={isLoading} />
      <DataTableown
        columns={columns}
        data={formattedData}
        onSortChange={handleSortChange}
        sortBy={sortBy}
        sortOrder={sortOrder}
      />
      {!isLoading && formattedData.length === 0 && (
        <div className={classes.noData}>
          <Text>No data available</Text>
        </div>
      )}
      <div className={classes.buttonContainer}>
        <div className={classes.rowSelection}>
          <Select
            placeholder={limit}
            data={limits}
            onChange={handleLimitChange}
          />
        </div>
        <ReactPaginate
          nextLabel=">"
          onPageChange={handlePageChange}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={Math.ceil(data?.totalCount / parseInt(limit))}
          previousLabel="<"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
          forcePage={parseInt(page) - 1}
        />
      </div>
    </Box>
  );
};

export default App;
