import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";
import axios from "axios";

interface GetSkusToNoteIdMapRequest {
  token: string;
}

const GetSkusToNoteIdMap = async ({ token }: GetSkusToNoteIdMapRequest) => {
  try {
    const url = `${BASE_URL}/sku-finance/dashboard/skus-to-note-id-map`;

    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    });

    return response.data;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);
    throw new Error(message);
  }
};

export default GetSkusToNoteIdMap;
