import { useQuery } from "react-query";
import getVaBalance from "../services/getVaBalance";

function useVaBalance(token: string) {
  return useQuery(["get-va-balance", token], () => getVaBalance({ token }), {
    retry: false,
  });
}

export default useVaBalance;
