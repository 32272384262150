import { Button } from "@funded-here-interface/common";
import {
  Group,
  Modal,
  Text,
  Button as MButton,
  MantineProvider,
  Radio,
  Center,
  Loader,
} from "@mantine/core";
import { FC, useEffect, useRef, useState, CSSProperties } from "react";
import { useStyle } from "./StructureNote.styles";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { REST_SERVER, WEB_DOMAIN } from "../../constants/domain";
import { useSubcriptionDocuSign } from "../../hooks/useDocuSign";
import usePostSubscriptionAgreementSigned from "../../hooks/usePostSubscriptionAgreementSigned";
import { COLORS } from "../../constants/colors";
import useOfferRoom from "../../hooks/useOfferRoom";
import useInvestorFunds from "../../hooks/useInvestorAccount";
import VQRVerifyPage from "../../components/VQR/VQRVerifyPage";
import { DocuSignEvent, DocuSignEventHandler } from "../../constants/docuSign";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";
import { useNavigate } from "react-router-dom";
import MembershipAgreement from "../../components/MembershipAgreement/MembershipAgreement";
import { useDisclosure } from "@mantine/hooks";
import useWithdrawalFund from "../../hooks/useWithdrawalFund";
import useWithdrawalFundConfirmed from "../../hooks/useWithdrawalFundConfirmed";
import { EntityTypeEnum } from "@funded-here-interface/common/src/constant/enum";
import DocViewer from "@cyntler/react-doc-viewer";
import { ENV } from "@funded-here-interface/common/src/constant/constant";
import SignQRModal from "../../components/SignQRModal/SignQRModal";
import useSignedDocument from "../../hooks/useSignedDocument";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";
import useInvestorGA from "../../hooks/useInvestorGA";

declare global {
  interface Window {
    DocuSign: any;
  }
}

interface InvestmentOpportunity {
  id: number;
  currency: string;
  tenor: string;
  amountAvailableForInvestment: string;
  snInterestRate: string;
  totalSKU: number;
  totalMerchants: number;
}

interface withdrawalDetail {
  totalFee: string;
  virtualAccountBalance: string;
}

const StructureNote: FC = () => {
  const { classes } = useStyle();
  const [category, setCategory] = useState("view");
  const [offerRoomData, setOfferRoomData] = useState<any>([]);
  const [accountData, setAccountData] = useState<any>([]);
  const [accountGAData, setAccountGAData] = useState<any>([]);
  const docuSignMutation = useSubcriptionDocuSign();
  const offerRoomMutation = useOfferRoom();
  const InvestorFundsMutation = useInvestorFunds();
  const InvestorGAMutation = useInvestorGA();
  const [realUrl, setRealUrl] = useState("");
  const [documentId, setDocumentId] = useState(0);
  const [qrModalOpened, { open: openQrModal, close: closeQrModal }] =
    useDisclosure(false);
  const docs = [
    {
      uri: realUrl,
      fileType: "pdf",
    },
  ];

  const [isWithdrawalLoading, setIsWithdrawalLoading] = useState(false);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [hoveredId, setHoveredId] = useState<number | null>(null);
  const [selectedData, setSelectedData] =
    useState<InvestmentOpportunity | null>(null);
  const { token, agreedToMembershipAgreement, isAuthRep, entityType } =
    useSelector((state: RootState) => state.auth);
  const hideNoteSubscribe =
    entityType === EntityTypeEnum.INSTITUTION && !isAuthRep;
  const [selectedCurrency, setSelectedCurrency] = useState("SGD");
  const [withdrawalFundDetail, setWithdrawalFundDetail] =
    useState<withdrawalDetail>();
  const withdrawalFund = useWithdrawalFund(token, selectedCurrency);
  const withdrawalFundConfirmed = useWithdrawalFundConfirmed();

  const subscriptionAgreementMutation = usePostSubscriptionAgreementSigned();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [investorSubscribeAmount, setInvestorSubscribeAmount] = useState("");
  const [selectedNoteCurrency, setSelectedNoteCurrency] = useState("");
  const [offerSigned, setOfferSigned] = useState(false);
  const navigation = useNavigate();
  const [opened, { close }] = useDisclosure(
    entityType === EntityTypeEnum.INSTITUTION
      ? isAuthRep === true && agreedToMembershipAgreement !== true
      : agreedToMembershipAgreement !== true
  );
  const [openedWithdraw, modalAction] = useDisclosure(false);
  const [completedAt, setCompletedAt] = useState("");

  const handleAuthenticatedChange = (isAuthenticated: boolean) => {
    setIsAuthenticated(isAuthenticated);

    if (isAuthenticated) {
      handleOK();
    }
  };
  const signedDocument = useSignedDocument();

  const agreementRef = useRef(null);
  const [viewerKey, setViewerKey] = useState(0);

  useEffect(() => {
    InvestorFundsMutation.mutate(
      { token },
      {
        onSuccess: (data) => {
          setAccountData(data);
        },
        onError: (error: any) => {
          toast.error(error);
        },
      }
    );
    InvestorGAMutation.mutate(
      { token },
      {
        onSuccess: (data) => {
          setAccountGAData(data);
        },
        onError: (error: any) => {
          toast.error(error);
        },
      }
    );
    offerRoomMutation.mutate(
      { token },
      {
        onSuccess: (data) => {
          setOfferRoomData(data);
        },
        onError: (error: any) => {
          toast.error(error);
        },
      }
    );
  }, []);

  const validateInvestorSubscribeAmount = (inputValue: string) => {
    const isValidFormat = /^\d*\.?\d{0,2}$/.test(inputValue);

    if (!isValidFormat) {
      return false;
    }

    const inputNumber = parseFloat(inputValue);
    const amountAvailable = parseFloat(
      selectedData?.amountAvailableForInvestment || "0"
    );

    if (inputNumber > amountAvailable) {
      return false;
    }

    return true;
  };

  const handleOK = async () => {
    if (!selectedData) {
      toast.error("No investment opportunity selected.");
      return;
    }

    toast.info("Document is loading");
    setIsOverlayVisible(true);

    docuSignMutation.mutate(
      {
        token,
        structuredNoteId: selectedData.id,
        investedAmount: investorSubscribeAmount,
        webDomain: WEB_DOMAIN,
        redirectUrl: `${REST_SERVER}/document-management/docusign/webhook`,
      },
      {
        onSuccess: async (data) => {
          setRealUrl(data.url);
          setDocumentId(data.documentId);
          setCompletedAt(data.completedAt);
        },
        onError: (e) => {
          toast.error((e as Error).message);
          setIsOverlayVisible(false);
        },
      }
    );
  };

  const handleSubscriptionAgreementSigned = async () => {
    if (!selectedData) {
      toast.error("No investment opportunity selected.");
      return;
    }
    subscriptionAgreementMutation.mutate(
      {
        token,
        structuredNoteId: selectedData.id,
        investorSubscribedAmount: investorSubscribeAmount,
      },
      {
        onSuccess: () => {
          toast.success("Document Signed Successfully");
          setIsOverlayVisible(false);
          setOfferSigned(true);
        },
        onError: (e) => {
          toast.error((e as Error).message);
          setIsOverlayVisible(false);
        },
      }
    );
  };

  const handleBoxSelect = (data: InvestmentOpportunity) => {
    setSelectedData(data);
    setSelectedNoteCurrency(data.currency);

    switch (data.currency) {
      case "SGD":
        accountData.bankName = accountData.investorSgdBankName;
        accountData.accountHolder = accountData.investorSgdBankAccountHolder;
        accountData.accountNumber = accountData.investorSgdBankAccountId;
        accountData.bicSwift = accountData.investorSgdBicSwift;
        accountData.branchCode = accountData.investorSgdBranchCode;
        accountData.bankCode = accountData.investorSgdBankCode;
        accountData.bankAddress = accountData.investorSgdBankAddress;
        break;
      case "USD":
        accountData.bankName = accountData.investorUsdBankName;
        accountData.accountHolder = accountData.investorUsdBankAccountHolder;
        accountData.accountNumber = accountData.investorUsdBankAccountId;
        accountData.bicSwift = accountData.investorUsdBicSwift;
        accountData.branchCode = accountData.investorUsdBranchCode;
        accountData.bankCode = accountData.investorUsdBankCode;
        accountData.bankAddress = accountData.investorUsdBankAddress;
        break;
    }

    setAccountData(accountData);
  };

  const handleSubscribeClick = () => {
    if (investorSubscribeAmount === "") {
      toast.error("Please enter an investment/subscription amount.");
      return;
    }

    setCategory("subscribe");
  };

  const getBoxStyle = (data: InvestmentOpportunity): CSSProperties => ({
    cursor: "pointer",
    border: "1px solid #ccc",
    padding: "30px",
    borderRadius: "5px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    flex: 1,
    marginTop: "20px",
    display: "flex",
    flexDirection: "column",
    gap: 25,
    margin: "10px",
    backgroundColor:
      selectedData && selectedData.id === data.id
        ? "rgba(0, 0, 0, 0.25)"
        : hoveredId === data.id
        ? "rgba(0, 0, 0, 0.25)"
        : "transparent",
    color:
      hoveredId === data.id || (selectedData && selectedData.id === data.id)
        ? COLORS.whiteColor
        : undefined,
    transition: "background-color 0.3s ease",
  });

  const [visibleStartIndex, setVisibleStartIndex] = useState(0);

  const canNavigateBackward = visibleStartIndex > 0;
  const canNavigateForward = visibleStartIndex < offerRoomData.length - 3;

  const handleNext = () => {
    if (canNavigateForward) {
      setVisibleStartIndex(visibleStartIndex + 1);
    }
  };

  const handlePrev = () => {
    if (canNavigateBackward) {
      setVisibleStartIndex(visibleStartIndex - 1);
    }
  };
  const visibleItems = offerRoomData.slice(
    visibleStartIndex,
    visibleStartIndex + 3
  );

  const handleOnVerifyMeNext = () => {
    setIsAuthenticated(true);
  };

  const handleBackSubscribedNote = () => {
    navigation("/subscribed-invested-notes");
  };

  const handleWithdrawalClick = () => {
    setIsWithdrawalLoading(true);
    withdrawalFund
      .refetch({ throwOnError: true })
      .then((resp) => {
        setWithdrawalFundDetail(resp.data);
        modalAction.open();
        setIsWithdrawalLoading(false);
      })
      .catch((error) => {
        const message = getApiResponseErrorMsg(error);
        toast.error(message);
        setIsWithdrawalLoading(false);
      });
  };

  const handleConfirmWithdrawalClick = () => {
    withdrawalFundConfirmed.mutate(
      { token, currency: selectedCurrency },
      {
        onSuccess: async (data) => {
          accountData.accountBalance[selectedCurrency] = "0.00";
          setAccountData(accountData);
          toast.info("Fund successfully withdraw");
        },
        onSettled: async (data) => {
          modalAction.close();
        },
      }
    );
  };

  const handleSign = async (isAuthenticated: boolean) => {
    if (isAuthenticated) {
      await handleSubscriptionAgreementSigned();
      closeQrModal();
      setRealUrl("");
      signedDocument.mutate(
        { documentId, token },
        {
          onSuccess: async (data) => {
            setRealUrl(data.url);
            setDocumentId(data.documentId);
            setCompletedAt(data.completedAt);
            setViewerKey((prevKey) => prevKey + 1);
          },
          onError: (e) => {
            toast.error((e as Error).message);
          },
        }
      );
    }
  };

  const openModal = () => {
    setViewerKey((prevKey) => prevKey + 1);
    openQrModal();
  };

  const closeModal = () => {
    setViewerKey((prevKey) => prevKey + 1);
    closeQrModal();
  };

  return (
    <>
      <div
        className={classes.stepContent}
        style={{ marginTop: "0px", paddingTop: "0px" }}
      >
        <MembershipAgreement
          toCompleteOnboard={false}
          onError={(value: string) => {
            toast.error(value);
          }}
          opened={opened}
          close={close}
        />
        <Text className={classes.headerText} style={{ marginTop: "10px" }}>
          {category === "subscribe" && isAuthenticated ? "" : "Offer Room"}
        </Text>

        {category === "view" && (
          <>
            <Text style={{ fontWeight: 400 }}>
              You may now deposit funds into your FundedHere Account and start
              your investment journey by subscribing to the investment notes
              below!{" "}
            </Text>
            <div>
              <Text
                style={{
                  fontWeight: 800,
                  marginTop: 10,
                  marginBottom: 10,
                }}
              >
                Virtual Account Details
              </Text>
              {!accountGAData?.investorSgdBankAccountId ? (
                <Text
                  style={{
                    color: "black",
                    fontStyle: "italic",
                    fontSize: 16,
                    textAlign: "left",
                    padding: "10px 10px 10px 0px",
                  }}
                >
                  Account creation is being processed.
                </Text>
              ) : (
                <div style={{ display: "flex", gap: 30 }}>
                  <div
                    style={{
                      backgroundColor: "#D9D9D980",
                      borderRadius: 5,
                      padding: "10px 30px",
                    }}
                  >
                    <Text>
                      Bank Name: {accountGAData?.investorSgdBankName ?? "-"}
                    </Text>
                    <Text>
                      Account name:{" "}
                      {accountGAData?.investorSgdBankAccountHolder ?? "-"}
                    </Text>
                    <Text>
                      <span
                        style={{
                          color: COLORS.agreementFont,
                          fontWeight: "bold",
                        }}
                      >
                        SGD Account:{" "}
                      </span>
                      {accountGAData?.investorSgdBankAccountId ?? "-"}
                    </Text>
                    <Text>
                      Swift code: {accountGAData?.investorSgdBicSwift ?? "-"}
                    </Text>
                  </div>
                </div>
              )}
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: 20,
                marginBottom: 10,
                gap: 10,
                alignItems: "flex-start",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginTop: 20,
                  marginBottom: 10,
                  gap: 10,
                  alignItems: "center",
                }}
              >
                <Text
                  style={{ fontWeight: 800, marginTop: 12, marginBottom: 10 }}
                >
                  Balance in your account{" "}
                </Text>
                <Text
                  style={{
                    color: COLORS.agreementFont,
                    fontWeight: "bold",
                    fontSize: "22px",
                  }}
                >
                  <div style={{ display: "flex", gap: 10 }}>
                    {selectedCurrency === "SGD" && (
                      <span>
                        SGD:{" "}
                        {parseFloat(
                          accountData?.accountBalance?.SGD ?? "0"
                        ).toFixed(2)}
                      </span>
                    )}
                    {selectedCurrency === "USD" && (
                      <span>
                        USD:{" "}
                        {parseFloat(
                          accountData?.accountBalance?.USD ?? "0"
                        ).toFixed(2)}
                      </span>
                    )}
                  </div>
                </Text>
                <MantineProvider
                  theme={{
                    colors: {
                      "fh-green": [
                        "#A2B6B4",
                        "#8DACA9",
                        "#78A4A0",
                        "#63A09A",
                        "#529992",
                        "#42938B",
                        "#338F86",
                        "#367872",
                        "#376662",
                        "#365855",
                      ],
                    },
                  }}
                >
                  <MButton
                    color="fh-green"
                    onClick={handleWithdrawalClick}
                    loading={isWithdrawalLoading}
                    disabled={
                      accountData?.accountBalance?.[selectedCurrency] === "0.00"
                    }
                  >
                    Withdraw Funds
                  </MButton>
                </MantineProvider>
              </div>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              {offerRoomData?.length > 1 && canNavigateBackward && (
                <button
                  onClick={handlePrev}
                  style={{
                    cursor: canNavigateBackward ? "pointer" : "not-allowed",
                    color: COLORS.agreementFont,
                    marginRight: "10px",
                    padding: "10px",
                    fontSize: "30px",
                    fontWeight: "bolder",
                    borderRadius: "80px",
                    border: "none",
                  }}
                >
                  ←
                </button>
              )}

              <div
                style={{
                  display: "flex",
                  flexWrap: "wrap",
                  width: offerRoomData?.length > 1 ? "100%" : "350px",
                  justifyContent: "space-between",
                }}
              >
                {visibleItems.map((data: any) => (
                  <div
                    key={data.id}
                    onMouseEnter={() => setHoveredId(data.id)}
                    onMouseLeave={() => setHoveredId(null)}
                    onClick={
                      hideNoteSubscribe
                        ? undefined
                        : () => handleBoxSelect(data)
                    }
                    style={getBoxStyle(data)}
                  >
                    <p style={{ fontWeight: "bold" }}>
                      Amount Available for
                      <br /> Investment
                    </p>
                    <p
                      style={{
                        color: COLORS.agreementFont,
                        fontWeight: "bold",
                        fontSize: "22px",
                      }}
                    >
                      {data?.currency}{" "}
                      {data?.amountAvailableForInvestment ?? "0"}
                    </p>
                    <p>Note : {data?.structuredNoteDisplayId}</p>
                    <p>Tenor: {data?.tenor} days</p>
                    <p>Interest Rate: {data?.snInterestRate} % p.a.</p>
                    <p>Total SKU’s: {data?.totalSKU}</p>
                    <p>Total Merchants: {data?.totalMerchants}</p>
                  </div>
                ))}
              </div>
              {offerRoomData?.length > 1 && canNavigateForward && (
                <button
                  onClick={handleNext}
                  style={{
                    cursor: canNavigateForward ? "pointer" : "not-allowed",
                    color: COLORS.agreementFont,
                    marginRight: "10px",
                    padding: "10px",
                    fontSize: "30px",
                    fontWeight: "bolder",
                    borderRadius: "80px",
                    border: "none",
                  }}
                >
                  →
                </button>
              )}
            </div>

            <Modal
              opened={openedWithdraw}
              onClose={modalAction.close}
              size="lg"
            >
              <Text
                ta="center"
                style={{
                  color: COLORS.agreementFont,
                  fontWeight: "bold",
                  fontSize: "22px",
                }}
              >
                Proceed to withdraw
              </Text>

              <div>
                <Text mt="lg" mb="lg">
                  Transfer to:
                </Text>

                <div
                  style={{
                    backgroundColor: "#D9D9D980",
                    borderRadius: 5,
                    padding: "10px",
                  }}
                >
                  <Text>Bank Name: {accountData?.investorBankName ?? "-"}</Text>
                  <Text>
                    Account Name:{" "}
                    {accountData?.investorBankAccountHolder ?? "-"}
                  </Text>
                  <Text>
                    Account Number: {accountData?.investorBankAccount ?? "-"}
                  </Text>
                  <Text>
                    Withdrawal Amount: {selectedCurrency}{" "}
                    {withdrawalFundDetail?.virtualAccountBalance}
                  </Text>
                </div>
              </div>
              <Text fz="sm" ta="center" mt="lg">
                Please note that {selectedCurrency}{" "}
                {withdrawalFundDetail?.totalFee === ""
                  ? "0"
                  : withdrawalFundDetail?.totalFee}{" "}
                will be charged by our payment service provider for each
                withdrawal.
              </Text>
              <Group mt="xl" position="center">
                <button
                  style={{
                    color: "white",
                    backgroundColor: COLORS.agreementFont,
                    borderRadius: "5px",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    padding: "12px 20px",
                    fontSize: "16px",
                    border: "none",
                  }}
                  onClick={modalAction.close}
                >
                  No, I’ll keep funds in my FundedHere Account
                </button>
                <button
                  style={{
                    color: "white",
                    backgroundColor: COLORS.darkGreyColor,
                    borderRadius: "5px",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    padding: "12px 20px",
                    fontSize: "16px",
                    border: "none",
                  }}
                  onClick={handleConfirmWithdrawalClick}
                >
                  Yes, I want to withdraw
                </button>
              </Group>
            </Modal>

            {selectedNoteCurrency !== "" && (
              <div>
                <Text
                  style={{
                    fontWeight: 800,
                    marginTop: 20,
                    marginBottom: 10,
                  }}
                >
                  {selectedNoteCurrency} Amount to Invest
                </Text>
                <input
                  type="text"
                  value={investorSubscribeAmount}
                  onChange={(e) => {
                    const inputValue = e.target.value;
                    if (
                      inputValue === "" ||
                      validateInvestorSubscribeAmount(inputValue)
                    ) {
                      setInvestorSubscribeAmount(inputValue);
                    }
                  }}
                  style={{
                    padding: "7px 2px 7px 2px",
                    outline: "none",
                    borderColor: "#D9DCE1",
                    marginBottom: 20,
                    width: "40%",
                    borderRadius: 3,
                  }}
                />
              </div>
            )}

            {!hideNoteSubscribe && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "20px",
                }}
              >
                <button
                  style={{
                    color: "white",
                    backgroundColor: COLORS.agreementFont,
                    borderRadius: "5px",
                    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
                    padding: "12px 80px",
                    fontSize: "20px",
                    border: "none",
                    cursor: selectedData ? "pointer" : "not-allowed",
                    opacity: selectedData ? 1 : 0.5,
                  }}
                  onClick={handleSubscribeClick}
                  disabled={
                    !selectedData &&
                    validateInvestorSubscribeAmount(investorSubscribeAmount)
                  }
                >
                  Subscribe
                </button>

                <ToastContainer />
              </div>
            )}
          </>
        )}

        {category === "subscribe" && !isAuthenticated && (
          <VQRVerifyPage
            onNext={handleOnVerifyMeNext}
            onAuthenticatedChange={handleAuthenticatedChange}
          />
        )}

        {category === "subscribe" && isAuthenticated && (
          <div className={classes.SubscribeOutter}>
            <div>
              <div className={classes.subscriptionAgreement}>
                <Text className={classes.aggrementTitle}>
                  Sign Subscription Agreement
                </Text>
              </div>
              <Text>
                Please review and sign the subscription agreement attached.
              </Text>
            </div>

            <div className={classes.fundAccount}>
              <Text className={classes.fundAccountText}>Fund your account</Text>
              <Text>
                Please make sure you have transferred up to{" "}
                {selectedNoteCurrency} {investorSubscribeAmount} into your
                account
              </Text>
              <div className={classes.paymentDetails}>
                <Text>Payment Details:</Text>
                <ul className={classes.listControl}>
                  <li>Bank Name: {accountData?.bankName ?? "N/A"}</li>
                  <li>Account Holder: {accountData?.accountHolder ?? "N/A"}</li>
                  <li>Account Number: {accountData?.accountNumber ?? "N/A"}</li>
                  <li>BIC/SWIFT: {accountData?.bicSwift ?? "N/A"}</li>
                  <li>Branch Code: {accountData?.branchCode ?? "N/A"}</li>
                  <li>Bank Code: {accountData?.bankCode ?? "N/A"}</li>
                  <li>Bank Address: {accountData?.bankAddress ?? "N/A"}</li>
                </ul>
              </div>
            </div>
            <ToastContainer />

            {realUrl !== "" && (
              <>
                <DocViewer
                  documents={docs}
                  style={{ width: "100%", height: 500, overflow: "auto" }}
                  className={classes.docviewer}
                  key={viewerKey}
                  config={{
                    header: {
                      disableHeader: false,
                      disableFileName: true,
                      retainURLParams: false,
                    },
                    pdfVerticalScrollByDefault: true,
                  }}
                />

                {completedAt === "" && (
                  <div className={classes.buttonContainer}>
                    <Button
                      onClick={openModal}
                      textColor="white"
                      backgroundColor="#338F86"
                      children="Proceed to Sign"
                    />
                  </div>
                )}

                {completedAt !== "" && (
                  <div className={classes.buttonContainer}>
                    <Button
                      onClick={handleBackSubscribedNote}
                      textColor="white"
                      backgroundColor="#338F86"
                      children="Subscribed Notes"
                    />
                  </div>
                )}
              </>
            )}

            {realUrl == "" && (
              <div>
                <Center maw={400} h={500} mx="auto">
                  <Loader />
                </Center>
              </div>
            )}

            <SignQRModal
              opened={qrModalOpened}
              close={closeModal}
              onSignVerify={handleSign}
            />
          </div>
        )}
      </div>
    </>
  );
};
export default StructureNote;