import { FC, useEffect, useState } from "react";
import {
  Button,
  InputGroup,
  InputSelect,
  NO_REGEX,
  PhoneInputGroup,
  color,
} from "@funded-here-interface/common";

import { useNavigate } from "react-router-dom";
import { useStyles } from "../../styles/BizProfileCss";
import { useSelector } from "react-redux";
import { RootState } from "@funded-here-interface/investor-onboarding/src/store";
import { useDispatch } from "react-redux";
import {
  setAddressRedux,
  setCountryOfResidenceRedux,
  setExperienceRedux,
  setIndustryOthersRedux,
  setIndustryGroupRedux,
  setIndustryDropdownValuesRedux,
  setIndustryRedux,
  setNationalityRedux,
  setGenderRedux,
  setEmploymentStatusRedux,
  setEmploymentPositionRedux,
  setdateOfBirthRedux,
  setCountryOfBirthRedux,
  setOccupationeRedux,
  setPhoneRedux,
  setPostalCodeRedux,
  setInvestmentAmountRedux,
} from "../../features/indinvestor/profSlice";
import {
  EmploymentPosition,
  EmploymentStatus,
  Industry,
  IndustryGroup,
} from "../../constants/enums";
import {
  industry as industryOtherOption,
  IndustryGroupMap,
} from "../../constants/dropdownValues";
import {
  CountryCode,
  countries,
  countryCodes,
} from "@funded-here-interface/common/src/constant/country";
import { useDisclosure } from "@mantine/hooks";
import { toast } from "react-toastify";
import IndInvestorDeclarationModal from "./Components/IndInvestorDeclarationModal/IndInvestorDeclarationModal";
import { camelCaseToNormalCase } from "../../Utils/formatter";

const BizProfileInd: FC = () => {
  const { classes } = useStyles();
  const Navigate = useNavigate();

  const {
    nationality: nationalityRedux,
    gender: genderRedux,
    employmentStatus: employmentStatusRedux,
    employmentPosition: employmentPositionRedux,
    dateOfBirth: dateOfBirthRedux,
    countryOfBirth: countryOfBirthRedux,
    address: addressRedux,
    countryOfResidence: countryOfResidenceRedux,
    industryGroup: industryGroupRedux,
    industryDropdownValues: industryDropdownValuesRedux,
    industry: ind,
    industryOthers: industryOthersRedux,
    phoneNumber: pn,
    postalCode: postalRedux,
    experience: experienceRedux,
    occupation: occ,
    investmentAmount: investmentAmountRedux,
    investorDeclarationQualified,
  } = useSelector((state: RootState) => state.prof);

  const [opened, { close }] = useDisclosure(!investorDeclarationQualified);
  const [nationality, setNationality] = useState(nationalityRedux || "");
  const [gender, setGender] = useState(genderRedux || "");
  const [employmentStatus, setEmploymentStatus] = useState(
    employmentStatusRedux || ""
  );
  const [employmentPosition, setEmploymentPosition] = useState(
    employmentPositionRedux || ""
  );
  const [dateOfBirth, setDateOfBirth] = useState(dateOfBirthRedux || "");
  const [countryOfBirth, setCountryOfBirth] = useState(
    countryOfBirthRedux || ""
  );
  const [countryOfResidence, setCountryOfResidence] = useState(
    countryOfResidenceRedux || ""
  );
  const [postalCode, setPostalCode] = useState(postalRedux || "");
  const [address, setAddress] = useState(addressRedux || "");
  const [industryGroup, setIndustryGroup] = useState(industryGroupRedux || "");
  const [industryDropdownValues, setIndustryDropdownValues] = useState<
    Industry[]
  >(industryDropdownValuesRedux || []);
  const [industry, setIndustry] = useState(ind || "");
  const [occupation, setOccupation] = useState(occ || "");
  const [experience, setExperience] = useState(experienceRedux || "");
  const [phoneNumber, setPhone] = useState(pn || "");
  const [industryOthers, setIndustryOthers] = useState(
    industryOthersRedux || ""
  );
  const [isOtherIndustry, setIsOtherIndustry] = useState(
    ind === Industry.OTHERS
  );
  const [investmentAmount, setInvestmentAmount] = useState(
    investmentAmountRedux || 0
  );

  const errorFields = {
    industryGroup: "",
    industry: "",
    industryOthers: "" as string | undefined,
    occupation: "",
    experience: "",
    phoneNumber: "",
    nationality: "",
    gender: "",
    employmentStatus: "",
    employmentPosition: "",
    dateOfBirth: "",
    countryOfBirth: "",
    countryOfResidence: "",
    address: "",
    postalCode: "",
    investmentAmount: "" as string | undefined,
  };

  // Remove custom message fields from default error fields
  const defaultErrorFields = { ...errorFields };
  delete defaultErrorFields.industryOthers;
  delete defaultErrorFields.investmentAmount;

  const [error, setError] = useState(errorFields);
  const dispatch = useDispatch();

  useEffect(() => {
    if (investorDeclarationQualified === false) {
      Navigate("/unqualified-investor");
    }
  }, [investorDeclarationQualified]);

  const handleSubmit = () => {
    const customError = { ...error };
    let valid = true;

    for (const key in defaultErrorFields) {
      let fieldExists = false;
      try {
        // eval to check if variable exists
        // eslint-disable-next-line no-eval
        fieldExists = eval(key);
      } catch (e) {
        // Do Nothing
      }

      if (!fieldExists) {
        valid = false;
        customError[key as keyof typeof errorFields] =
          camelCaseToNormalCase(key) + ` is required`;
      }
    }

    if (isOtherIndustry && !industryOthers) {
      valid = false;
      customError.industryOthers = "Industry Specification is required";
    }

    if (!investmentAmount) {
      valid = false;
      customError.investmentAmount = "Planned Investment Amount is required";
    }

    if (valid) {
      dispatch(setIndustryGroupRedux({ industryGroup }));
      dispatch(setIndustryDropdownValuesRedux({ industryDropdownValues }));
      dispatch(setIndustryRedux({ industry }));
      dispatch(setIndustryOthersRedux({ industryOthers }));
      dispatch(setExperienceRedux({ experience }));
      dispatch(setOccupationeRedux({ occupation }));
      dispatch(setPhoneRedux({ phoneNumber }));
      dispatch(setNationalityRedux({ nationality }));
      dispatch(setGenderRedux({ gender }));
      dispatch(setEmploymentStatusRedux({ employmentStatus }));
      dispatch(setEmploymentPositionRedux({ employmentPosition }));
      dispatch(setdateOfBirthRedux({ dateOfBirth }));
      dispatch(setCountryOfBirthRedux({ countryOfBirth }));
      dispatch(setCountryOfResidenceRedux({ countryOfResidence }));
      dispatch(setAddressRedux({ address }));
      dispatch(setPostalCodeRedux({ postalCode }));
      dispatch(
        setInvestmentAmountRedux({
          investmentAmount,
        })
      );

      Navigate("/bank-details-ind");
    } else {
      setError(customError);
    }
  };

  const onIndustryGroupChange = (value: IndustryGroup) => {
    setIndustryDropdownValues([]);
    setIndustry("");

    let industry: Industry[] = [];

    industry = IndustryGroupMap[value];

    setIndustryGroup(value);
    setIndustryDropdownValues([...industry, ...industryOtherOption]);
  };

  const onIndustryChange = (value: Industry) => {
    if (value === Industry.OTHERS) {
      setIsOtherIndustry(true);
    } else {
      setIsOtherIndustry(false);
      setIndustryOthers("");
    }

    setIndustry(value);
    error.industry = "";
  };

  const [countryCode, setCountryCode] = useState<CountryCode | undefined>();

  const handleCountryChange = (value: string) => {
    error.countryOfResidence = "";
    setCountryOfResidence(value);
    const code = countryCodes[value as keyof typeof countryCodes];
    setCountryCode(code as CountryCode);
  };

  return (
    <>
      <IndInvestorDeclarationModal
        onError={(value: string) => {
          toast.error(value);
        }}
        opened={opened}
        close={close}
      />
      <div style={{ marginTop: "70px" }}>
        <InputSelect
          isErrored={error.industry}
          onChange={onIndustryGroupChange}
          labelText="Industry Group"
          value={industryGroup}
          id="industryGroup"
          data={Object.values(IndustryGroup)}
        />
        <InputSelect
          isErrored={error.industry}
          onChange={onIndustryChange}
          labelText="Industry"
          value={industry}
          id="institution_type"
          data={industryDropdownValues}
        />
        {isOtherIndustry && (
          <InputGroup
            isErrored={error.industryOthers}
            onChange={(value: string) => {
              error.industryOthers = "";
              setIndustryOthers(value);
            }}
            labelText="Please Specify Industry"
            id="industry_others"
            value={industryOthers}
          />
        )}
        <InputGroup
          isErrored={error?.occupation}
          onChange={(value: string) => {
            error.occupation = "";
            setOccupation(value);
          }}
          labelText="Specify Occupation"
          id="name"
          value={occupation}
        />
        <InputGroup
          isErrored={error?.experience}
          onChange={(value: string) => {
            error.experience = "";
            setExperience(value.replace(NO_REGEX, ""));
          }}
          labelText="No of years in this occupation"
          id="experience"
          value={experience}
        />
        <InputSelect
          isErrored={error.nationality}
          onChange={(value: string) => {
            error.nationality = "";
            setNationality(value);
          }}
          labelText="Nationality"
          id="nationality"
          value={nationality}
          data={countries}
        />
        <InputSelect
          isErrored={error.gender}
          onChange={(value: string) => {
            error.gender = "";
            setGender(value);
          }}
          labelText="Gender"
          id="gender"
          value={gender}
          data={["Female", "Male"]}
        />
        <InputSelect
          isErrored={error.employmentStatus}
          onChange={(value: string) => {
            error.employmentStatus = "";
            setEmploymentStatus(value);
          }}
          labelText="Employment Status"
          id="employmentStatus"
          value={employmentStatus}
          data={Object.values(EmploymentStatus)}
        />
        <InputSelect
          isErrored={error.employmentPosition}
          onChange={(value: string) => {
            error.employmentPosition = "";
            setEmploymentPosition(value);
          }}
          labelText="Employment Position"
          id="employmentPosition"
          value={employmentPosition}
          data={Object.values(EmploymentPosition)}
        />
        <InputGroup
          isErrored={error.dateOfBirth}
          onChange={(value: any) => {
            error.dateOfBirth = "";
            setDateOfBirth(value);
          }}
          labelText="Date of Birth"
          value={dateOfBirth}
          id="dateOfBirth"
          type="date"
        />
        <InputSelect
          isErrored={error.countryOfBirth}
          onChange={(value: any) => {
            error.countryOfBirth = "";
            setCountryOfBirth(value);
          }}
          labelText="Country of Birth"
          id="countryOfBirth"
          value={countryOfBirth}
          data={countries}
        />
        <InputSelect
          isErrored={error.countryOfResidence}
          onChange={handleCountryChange}
          labelText="Country of residence"
          id="countryOfResidence"
          value={countryOfResidence}
          data={countries}
        />
        <InputGroup
          isErrored={error.address}
          onChange={(value: string) => {
            error.address = "";
            setAddress(value);
          }}
          labelText="Address"
          id="address"
          value={address}
        />
        <InputGroup
          isErrored={error.postalCode}
          onChange={(value: string) => {
            error.postalCode = "";
            setPostalCode(value.replace(NO_REGEX, ""));
          }}
          labelText="Postal Code"
          id="postalcode"
          value={postalCode}
        />
        <InputGroup
          isErrored={error.investmentAmount}
          onChange={(value: string) => {
            error.investmentAmount = "";
            setInvestmentAmount(parseInt(value.replace(NO_REGEX, "")));
          }}
          labelText="Planned Investment Amount"
          id="investmentAmount"
          value={`${investmentAmount || 0}`}
        />
        <div className={classes.phoneNumberWrapper}>
          <PhoneInputGroup
            isErrored={error?.phoneNumber}
            value={phoneNumber}
            country={countryCode}
            onChange={(value: string) => {
              error.phoneNumber = "";
              setPhone(value.replace(NO_REGEX, ""));
            }}
          />
        </div>
        <Button
          onClick={handleSubmit}
          textColor={color.WHITE}
          backgroundColor={color.FHGREEN}
          children="Next"
          width="100%"
        />
      </div>
    </>
  );
};

export default BizProfileInd;
