import { EntityTypeEnum } from "@funded-here-interface/common/src/constant/enum";
import { createSlice } from "@reduxjs/toolkit";

interface OrgDirectorType {
  firstName: string;
  lastName: string;
  email: string;
  spvBoardResolutionSigned?: boolean;
  isAuthRep?: boolean;
  isShareholder?: boolean;
  userId?: number;
  orgRoleId?: number;
}

interface Spv {
  companyName?: string;
  legalEntityAddress?: string;
  companyRegistrationNo?: string;
  legalStructure?: string;
  industry?: string;
  countryISOCode?: string;
  countryOfIncorporation?: string;
  companyPhoneNumber?: string;
  nameOfAuthorisedRepresentiative?: string;
  postalCode?: number;
  titleOfAuthorisedRepresentiative?: string;
  emailOfAuthorisedRepresentiative?: string;
  authRepIsMerchant?: boolean;
  orgRoleIdOfAuthorisedRepresentiative?: number;
  website?: string;
  boardOfDirectors?: OrgDirectorType[];
  kycStatus?: string;
  createdAt: Date;
}

interface AuthState {
  token: string;
  userId: string;
  spvStatus: string | null;
  type: string | null;
  userEmail: string | null;
  role: string | null;
  orgId: number | null;
  orgRoleId: number | null;
  userFirstName: string | null;
  userLastName: string | null;
  agreedToMembershipAgreement: boolean | null;
  agreedToSpvMembershipAgreement: boolean | null;
  isAuthRep: boolean | null;
  hasCreditLimit: boolean | null;
  entityType: EntityTypeEnum | null;
  isAdminAuthRep: boolean | null;
  spvName: string | null;
  spvDetails: Spv | null;
  orgName: string | null;
}

const initialState: AuthState = {
  token: "none",
  userId: "",
  spvStatus: null,
  type: null,
  userEmail: null,
  role: null,
  orgId: null,
  orgRoleId: null,
  userFirstName: null,
  userLastName: null,
  agreedToMembershipAgreement: null,
  agreedToSpvMembershipAgreement: null,
  isAuthRep: null,
  hasCreditLimit: null,
  entityType: null,
  isAdminAuthRep: null,
  spvName: null,
  spvDetails: null,
  orgName: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setToken: (state, action) => {
      const { token } = action.payload;
      state.token = token;
    },
    setUserID: (state, action) => {
      const { userId } = action.payload;
      state.userId = userId;
    },
    setUserSpv: (state, action) => {
      const { spvStatus } = action.payload;
      state.spvStatus = spvStatus;
    },
    setOnboardingType: (state, action) => {
      const { type } = action.payload;
      state.type = type;
    },
    setUserEmail: (state, action) => {
      const { userEmail } = action.payload;
      state.userEmail = userEmail;
    },
    setRole: (state, action) => {
      const { role } = action.payload;
      state.role = role;
    },
    setOrgId: (state, action) => {
      const { orgId } = action.payload;
      state.orgId = orgId;
    },
    setOrgRoleId: (state, action) => {
      const { currentOrgRoleId } = action.payload;
      state.orgRoleId = currentOrgRoleId;
    },
    setUserFirstName: (state, action) => {
      const { userFirstName } = action.payload;
      state.userFirstName = userFirstName;
    },
    setUserLastName: (state, action) => {
      const { userLastName } = action.payload;
      state.userLastName = userLastName;
    },
    setAgreedToMembershipAgreement: (state, action) => {
      const { agreedToMembershipAgreement } = action.payload;
      state.agreedToMembershipAgreement = agreedToMembershipAgreement;
    },
    setAgreedToSpvMembershipAgreement: (state, action) => {
      const { agreedToSpvMembershipAgreement } = action.payload;
      state.agreedToSpvMembershipAgreement = agreedToSpvMembershipAgreement;
    },
    setIsAuthRep: (state, action) => {
      const { isAuthRep } = action.payload;
      state.isAuthRep = isAuthRep;
    },
    setHasCreditLimit: (state, action) => {
      const { hasCreditLimit } = action.payload;
      state.hasCreditLimit = hasCreditLimit;
    },
    setEntityType: (state, action) => {
      const { entityType } = action.payload;
      state.entityType = entityType;
    },
    setIsAdminAuthRep: (state, action) => {
      const { isAdminAuthRep } = action.payload;
      state.isAdminAuthRep = isAdminAuthRep;
    },
    setSpvName: (state, action) => {
      const { spvName } = action.payload;
      state.spvName = spvName;
    },
    setSpvDetails: (state, action) => {
      const { spvDetails } = action.payload;
      state.spvDetails = spvDetails;
    },
    setOrgName: (state, action) => {
      const { orgName } = action.payload;
      state.orgName = orgName;
    },
    resetAuthState: () => initialState,
  },
});

export const {
  setToken,
  setUserID,
  setUserSpv,
  setOnboardingType,
  setUserEmail,
  setRole,
  setOrgId,
  setOrgRoleId,
  setUserFirstName,
  setUserLastName,
  setAgreedToMembershipAgreement,
  setAgreedToSpvMembershipAgreement,
  setIsAuthRep,
  setHasCreditLimit,
  setEntityType,
  setIsAdminAuthRep,
  setSpvName,
  resetAuthState,
  setSpvDetails,
  setOrgName,
} = authSlice.actions;
export default authSlice.reducer;
