import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import PortfolioReturnAnalysis from "./PortfolioReturnAnalysis";
import NotesReturnAnalysis from "./NotesReturnAnalysis";

const ReturnAnalysis = () => {
  return (
    <>
      <div className="returnAnDiv">
        <Tabs>
          <TabList>
            {/* <Tab>
              Notes Return Analysis
              <span class="ReturnAnTooltip">Click here to follow link</span>
            </Tab> */}
            {/* <Tab>
              Portfolio Return Analysis
              <span class="ReturnAnTooltip">Click here to follow link</span>
            </Tab> */}
          </TabList>

          <TabPanel>
            <NotesReturnAnalysis />
          </TabPanel>
          <TabPanel>
            <PortfolioReturnAnalysis />
          </TabPanel>
        </Tabs>
      </div>
    </>
  );
};

export default ReturnAnalysis;
