import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { ApiHelperFunction } from "../../../Utils/HttpClient";

let initialState = {
  status: "idle",
  activePaymentDetails: null,
  closePaymentDetails: null,
  amountDueAnalysisReport: null,
  allSalesReport: null,
  skuAndDoiReport: null,
  isLoading: false,
  isSuccess: false,
  isError: false,
};

export const getActivePayment = createAsyncThunk(
  "get_all_payment_merchant",
  async (id, token) => {
    let response = await ApiHelperFunction({
      urlPath: "view-payment-tracking-byMarchent",
      method: "POST",
      token: `Bearer ${token}`,
    });

    if (response.status === 200) {
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const getClosePayment = createAsyncThunk(
  "view_close_payment_by_merchant",
  async (id, token, status) => {
    let response = await ApiHelperFunction({
      urlPath: "view-payment-tracking-byMarchent",
      method: "POST",
      data: { status: "closed" },
      token: `Bearer ${token}`,
    });

    if (response.status === 200) {
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const getDefaultPayment = createAsyncThunk(
  "get_default_payment_merchant",
  async (id) => {
    let response = await ApiHelperFunction({
      urlPath: "merchant-default-payment-in-merchant-summary",
      method: "GET",
    });

    if (response.status === 200) {
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const getAmountDueAnalysis = createAsyncThunk(
  "get_amount_due_analysis_by_merchant",
  async (id, token) => {
    let response = await ApiHelperFunction({
      urlPath: "ammout-due-analysis-report-byMarchant/75",
      method: "GET",
      token: token,
    });

    if (response.status === 200) {
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const getSkuAndDoiSales = createAsyncThunk(
  "get_sku_and_doi",
  async (id, token) => {
    let response = await ApiHelperFunction({
      urlPath: `sales-comparison-report-byMarchent/75`,
      method: "POST",
      token: token,
    });

    if (response.status === 200) {
      return response.data;
    } else {
      toast.error(response?.response?.data?.message);
    }
  }
);

export const PaymentTrackingSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    clearState: (state) => {
      state.activePaymentDetails = null;
      state.closePaymentDetails = null;
      state.amountDueAnalysisReport = null;
      state.skuAndDoiReport = null;
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
    },
  },
  extraReducers: (builder) => {
    builder

      /*------------------ get active payment details  --------------- */
      .addCase(getActivePayment.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getActivePayment.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        state.isLoading = false;
        state.activePaymentDetails = payload?.data;
      })
      .addCase(getActivePayment.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
        state.isLoading = false;
      })

      /*------------------ get close payment details  --------------- */
      .addCase(getClosePayment.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getClosePayment.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        state.isLoading = false;
        state.closePaymentDetails = payload?.data;
      })
      .addCase(getClosePayment.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
        state.isLoading = false;
      })

      /*------------------ get default payment details  --------------- */
      .addCase(getDefaultPayment.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getDefaultPayment.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        state.isLoading = false;
        state.defaultPaymentDetails = payload?.data;
      })
      .addCase(getDefaultPayment.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
        state.isLoading = false;
      })

      /*------------------ get amount due analysis report  --------------- */
      .addCase(getAmountDueAnalysis.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getAmountDueAnalysis.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        state.isLoading = false;
        state.amountDueAnalysisReport = payload?.data;
      })
      .addCase(getAmountDueAnalysis.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
        state.isLoading = false;
      })

      /*------------------ get sku and doi sales report --------------- */
      .addCase(getSkuAndDoiSales.pending, (state) => {
        state.status = "loading";
        state.isLoading = true;
      })
      .addCase(getSkuAndDoiSales.fulfilled, (state, { payload }) => {
        state.status = "success";
        state.isSuccess = true;
        state.isLoading = false;
        state.skuAndDoiReport = payload?.data;
      })
      .addCase(getSkuAndDoiSales.rejected, (state) => {
        state.status = "failed";
        state.isError = true;
        state.isSuccess = false;
        state.isLoading = false;
      });
  },
});

export const { clearState } = PaymentTrackingSlice.actions;

export default PaymentTrackingSlice.reducer;
