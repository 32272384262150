import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import HttpClientXmInv from "../../../../Utils/HttpClientXmInv";

const NotesReturnAnalysis = () => {
  const [notesData, setNotesData] = useState([]);
  const [chartWidth, setChartWidth] = useState(0);

  const token = useSelector((state) => state.auth.token);

  const state = {
    notesSeries: [
      {
        name: "Weighted Average Effective Return",
        data: notesData.map((data) => data.effectiveROR),
      },
    ],
    options: {
      chart: {
        type: "area",
        zoom: {
          enabled: false,
        },
      },
      yaxis: {
        min: 0,
        labels: {
          formatter: function (value) {
            return value.toFixed(2) + "%";
          },
        },
      },
      xaxis: {
        labels: {
          formatter: function (value) {
            const date = new Date(value);
            if (!isNaN(date)) {
              return date.toISOString().split("T")[0];
            } else {
              return "";
            }
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
        lineCap: "butt",
        width: 2,
        colors: ["#efb5b9"],
      },
      fill: {
        colors: ["#f9e1e3"],
        opacity: 0.9,
        type: "solid",
        gradient: {
          shade: "dark",
          type: "horizontal",
          shadeIntensity: 0.5,
          gradientToColors: undefined,
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 100],
          colorStops: [],
        },
      },
      markers: {
        size: 3,
        colors: "#efb5b9",
        strokeColors: "#efb5b9",
        hover: {
          size: 3,
        },
      },
      title: {
        text: "Weighted Average Effective Return",
        align: "left",
        style: {
          fontSize: "13px",
          colors: "#595959",
        },
      },
      labels: notesData.map((data) => {
        const date = new Date(data.date);
        return !isNaN(date) ? date.toISOString().split("T")[0] : "";
      }),
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
    },
  };

  const fetchNotesData = async () => {
    // const res = await AuthServices.getNotesReturn(token);
    const res = await HttpClientXmInv.requestData(
      `investor-wise-effective-ror`,
      {},
      token,
      "GET"
    );
    if (res && res.status) {
      setNotesData(res.data);
    } else {
      toast.error("Error fetching portfolio data");
    }
  };

  useEffect(() => {
    fetchNotesData();

    const width = Math.max(notesData.length * 40, 400);

    setChartWidth(width);
  }, [notesData.length]);

  return (
    <>
      <ReactApexChart
        options={state.options}
        series={state.notesSeries}
        width={chartWidth}
        height={500}
        type="area"
      />
    </>
  );
};

export default NotesReturnAnalysis;
