import React, { useEffect, useRef, useState } from "react";
import { useStyle } from "./DeclarationAccreditedInvestor.styles";
import { Center, Loader, Text } from "@mantine/core";
import { Button } from "@funded-here-interface/common";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useDeclarationOfAISign } from "../../hooks/useDocuSign";
import { REST_SERVER, WEB_DOMAIN } from "../../constants/domain";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";
import useSignedDeclarationOfAI from "../../hooks/useSignedDeclarationOfAI";
import { useLocation, useNavigate } from "react-router-dom";
import VQRVerifyPage from "../../components/VQR/VQRVerifyPage";
import useCompleteOnboarding from "../../hooks/useCompleteOnboarding";
import { EntityTypeEnum } from "@funded-here-interface/common/src/constant/enum";
import DocViewer, { PDFRenderer, PNGRenderer } from "@cyntler/react-doc-viewer";
import { useDisclosure } from "@mantine/hooks";
import SignQRModal from "../../components/SignQRModal/SignQRModal";
import useSignedDocument from "../../hooks/useSignedDocument";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

const DeclarationAccreditedInvestor = () => {
  const location = useLocation();
  const skipDidVerify = location.state?.skipDidVerify;
  const { classes } = useStyle();
  const declarationOfAIMutation = useDeclarationOfAISign();
  const signedDocument = useSignedDocument();
  const { token, entityType } = useSelector((state: RootState) => state.auth);
  const useSignedDeclarationOfAIMutation = useSignedDeclarationOfAI();
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    skipDidVerify ?? false
  );
  const navigate = useNavigate();
  const useCompleteOnboardingMutation = useCompleteOnboarding();

  const [realUrl, setRealUrl] = useState("");
  const [documentId, setDocumentId] = useState(0);
  const [completedAt, setCompletedAt] = useState("");

  const [opened, { open, close }] = useDisclosure(false);
  const docs = [
    {
      uri: realUrl,
      fileType: "pdf",
    },
  ];

  const [viewerKey, setViewerKey] = useState(0);

  const handleAuthenticatedChange = (isAuthenticated: boolean) => {
    setIsAuthenticated(isAuthenticated);
    if (isAuthenticated) {
      getDeclarationOfAI();
    }
  };

  useEffect(() => {
    if (skipDidVerify) {
      getDeclarationOfAI();
      setViewerKey((prevKey) => prevKey + 1);
    }
  }, []);

  const getDeclarationOfAI = () => {
    toast.info("Document is loading");
    try {
      declarationOfAIMutation.mutate(
        {
          token,
          webDomain: WEB_DOMAIN,
          redirectUrl: `${REST_SERVER}/document-management/docusign/webhook`,
        },
        {
          onSuccess: async (data) => {
            setRealUrl(data.url);
            setDocumentId(data.documentId);
          },
          onError: (e) => {
            toast.error((e as Error).message);
          },
        }
      );
    } catch (ex) {
      toast.error("Error initializing DocuSign");
    }
  };

  const handleNext = () => {
    useSignedDeclarationOfAIMutation.mutate(
      { token },
      {
        onSuccess: () => {
          if (entityType === EntityTypeEnum.INSTITUTION) {
            useCompleteOnboardingMutation.mutate(
              { token },
              {
                onSuccess: () => {
                  navigate("/registration-completed");
                },
                onError: (e) => {
                  toast.error((e as Error).message);
                },
              }
            );
          } else {
            navigate("/thanks-page");
          }
        },
        onError: (e) => {
          toast.error((e as Error).message);
        },
      }
    );
  };

  const handleSign = (isAuthenticated: boolean) => {
    if (isAuthenticated) {
      close();
      setRealUrl("");
      signedDocument.mutate(
        { documentId, token },
        {
          onSuccess: (data) => {
            toast.success("Document Signed Successfully");
            setRealUrl(data.url);
            setDocumentId(data.documentId);
            setCompletedAt(data.completedAt);
            setViewerKey((prevKey) => prevKey + 1);
          },
          onError: (e) => {
            toast.error((e as Error).message);
          },
        }
      );
    }
  };

  const handleOnVerifyMeNext = () => {
    setIsAuthenticated(true);
  };

  const openModal = () => {
    setViewerKey((prevKey) => prevKey + 1);
    open();
  };

  const closeModal = () => {
    setViewerKey((prevKey) => prevKey + 1);
    close();
  };

  return (
    <>
      {!isAuthenticated && (
        <div className={classes.topVerifyDiv}>
          <VQRVerifyPage
            onAuthenticatedChange={handleAuthenticatedChange}
            onNext={handleOnVerifyMeNext}
          />
        </div>
      )}
      {isAuthenticated && (
        <>
          <div className={classes.tempWideDisplay}>
            <div className={classes.topDiv}>
              <p className={classes.headinfo}>Declaration of AI</p>
            </div>

            {realUrl !== "" && (
              <>
                <DocViewer
                  pluginRenderers={[PDFRenderer, PNGRenderer]}
                  documents={docs}
                  key={viewerKey}
                  className={classes.docviewer}
                  style={{ width: "100%", height: 600, overflow: "auto" }}
                  config={{
                    header: {
                      disableHeader: false,
                      disableFileName: true,
                      retainURLParams: false,
                    },
                    pdfVerticalScrollByDefault: true, // false as default
                  }}
                />

                {completedAt === "" && (
                  <div className={classes.buttonContainer}>
                    <Button
                      onClick={openModal}
                      textColor="white"
                      backgroundColor="#338F86"
                      children="Proceed to Sign"
                    />
                  </div>
                )}

                {completedAt !== "" && (
                  <div className={classes.buttonContainer}>
                    <Button
                      onClick={handleNext}
                      textColor="white"
                      backgroundColor="#338F86"
                      children="Okay"
                    />
                  </div>
                )}
              </>
            )}

            {realUrl == "" && (
              <div>
                <Center maw={400} h={500} mx="auto">
                  <Loader />
                </Center>
              </div>
            )}
          </div>

          <SignQRModal
            opened={opened}
            close={closeModal}
            onSignVerify={handleSign}
          ></SignQRModal>
        </>
      )}
    </>
  );
};

export default DeclarationAccreditedInvestor;
