import { FC, useState } from "react";
import {
  Button,
  InputGroup,
  InputSelect,
  PhoneInputGroup,
} from "@funded-here-interface/common";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "@funded-here-interface/investor-onboarding/src/store";
import { useDispatch } from "react-redux";
import {
  setCompanyRedux,
  setRegNumberRedux,
  setLegalStructureRedux,
  setTypeOfInstituteRedux,
  setAddressRedux,
  setPostalRedux,
  setTurnOverRedux,
  setWebsiteRedux,
  setLegalStructureOthersRedux,
  setIndustryGroupRedux,
  setIndustryDropdownValuesRedux,
  setIndustryOthersRedux,
  setCountryOfIncorpRedux,
  setCountryOfDomicileRedux,
  setPhoneNumber,
} from "../../features/merchant/biz";
import {
  CountryCode,
  countries,
  countryCodes,
} from "@funded-here-interface/common/src/constant/country";
import "react-toastify/dist/ReactToastify.css";
import { NO_REGEX } from "@funded-here-interface/common";
import { Industry, LegalStructure, IndustryGroup } from "../../constants/enums";
import {
  industry as industryOtherOption,
  IndustryGroupMap,
  LegalStructures,
} from "../../constants/dropdownValues";
import { camelCaseToNormalCase } from "../../Utils/formatter";

const BorrowerBizProfile: FC = () => {
  const Navigate = useNavigate();

  const {
    company: comp,
    regNumber: regno,
    legalStructure: legalStructureRedux,
    legalStructureOthers: legalStructureOthersRedux,
    industryGroup: industryGroupRedux,
    industryDropdownValues: industryDropdownValuesRedux,
    industryOthers: industryOthersRedux,
    typeOfInstitute,
    countryOfDomicile: countryOfDomicileRedux,
    countryOfIncorp: countryOfIncorpRedux,
    address: addressRedux,
    postal: postalRedux,
    website: companysiteRedux,
    turnover: turnOverRedux,
    phoneNumber: phoneNumberRedux,
  } = useSelector((state: RootState) => state.borBiz);

  const [companyName, setCompany] = useState(comp || "");
  const [isOtherLegalStructure, setIsOtherLegalStructure] = useState(
    legalStructureRedux === LegalStructure.OTHERS
  );
  const [isOtherIndustry, setIsOtherIndustry] = useState(
    typeOfInstitute === Industry.OTHERS
  );
  const [registrationNumber, setRegNumber] = useState(regno || "");
  const [legalStructure, setLegStr] = useState(legalStructureRedux || "");
  const [legalStructureOthers, setLegalStructurOthers] = useState(
    legalStructureOthersRedux || ""
  );
  const [institutionType, setInstitute] = useState(typeOfInstitute || "");
  const [industryGroup, setIndustryGroup] = useState(industryGroupRedux || "");
  const [industryDropdownValues, setIndustryDropdownValues] = useState<
    Industry[]
  >(industryDropdownValuesRedux || []);
  const [industryOthers, setIndustryOthers] = useState(
    industryOthersRedux || ""
  );
  const [countryOfIncorporation, setCountryCountryOfIncorp] = useState(
    countryOfIncorpRedux || ""
  );
  const [countryOfDomicile, setCountryCountryOfDomicile] = useState(
    countryOfDomicileRedux || ""
  );
  const [address, setAddress] = useState(addressRedux || "");
  const [postalcode, setPostalCode] = useState(postalRedux || "");
  const [companyWebsite, setcompanysite] = useState(companysiteRedux || "");
  const [financialTurnover, setTurnover] = useState(turnOverRedux || "");
  const [phoneNumber, setPhone] = useState(phoneNumberRedux || "");

  const errorFields = {
    companyName: "",
    registrationNumber: "",
    legalStructure: "",
    legalStructureOthers: "" as string | undefined,
    industryGroup: "",
    institutionType: "" as string | undefined,
    industryOthers: "" as string | undefined,
    countryOfIncorporation: "",
    countryOfDomicile: "",
    address: "",
    phoneNumber: "",
    postalcode: "",
    companyWebsite: "",
    financialTurnover: "",
  };

  const [error, setError] = useState(errorFields);
  const dispatch = useDispatch();

  const handleSubmit = () => {
    const customError = { ...error };
    let valid = true;

    // Remove custom message fields from default error fields
    const defaultErrorFields = { ...errorFields };
    delete defaultErrorFields.legalStructureOthers;
    delete defaultErrorFields.institutionType;
    delete defaultErrorFields.industryOthers;

    for (const key in defaultErrorFields) {
      let fieldExists = false;
      try {
        // eval to check if variable exists
        // eslint-disable-next-line no-eval
        fieldExists = eval(key);
      } catch (e) {
        // Do Nothing
      }

      if (!fieldExists) {
        valid = false;
        customError[key as keyof typeof errorFields] =
          camelCaseToNormalCase(key) + ` is required`;
      }
    }

    if (isOtherLegalStructure && !legalStructureOthers) {
      valid = false;
      customError.legalStructureOthers =
        "Legal Structure Specification is required";
    }

    if (!institutionType) {
      valid = false;
      customError.institutionType = "Industry is required";
    }

    if (isOtherIndustry && !industryOthers) {
      valid = false;
      customError.industryOthers = "Industry Specification is required";
    }

    if (valid) {
      dispatch(setCompanyRedux({ company: companyName }));
      dispatch(setRegNumberRedux({ regNumber: registrationNumber }));
      dispatch(setIndustryGroupRedux({ industryGroup: industryGroup }));
      dispatch(setIndustryDropdownValuesRedux({ industryDropdownValues }));
      dispatch(setLegalStructureRedux({ legalStructure: legalStructure }));
      dispatch(
        setLegalStructureOthersRedux({
          legalStructureOthers,
        })
      );
      dispatch(
        setIndustryOthersRedux({
          industryOthers,
        })
      );
      dispatch(
        setCountryOfIncorpRedux({ countryOfIncorp: countryOfIncorporation })
      );
      dispatch(setCountryOfDomicileRedux({ countryOfDomicile }));
      dispatch(setAddressRedux({ address }));
      dispatch(setTypeOfInstituteRedux({ typeOfInstitute: institutionType }));
      dispatch(setPostalRedux({ postal: postalcode }));
      dispatch(setWebsiteRedux({ website: companyWebsite }));
      dispatch(setTurnOverRedux({ turnover: financialTurnover }));
      dispatch(setPhoneNumber({ phoneNumber }));

      Navigate("/bor-rep");
    } else {
      setError(customError);
    }
  };

  const onLegalStructureChange = (value: LegalStructure) => {
    if (value === LegalStructure.OTHERS) {
      setIsOtherLegalStructure(true);
    } else {
      setIsOtherLegalStructure(false);
      setLegalStructurOthers("");
    }

    setLegStr(value);
    error.legalStructure = "";
  };

  const onIndustryGroupChange = (value: IndustryGroup) => {
    setIndustryDropdownValues([]);
    setInstitute("");

    let industry: Industry[] = [];

    industry = IndustryGroupMap[value];

    setIndustryGroup(value);
    setIndustryDropdownValues([...industry, ...industryOtherOption]);
  };

  const onIndustryChange = (value: Industry) => {
    if (value === Industry.OTHERS) {
      setIsOtherIndustry(true);
    } else {
      setIsOtherIndustry(false);
      setIndustryOthers("");
    }

    setInstitute(value);
    error.institutionType = "";
  };
  const [countryCode, setCountryCode] = useState<CountryCode | undefined>();

  const handleCountryOfDomicileChange = (value: string) => {
    error.countryOfDomicile = "";
    setCountryCountryOfDomicile(value);
    const code = countryCodes[value as keyof typeof countryCodes];
    setCountryCode(code as CountryCode);
  };

  return (
    <div style={{ marginTop: "70px" }}>
      <InputGroup
        isErrored={error.companyName}
        onChange={(value: any) => {
          error.companyName = "";
          setCompany(value);
        }}
        labelText="Company Name"
        id="companyName"
        value={companyName}
      />
      <InputGroup
        isErrored={error.registrationNumber}
        onChange={(value: string) => {
          error.registrationNumber = "";
          setRegNumber(value);
        }}
        labelText="Registration Number"
        id="registrationNumber"
        value={registrationNumber}
      />
      <InputSelect
        isErrored={error.legalStructure}
        onChange={onLegalStructureChange}
        labelText="Legal Structure"
        id="legalStructure"
        value={legalStructure}
        data={LegalStructures}
      />
      {isOtherLegalStructure && (
        <InputGroup
          isErrored={error.legalStructureOthers}
          onChange={(value: string) => {
            error.legalStructureOthers = "";
            setLegalStructurOthers(value);
          }}
          labelText="Please Specify Legal Structure"
          id="legalStructureOthers"
          value={legalStructureOthers}
        />
      )}
      <InputSelect
        isErrored={error.industryGroup}
        onChange={onIndustryGroupChange}
        labelText="Industry Group"
        value={industryGroup}
        id="industryGroup"
        data={Object.values(IndustryGroup)}
      />
      <InputSelect
        isErrored={error.institutionType}
        onChange={onIndustryChange}
        labelText="Industry"
        value={institutionType}
        id="institutionType"
        data={industryDropdownValues}
      />
      {isOtherIndustry && (
        <InputGroup
          isErrored={error.industryOthers}
          onChange={(value: string) => {
            error.industryOthers = "";
            setIndustryOthers(value);
          }}
          labelText="Please Specify Industry"
          id="industryOthers"
          value={industryOthers}
        />
      )}
      <InputSelect
        isErrored={error?.countryOfIncorporation}
        onChange={(value: string) => {
          error.countryOfIncorporation = "";
          setCountryCountryOfIncorp(value);
        }}
        labelText="Country of Incorporation"
        id="country"
        value={countryOfIncorporation}
        data={countries}
      />
      <InputSelect
        isErrored={error?.countryOfDomicile}
        onChange={handleCountryOfDomicileChange}
        labelText="Country of Domicile"
        id="countryOfDomicile"
        value={countryOfDomicile}
        data={countries}
      />
      <InputGroup
        isErrored={error.address}
        onChange={(value: any) => {
          error.address = "";
          setAddress(value);
        }}
        labelText="Address"
        id="address"
        value={address}
      />
      <InputGroup
        isErrored={error.postalcode}
        onChange={(value: any) => {
          error.postalcode = "";
          setPostalCode(value.replace(NO_REGEX, ""));
        }}
        labelText="Postal Code"
        id="postalcode"
        value={postalcode}
      />
      <InputGroup
        isErrored={error.companyWebsite}
        onChange={(value: any) => {
          error.companyWebsite = "";
          setcompanysite(value);
        }}
        labelText="Company Website / Online Marketplace Link"
        id="companyWebsite"
        value={companyWebsite}
      />
      <InputGroup
        isErrored={error.financialTurnover}
        onChange={(value: any) => {
          error.financialTurnover = "";
          setTurnover(value.replace(NO_REGEX, ""));
        }}
        labelText="Previous year closing inventory amount (as per Balance sheet)"
        id="financialTurnover"
        value={financialTurnover}
      />
      <div style={{ marginBottom: "20px" }}>
        <PhoneInputGroup
          isErrored={error.phoneNumber}
          value={phoneNumber}
          country={countryCode}
          onChange={(value: string) => {
            error.phoneNumber = "";
            setPhone(value.replace(NO_REGEX, ""));
          }}
        />
      </div>
      <Button
        onClick={handleSubmit}
        textColor="#FFFF"
        backgroundColor="#338F86"
        children="Next"
        width="100%"
      />
    </div>
  );
};

export default BorrowerBizProfile;
