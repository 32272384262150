import axios from "axios";
import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";

interface ResendOtpRequest {
  email: string;
}

const resendOtp = async ({ email }: ResendOtpRequest) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/trade-directory/users/resend-otp`,
      { email },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );

    return response.data;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);

    throw new Error(message);
  }
};

export default resendOtp;
