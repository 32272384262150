import axios from "axios";
import { toast } from "react-toastify";
import { API_ENDPOINT_PATH, BASE_URL } from "../constants/config";
import { useSelector } from "react-redux";

// Constants

const API_URL = BASE_URL + API_ENDPOINT_PATH;

export async function RequestData(url, method, data = {}, token) {
  let apiUrl = API_URL + url;
  var myHeaders = new Headers();

  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", token);
  myHeaders.append("Access-Control-Allow-Origin", BASE_URL);
  var raw = JSON.stringify(data);

  var requestOptions = {
    method: method,
    headers: myHeaders,
    redirect: "follow",
  };

  if (method != "GET") {
    requestOptions.body = raw;
  }

  return await fetch(apiUrl, requestOptions)
    .then((response) => response.json())
    .then((result) => {
      return result;
    })
    .catch((error) => console.log("error", error));
}

export const FileUpload = async (props) => {
  const userType = localStorage.getItem("userType");
  const { urlPath, method, data, token } = props;

  let config = {
    method: `${method}`,
    url: `${API_URL}${urlPath}`,
    headers: {
      Authorization: token,
      userType: userType,
    },
    data: data,
  };

  let responseData;
  await axios(config)
    .then(function (res) {
      responseData = res;
    })
    .catch(function (error) {
      if (error?.response?.status === 401) {
        toast.error("Unauthorized");
        responseData = error;
      } else {
        responseData = error;
      }
    });

  return responseData;
};

export const ApiHelperFunction = async (props) => {
  let userType = localStorage.getItem("userType");
  const { urlPath, method, data, token } = props;

  let config = {
    method: `${method}`,
    url: `${API_URL}${urlPath}`,
    headers: {
      // "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
      userType: userType,
    },
    data: data,
  };
  let responseData;

  await axios(config)
    .then(function (res) {
      responseData = res;
    })
    .catch(function (error) {
      if (error?.response?.status === 401) {
        toast.error("Unauthorized");
        responseData = error;
      } else {
        responseData = error;
      }
    });

  return responseData;
};

export { API_URL };
