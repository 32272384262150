import React, { useState, useEffect } from "react";
import { useTable, useSortBy } from "react-table";
import toast from "react-hot-toast";
import ErrorBoundary from "@funded-here-interface/common/src/dashboard/components/ErrorBoundry";
import HttpClientXmInv from "../../../../Utils/HttpClientXmInv";
import { useSelector } from "react-redux";
import {
  IconArrowsDownUp,
  IconArrowDown,
  IconArrowUp,
} from "@tabler/icons-react";

const DataTableownInterest = ({ columns, data }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy);

  return (
    <table {...getTableProps()} style={{ borderSpacing: "0", width: "100%" }}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                style={{
                  borderBottom: "1px solid black",
                  background: "#f2f2f2",
                  padding: "8px",
                  cursor: "pointer",
                }}
              >
                {column.render("Header")}
                <span>
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <IconArrowDown size={12} width={24} />
                    ) : (
                      <IconArrowUp size={12} width={24} />
                    )
                  ) : (
                    <IconArrowsDownUp size={12} width={24} />
                  )}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              style={{ borderBottom: "1px solid black", padding: "8px" }}
            >
              {row.cells.map((cell) => (
                <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const App4 = () => {
  const [paymentdata, setPaymentData] = useState([]);
  const token = useSelector((state) => state.auth.token);

  useEffect(() => {
    const fetchPaymentData = async () => {
      try {
        const res = await HttpClientXmInv.requestData(
          `view-note-payment-bySnId-byDate`,
          {},
          token,
          "POST"
        );
        if (res && res.status) {
          const sortedData = res.data.sort((a, b) => {
            return new Date(b._id.paymentDate) - new Date(a._id.paymentDate);
          });

          setPaymentData(sortedData);
        } else {
          toast.error("Failed to fetch amount data");
        }
      } catch (error) {
        console.error("Error fetching payment data: ", error);
        toast.error("Failed to fetch amount data");
      }
    };

    fetchPaymentData();
  }, []);

  const columns = [
    { Header: "Note ID", accessor: (row) => row._id.snId },
    { Header: "Repayment Amount", accessor: "repaidAmount" },
    {
      Header: "Date",
      accessor: (row) => new Date(row._id.paymentDate).toLocaleDateString(),
    },
  ];

  return (
    <ErrorBoundary>
      <DataTableownInterest columns={columns} data={paymentdata} />
    </ErrorBoundary>
  );
};

export default App4;
