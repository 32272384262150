import {
  Anchor,
  Box,
  LoadingOverlay,
  MantineProvider,
  Modal,
  Tabs,
  TabsValue,
  Text,
} from "@mantine/core";
import { FC, useState } from "react";
import mobile from "../../assets/phone_iphone_24px.png";
import cloud from "../../assets/cloud_download_24px.png";
import edit from "../../assets/border_color_24px.png";
import box from "../../assets/check_box_24px_outlined.svg";
import qrcode from "../../assets/luminorAPK.png";
import { useStyle } from "./SetupDID.styles";
import { VQRRegister } from "../../shareRing/VQRRegister";
import {
  CLIENT_ID_LUMINOR_DID,
  PASSPORT_LUMINOR_DID_QUERY_ID,
  QUERY_NEW_OWNER_LUMINOR_DID,
  QUERY_OWNER_LUMINOR_DID,
  REGISTER_LUMINOR_DID_QUERY_ID,
} from "../../constants/domain";
import { useDisclosure } from "@mantine/hooks";

interface IProps {
  onScan: (data: any) => void;
  onInit: (data: any) => void;
  id: string;
  header: string;
}

const SetupDID: FC<IProps> = (props) => {
  const { onScan, onInit, id, header } = props;
  const { classes } = useStyle();
  const [activeTab, setActiveTab] = useState<string | null>("ePassport");
  const [qrLoading, setQrLoading] = useState(true);
  const [opened, { open, close }] = useDisclosure(false);

  const onTabChange = (value: TabsValue) => {
    if (value !== activeTab) {
      setActiveTab(value);
      setQrLoading(true);
    }
  };

  return (
    <div>
      <Text className={classes.headerText}>{header}</Text>
      <div className={classes.subHeading}>
        <Text className={classes.mainText}>
          To verify your identity, choose your passport type:
        </Text>

        <Text className={classes.subText}>
          <span className={classes.highlightText}>ePassport</span> contains an
          electronic chip that stores your personal information securely
        </Text>
        <Text className={classes.subText}>
          <span className={classes.highlightText}>Standard Passport</span> is a
          traditional passport without an electronic chip.
        </Text>
      </div>

      <MantineProvider
        theme={{
          colors: {
            "fh-green": [
              "#A2B6B4",
              "#8DACA9",
              "#78A4A0",
              "#63A09A",
              "#529992",
              "#42938B",
              "#338F86",
              "#367872",
              "#376662",
              "#365855",
            ],
          },
        }}
      >
        <Tabs
          color="fh-green"
          defaultValue="ePassport"
          value={activeTab}
          onTabChange={onTabChange}
          className={classes.tabsOverall}
        >
          <Tabs.List>
            <Tabs.Tab
              disabled={activeTab !== "ePassport" && qrLoading}
              value="ePassport"
            >
              E-PASSPORT
            </Tabs.Tab>
            <Tabs.Tab
              disabled={activeTab !== "standardPassport" && qrLoading}
              value="standardPassport"
            >
              STANDARD PASSPORT
            </Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="ePassport">
            <ul className={classes.unOrdered}>
              <li className={classes.list}>
                <img width={24} src={mobile} alt="" />
                <Text className={classes.instruction}>
                  Keep your e-passport & mobile handy
                </Text>
              </li>

              <li className={classes.list}>
                <img width={24} src={cloud} alt="" />
                <Text className={classes.instruction}>
                  For phones with the App Store or Google Play, scan the QR code
                  below to download the Luminor ID app. For others,{" "}
                  <Anchor<"a">
                    href="#"
                    onClick={open}
                    sx={(theme) => ({
                      color: "#338F86",
                      fontWeight: 500,
                    })}
                  >
                    click here
                  </Anchor>
                </Text>
              </li>
              {activeTab === "ePassport" && (
                <Box pos="relative">
                  <LoadingOverlay
                    visible={qrLoading}
                    className={classes.loader}
                  />
                  <VQRRegister
                    queryOwner={QUERY_OWNER_LUMINOR_DID}
                    className={classes.qrcode}
                    onInit={(value) => {
                      setQrLoading(false);
                      onInit(value);
                    }}
                    onScan={onScan}
                    correlationId={id}
                    queryId={
                      REGISTER_LUMINOR_DID_QUERY_ID +
                      "," +
                      CLIENT_ID_LUMINOR_DID
                    }
                  />
                </Box>
              )}
              {/* {qrLoading && <Loader className={classes.loader} />} */}

              <li className={classes.list}>
                <img width={24} src={edit} alt="" />
                <Text className={classes.instruction}>
                  Select your country, Upload your E-passport details
                </Text>
              </li>
              <li className={classes.list}>
                <img src={box} alt="" />
                <Text className={classes.instruction}>
                  Hit submit- to verify your account
                </Text>
              </li>
            </ul>
          </Tabs.Panel>
          <Tabs.Panel value="standardPassport">
            <Text className={classes.tabHeaderText}>
              Find a licensed notary to notarize a copy of your passport. Once
              done, log in to your account to complete the following steps.
            </Text>
            <ul className={classes.unOrdered}>
              <li className={classes.list}>
                <img width={24} src={mobile} alt="" />
                <Text className={classes.instruction}>
                  Keep the notarised copy, your passport and your mobile handy.
                </Text>
              </li>

              <li className={classes.list}>
                <img width={24} src={cloud} alt="" />
                <Text className={classes.instruction}>
                  For phones with the App Store or Google Play, scan the QR code
                  below to download the Luminor ID app. For others,{" "}
                  <Anchor<"a">
                    href="#"
                    onClick={open}
                    sx={(theme) => ({
                      color: "#338F86",
                      fontWeight: 500,
                    })}
                  >
                    click here
                  </Anchor>
                </Text>
              </li>
              {activeTab === "standardPassport" && (
                <Box pos="relative">
                  <LoadingOverlay
                    visible={qrLoading}
                    className={classes.loader}
                  />
                  <VQRRegister
                    queryOwner={QUERY_NEW_OWNER_LUMINOR_DID}
                    className={classes.qrcode}
                    onInit={(value) => {
                      setQrLoading(false);
                      onInit(value);
                    }}
                    onScan={onScan}
                    correlationId={id}
                    queryId={
                      PASSPORT_LUMINOR_DID_QUERY_ID +
                      "," +
                      CLIENT_ID_LUMINOR_DID
                    }
                  />
                </Box>
              )}

              <li className={classes.list}>
                <img width={24} src={edit} alt="" />
                <Text className={classes.instruction}>
                  Select your country, then scan your passport + scan the
                  notarised copy of your passport
                </Text>
              </li>
              <li className={classes.list}>
                <img src={box} alt="" />
                <Text className={classes.instruction}>
                  Our back office will check and you will get a notification on
                  your Luminor ID app to confirm your account is verified
                </Text>
              </li>
            </ul>
          </Tabs.Panel>
        </Tabs>
      </MantineProvider>

      <Modal
        opened={opened}
        onClose={close}
        title="Scan QR to install Luminor App (Other)"
        size="auto"
      >
        <div className={classes.qrContainer}>
          <div className={classes.qrcodeLuminor}>
            <img className={classes.qrImgTag} src={qrcode}></img>
          </div>

          <div className={classes.instructionContainer}>
            <div className={classes.instructionHeader}>OR</div>

            <ol className={classes.instructionList}>
              <li>Open the browser on your phone.</li>
              <li>
                Enter this address to the URL -
                https://download.shareri.ng/playstore/Luminor_1.0.7(12).apk
              </li>
              <li>
                (Optional) Enable installation from unknown sources in your
                phone's settings.
              </li>
              <li>The APK file will download directly to your phone.</li>
              <li>Open the APK file to install the Luminor ID App</li>
            </ol>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default SetupDID;
