export const setDecimalPlaces = (
  string: string,
  decimalPlaces: number
): string => {
  const formattedNumber = parseFloat(string);
  return formattedNumber.toFixed(decimalPlaces);
};

export const formatPercentageFromRatio = (
  number: string,
  decimalPlaces: number = 2
): string => {
  const mulipliedRatio = parseFloat(number) * 100;
  return mulipliedRatio.toFixed(decimalPlaces);
};

export function camelCaseToNormalCase(camelCase: string): string {
  return camelCase
    .replace(/([a-z])([A-Z])/g, "$1 $2") // Add space between camel case words
    .replace(/^./, (str) => str.toUpperCase()); // Capitalize the first letter
}
