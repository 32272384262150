import { Industry, IndustryGroup, LegalStructure } from "./enums";

export const LegalStructures = [
  LegalStructure.ASSOCIATION_OR_NONPROFIT_ORGANIZATION,
  LegalStructure.COOPERATIVE,
  LegalStructure.CORPORATION_INC_OR_LTD,
  LegalStructure.FOUNDATION,
  LegalStructure.LICENSED_FUND,
  LegalStructure.LIMITED_LIABILITY_COMPANY_LLC,
  LegalStructure.LIMITED_LIABILITY_PARTNERSHIP_LLP,
  LegalStructure.LIMITED_PARTNERSHIP_LP,
  LegalStructure.PRIVATE_LIMITED,
  LegalStructure.REAL_ESTATE_INVESTMENT_TRUST_REIT,
  LegalStructure.SOVEREIGN_WEALTH_FUND,
  LegalStructure.TRUST,
  LegalStructure.OTHERS,
];

export const Funds = [
  LegalStructure.LICENSED_FUND,
  LegalStructure.SOVEREIGN_WEALTH_FUND,
];

export const industry = [Industry.OTHERS];

export const Energy = [
  Industry.ENERGY_EQUIPMENT_AND_SERVICES,
  Industry.OIL_GAS_AND_CONSUMABLE_FUELS,
];

export const Materials = [
  Industry.CHEMICALS,
  Industry.CONSTRUCTION_MATERIALS,
  Industry.CONTAINERS_AND_PACKAGING,
  Industry.METALS_AND_MINING,
  Industry.PAPER_AND_FOREST_PRODUCTS,
];

export const CapitalGoods = [
  Industry.AEROSPACE_AND_DEFENSE,
  Industry.BUILDING_PRODUCTS,
  Industry.CONSTRUCTION_AND_ENGINEERING,
  Industry.ELECTRICAL_EQUIPMENT,
  Industry.INDUSTRIAL_CONGLOMERATES,
  Industry.MACHINERY,
  Industry.TRADING_COMPANIES_AND_DISTRIBUTORS,
];

export const CommercialAndProfessionalServices = [
  Industry.COMMERCIAL_SERVICES_AND_SUPPLIES,
  Industry.PROFESSIONAL_SERVICES,
];

export const Transportation = [
  Industry.AIR_FREIGHT_AND_LOGISTICS,
  Industry.PASSENGER_AIRLINES,
  Industry.MARINE_TRANSPORTATION,
  Industry.GROUND_TRANSPORTATION,
  Industry.TRANSPORTATION_INFRASTRUCTURE,
];

export const AutomobilesAndComponents = [
  Industry.AUTOMOBILE_COMPONENTS,
  Industry.AUTOMOBILES,
];

export const ConsumerDurablesAndApparel = [
  Industry.HOUSEHOLD_DURABLES,
  Industry.LEISURE_PRODUCTS,
  Industry.TEXTILES_APPAREL_AND_LUXURY_GOODS,
];

export const ConsumerServices = [
  Industry.HOTELS_RESTAURANTS_AND_LEISURE,
  Industry.DIVERSIFIED_CONSUMER_SERVICES,
];

export const ConsumerDiscretionaryDistributionAndRetail = [
  Industry.DISTRIBUTORS,
  Industry.BROADLINE_RETAIL,
  Industry.SPECIALTY_RETAIL,
];

export const ConsumerStaplesDistributionAndRetail = [
  Industry.CONSUMER_STAPLES_DISTRIBUTION_AND_RETAIL,
];

export const FoodBeverageAndTobacco = [
  Industry.BEVERAGES,
  Industry.FOOD_PRODUCTS,
  Industry.TOBACCO,
];

export const HouseholdAndPersonalProducts = [
  Industry.HOUSEHOLD_PRODUCTS,
  Industry.PERSONAL_CARE_PRODUCTS,
];

export const HealthCareEquipmentAndServices = [
  Industry.HEALTH_CARE_EQUIPMENT_AND_SUPPLIES,
  Industry.HEALTH_CARE_PROVIDERS_AND_SERVICES,
  Industry.HEALTH_CARE_TECHNOLOGY,
];

export const PharmaceuticalsBiotechnologyAndLifeSciences = [
  Industry.BIOTECHNOLOGY,
  Industry.PHARMACEUTICALS,
  Industry.LIFE_SCIENCES_TOOLS_AND_SERVICES,
];

export const Banks = [Industry.BANKS];

export const FinancialServices = [
  Industry.FINANCIAL_SERVICES,
  Industry.CONSUMER_FINANCE,
  Industry.CAPITAL_MARKETS,
  Industry.MORTGAGE_REAL_ESTATE_INVESTMENT_TRUSTS_REITS,
];

export const Insurance = [
  Industry.INSURANCE,
  Industry.IT_SERVICES,
  Industry.SOFTWARE,
];

export const TechnologyHardwareAndEquipment = [
  Industry.COMMUNICATIONS_EQUIPMENT,
  Industry.TECHNOLOGY_HARDWARE_STORAGE_AND_PERIPHERALS,
  Industry.ELECTRONIC_EQUIPMENT_INSTRUMENTS_AND_COMPONENTS,
];

export const SemiconductorsAndSemiconductorEquipment = [
  Industry.SEMICONDUCTORS_AND_SEMICONDUCTOR_EQUIPMENT,
];

export const TelecommunicationServices = [
  Industry.DIVERSIFIED_TELECOMMUNNICATION_SERVICES,
  Industry.WIRELESS_TELECOMMUNICATION_SERVICES,
];

export const MediaAndEntertainment = [
  Industry.MEDIA,
  Industry.ENTERTAINMENT,
  Industry.INTERACTIVE_MEDIA_AND_SERVICES,
];

export const Utilities = [
  Industry.ELECTRIC_UTILITIES,
  Industry.GAS_UTILITIES,
  Industry.MULTI_UTILITIES,
  Industry.WATER_UTILITIES,
  Industry.INDEPENDENT_POWER_AND_RENEWABLE_ELECTRICITY_PRODUCERS,
];

export const EquityRealEstateInvestmentTrusts = [
  Industry.DIVERSIFIED_REITS,
  Industry.INDUSTRAIL_REITS,
  Industry.HOTEL_AND_RESORT_REITS,
  Industry.OFFICE_REITS,
  Industry.HEALTH_CARE_REITS,
  Industry.RESIDENTIAL_REITS,
  Industry.RETAIL_REITS,
  Industry.SPECIALIZED_REITS,
];

export const RealEstateManagementAndDevelopment = [
  Industry.REAL_ESTATE_MANAGEMENT_AND_DEVELOPMENT,
];

export const IndustryGroupMap: Record<IndustryGroup, Industry[]> = {
  [IndustryGroup.ENERGY]: Energy,
  [IndustryGroup.MATERIALS]: Materials,
  [IndustryGroup.CAPITAL]: CapitalGoods,
  [IndustryGroup.COMMERCIAL]: CommercialAndProfessionalServices,
  [IndustryGroup.TRANSPORTATION]: Transportation,
  [IndustryGroup.AUTOMOBILES]: AutomobilesAndComponents,
  [IndustryGroup.CONSUMER_DURABLES]: ConsumerDurablesAndApparel,
  [IndustryGroup.CONSUMER_SERVICES]: ConsumerServices,
  [IndustryGroup.CONSUMER_DISCRETIONARY]:
    ConsumerDiscretionaryDistributionAndRetail,
  [IndustryGroup.CONSUMER_STAPLES]: ConsumerStaplesDistributionAndRetail,
  [IndustryGroup.FOOD]: FoodBeverageAndTobacco,
  [IndustryGroup.HOUSEHOLD]: HouseholdAndPersonalProducts,
  [IndustryGroup.HEALTHCARE]: HealthCareEquipmentAndServices,
  [IndustryGroup.PHARMACEUTICALS]: PharmaceuticalsBiotechnologyAndLifeSciences,
  [IndustryGroup.BANKS]: Banks,
  [IndustryGroup.FINANCIAL]: FinancialServices,
  [IndustryGroup.INSURANCE]: Insurance,
  [IndustryGroup.TECHNOLOGY]: TechnologyHardwareAndEquipment,
  [IndustryGroup.SEMICONDUCTORS]: SemiconductorsAndSemiconductorEquipment,
  [IndustryGroup.TELECOMMUNICATION]: TelecommunicationServices,
  [IndustryGroup.MEDIA]: MediaAndEntertainment,
  [IndustryGroup.UTILITIES]: Utilities,
  [IndustryGroup.EQUITY_REAL_ESTATE]: EquityRealEstateInvestmentTrusts,
  [IndustryGroup.REAL_ESTATE]: RealEstateManagementAndDevelopment,
};
