import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import axios from "axios";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";

type GetEStatementRequest = {
  token: string;
  limit: string;
  page: string;
  description?: string;
  search?: string;
  sortBy?: string;
  sortOrder?: "ASC" | "DESC";
  skusToNoteIdMap?: Record<string, string>;
};

const getEStatement = async ({
  token,
  page,
  limit,
  description,
  search,
  sortBy,
  sortOrder,
  skusToNoteIdMap,
}: GetEStatementRequest) => {
  try {
    const url = `${BASE_URL}/payment/dashboard/e-statement`;
    const requestBody: Partial<GetEStatementRequest> = { page, limit };

    if (description && description !== "ALL") {
      requestBody.description = description;
    }

    if (search) {
      requestBody.search = search;
    }

    if (sortBy) {
      requestBody.sortBy = sortBy;
    }

    if (sortOrder) {
      requestBody.sortOrder = sortOrder;
    }

    if (skusToNoteIdMap) {
      requestBody.skusToNoteIdMap = skusToNoteIdMap;
    }

    const response = await axios.post(url, requestBody, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    });

    return response.data;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);

    throw new Error(message);
  }
};

export default getEStatement;
