import { Modal } from "@mantine/core";
import React, { FC, useCallback, useEffect, useState } from "react";
import { VQRVerify } from "../../shareRing/VQRVerify";
import { useStyle } from "./SignQRModal.styles";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useAuthenticateDID } from "../../hooks/useAuthenticateDID";
import { toast } from "react-toastify";

interface IProps {
  opened: boolean;
  close: () => void;
  onSignVerify: (isAuthenticated: boolean) => void;
}

const SignQRModal: FC<IProps> = (props) => {
  const { classes } = useStyle();
  const [isLoading, setIsLoading] = useState(false);
  const [scanData, setScanData] = useState<any>(null);
  const id = useSelector((state: RootState) => state.auth.userId);
  const [initialAuthentication, setInitialAuthentication] = useState(true);
  const authenticateDID = useAuthenticateDID({ scanData });

  const { opened, close } = props;

  const onInit = (data: any) => {
    console.log("onInit", data.detail);
  };

  const onScan = useCallback(
    (data: any) => {
      setIsLoading(true);
      const response = data.detail;
      setScanData(response.result);
    },
    [setScanData]
  );

  useEffect(() => {
    if (initialAuthentication) {
      setInitialAuthentication(false);
      return;
    }
    setIsLoading(false);
    const timeoutId = setTimeout(() => {
      if (authenticateDID) {
        setIsLoading(true);
        props.onSignVerify(authenticateDID);
      } else {
        setIsLoading(false);
        toast.error("Verification Failed");
      }
    }, 1000);

    return () => clearTimeout(timeoutId);
  }, [authenticateDID, scanData]);

  return (
    <Modal opened={opened} onClose={close}>
      <div className={classes.header}>Scan QR Code to sign</div>
      <VQRVerify
        className={classes.qrcode}
        onInit={onInit}
        onScan={onScan}
        correlationId={id.toString()}
      />
    </Modal>
  );
};

export default SignQRModal;
