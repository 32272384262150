import { useEffect, useState, useMemo } from "react";
import { useTable } from "react-table";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import {
  IconArrowsDownUp,
  IconArrowDown,
  IconArrowUp,
} from "@tabler/icons-react";
import { useStyles } from "./EStatement.styles";
import useDebounce from "@funded-here-interface/common/src/hooks/useDebounce";
import {
  Box,
  LoadingOverlay,
  Select,
  TextInput,
  Text,
  Group,
} from "@mantine/core";
import ReactPaginate from "react-paginate";
import useGetSkusToNoteIdMap from "../../../../hooks/useGetSkusToNoteIdMap";
import useGetEStatementMutation from "../../../../hooks/useGetEStatement";
import { convertDate } from "@funded-here-interface/common/src/Utils/date";
import { formatNumbersWithThousandSeperators } from "@funded-here-interface/common/src/Utils/formatter";

const DataTableown = ({ columns, data, onSortChange, sortBy, sortOrder }) => {
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data });

  return (
    <table {...getTableProps()} style={{ borderSpacing: "0", width: "100%" }}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => {
              const isSorted = sortBy === column.id;

              return (
                <th
                  {...column.getHeaderProps()}
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (onSortChange) {
                      onSortChange({ id: column.id });
                    }
                  }}
                >
                  {column.render("Header")}
                  <span>
                    {isSorted ? (
                      sortOrder === "DESC" ? (
                        <IconArrowDown height={12} width={24} />
                      ) : (
                        <IconArrowUp height={12} width={24} />
                      )
                    ) : (
                      <IconArrowsDownUp height={12} width={24} />
                    )}
                  </span>
                </th>
              );
            })}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              style={{ borderBottom: "1px solid black", padding: "8px" }}
            >
              {row.cells.map((cell) => (
                <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
              ))}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

const App = () => {
  const { classes } = useStyles();
  const token = useSelector((state) => state.auth.token);
  const noDescriptionFilter = "ALL";
  const descriptionFilter = [
    noDescriptionFilter,
    "Repayment",
    "Investment",
    "Deposit",
  ];
  const [page, setPage] = useState("1");
  const [limit, setLimit] = useState("20");
  const [search, setSearch] = useState("");
  const limits = ["20", "50", "100"];
  const debouncedSearch = useDebounce(search, 1000);
  const [sortBy, setSortBy] = useState("");
  const [sortOrder, setSortOrder] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [description, setDescription] = useState(noDescriptionFilter);
  const [isEStatementLoading, setIsEStatementLoading] = useState(true);
  const [eStatement, setEStatement] = useState([]);
  const {
    data: skusToNoteIdData = {},
    error: getSkusToNoteIdMapError,
    isLoading: getSkusToNoteIdMapLoading,
  } = useGetSkusToNoteIdMap(token);
  const getEStatementMutation = useGetEStatementMutation();
  const skusToNoteIdMap = useMemo(
    () => skusToNoteIdData.skusToNoteIdMap,
    [skusToNoteIdData]
  );

  if (getSkusToNoteIdMapError) {
    toast.error(getSkusToNoteIdMapError?.message);
  }

  const columns = [
    { Header: "Note ID", accessor: "noteId" },
    { Header: "Date", accessor: "createdDate" },
    { Header: "Description", accessor: "description" },
    { Header: "Inflow", accessor: "inflow" },
    { Header: "Outflow", accessor: "outflow" },
  ];

  useEffect(() => {
    setPage("1");
  }, [debouncedSearch]);

  useEffect(() => {
    if (!getSkusToNoteIdMapLoading) {
      setIsEStatementLoading(true);

      getEStatementMutation.mutate(
        {
          token,
          page,
          limit,
          description,
          search: debouncedSearch,
          sortBy,
          sortOrder,
          skusToNoteIdMap: skusToNoteIdMap,
        },
        {
          onSuccess: (data) => {
            const formattedData =
              data?.data?.map((item) => ({
                noteId: item?.noteId || "-",
                createdDate: item?.createdDate
                  ? convertDate(item.createdDate)
                  : "-",
                description: item?.description || "-",
                inflow: item?.inflow
                  ? formatNumbersWithThousandSeperators(item.inflow)
                  : "0",
                outflow: item?.outflow
                  ? formatNumbersWithThousandSeperators(item.outflow)
                  : "0",
              })) ?? [];

            setTotalCount(data?.totalCount);
            setEStatement(formattedData);
            setIsEStatementLoading(false);
          },
          onError: (e) => {
            toast.error(e.message);
            setIsEStatementLoading(false);
          },
        }
      );
    }
  }, [
    skusToNoteIdMap,
    getSkusToNoteIdMapLoading,
    token,
    limit,
    page,
    sortBy,
    sortOrder,
    debouncedSearch,
    description,
  ]);

  const handlePageChange = async (event) => {
    const pageNo = event.selected + 1;
    setPage(`${pageNo}`);
  };

  const handleLimitChange = async (limit) => {
    setPage("1");
    setLimit(limit);
  };

  const handleSearchChange = (e) => {
    const searchedTerm = e.target.value;
    setSearch(searchedTerm);
  };

  const handleSortChange = ({ id }) => {
    if (id === sortBy) {
      if (sortOrder === "ASC") {
        setSortOrder("DESC");
      } else if (sortOrder === "DESC") {
        setSortBy("");
        setSortOrder("");
      }
    } else {
      setSortBy(id);
      setSortOrder("ASC");
    }
  };

  return (
    <Box pos="relative">
      <Group align="end" style={{ marginBottom: "20px" }}>
        <Select
          style={{ width: "130px" }}
          label="Description"
          value={description}
          onChange={(value) => {
            setPage("1");
            setDescription(value);
          }}
          data={descriptionFilter}
        />
        <div className={classes.search}>
          <TextInput
            mt="md"
            label="Search"
            onChange={handleSearchChange}
            placeholder="Search Note ID"
          />
        </div>
      </Group>
      <LoadingOverlay
        visible={getSkusToNoteIdMapLoading || isEStatementLoading}
      />
      <DataTableown
        columns={columns}
        data={eStatement}
        onSortChange={handleSortChange}
        sortBy={sortBy}
        sortOrder={sortOrder}
      />
      {!getSkusToNoteIdMapLoading &&
        !isEStatementLoading &&
        eStatement.length === 0 && (
          <div className={classes.noData}>
            <Text>No data available</Text>
          </div>
        )}
      <div className={classes.buttonContainer}>
        <div className={classes.rowSelection}>
          <Select
            placeholder={limit}
            data={limits}
            onChange={handleLimitChange}
          />
        </div>
        <ReactPaginate
          nextLabel=">"
          onPageChange={handlePageChange}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={Math.ceil(totalCount / parseInt(limit))}
          previousLabel="<"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
          forcePage={parseInt(page) - 1}
        />
      </div>
    </Box>
  );
};

export default App;
