import { Text } from "@mantine/core";
import { FC } from "react";
import { Button } from "@funded-here-interface/common";
import { useNavigate } from "react-router-dom";
import downArrow from "../../assets/keyboard_arrow_down_24px.svg";
import downloadbutton from "../../assets/download_24px.svg";
import Line from "@funded-here-interface/common/src/assets/LineHorizontal.png";
import useSetCreditLimit from "../../hooks/useSetCreditLimit";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useStyle } from "./LoanAgreement.styles";
import OnboardingLayout from "../../layouts/OnboardingLayout/OnboardingLayout";

const LoanAgreement: FC = () => {
  const { classes } = useStyle();
  const Navigate = useNavigate();
  const mutation = useSetCreditLimit();
  const token = useSelector((state: RootState) => state.auth.token);
  const turnover = useSelector((state: RootState) => state.borBiz.turnover);

  const handleSubmit = () => {
    if (turnover && token) {
      mutation.mutate(
        { limit: turnover, token },
        {
          onSuccess: (data) => {
            Navigate("/loanApplication");
          },
          onError: (error) => {
            // Handle the error
          },
        }
      );
    }
  };

  return (
    <OnboardingLayout
      step={0}
      content={
        <div className={classes.stepContent} style={{ marginTop: "0px" }}>
          <Text className={classes.headerText} style={{ marginTop: "10px" }}>
            Consent Forms{" "}
          </Text>
          <Text className={classes.subHeading} style={{ marginTop: "10px" }}>
            Payment{" "}
          </Text>
          <Text
            className={classes.instruction}
            style={{ marginTop: "10px", marginBottom: "75px" }}
          >
            We'll create a Payment Service Bank Account with our partner PSC
            (Nium) for your incoming sales proceeds. Your consent is needed for
            FundedHere's API integration with this account to transfer funds for
            SKU Loan repayment. Our tech team will also set up APIs for virtual
            accounts.
          </Text>{" "}
          {/* <Text className={classes.subHeading}>
                        STEPS
                  */}
          <div className={classes.download}>
            <Text className={classes.doc}>
              Consent Form for Data Sharing with Nium
            </Text>

            <img src={downArrow} alt="down" />
            <img src={downloadbutton} alt="down" />
          </div>
          <div className={classes.download}>
            <Text className={classes.doc}>
              Consent for Payment Account API Integration{" "}
            </Text>

            <img src={downArrow} alt="down" />
            <img src={downloadbutton} alt="down" />
          </div>
          <div className={classes.checkbox}>
            <input type="checkbox" className={classes.cbox} />
            <Text className={classes.ctext}>
              I have read & understood the above.{" "}
            </Text>
          </div>
          <div className={classes.horizontalLine}>
            <img src={Line} />
          </div>
          <Text className={classes.subHeading} style={{ marginTop: "10px" }}>
            WMS Integration{" "}
          </Text>
          <Text
            className={classes.instruction}
            style={{ marginTop: "10px", marginBottom: "40px" }}
          >
            Please provide your consent for stock integration and checking via
            our WMS. Your agreement allows us to efficiently manage your
            inventory. To proceed, please sign below.
          </Text>{" "}
          {/* <Text className={classes.subHeading}>
                        STEPS
                  */}
          <div className={classes.download}>
            <Text className={classes.doc}>
              Permission to access WMS records
            </Text>

            <img src={downArrow} alt="down" />
            <img src={downloadbutton} alt="down" />
          </div>
          <div className={classes.checkbox}>
            <input type="checkbox" className={classes.cbox} />
            <Text className={classes.ctext}>
              I have read & understood the above.{" "}
            </Text>
          </div>
          <div className={classes.buttonDiv}>
            <div className={classes.buttonWrapper}>
              <Button
                onClick={() => handleSubmit()}
                backgroundColor="#ffff"
                textColor="#338F86"
                children="Back"
                border="1px solid #338F86"
                width="100%"
              />
            </div>
            <div className={classes.buttonWrapper}>
              <Button
                onClick={() => handleSubmit()}
                textColor="#ffff"
                backgroundColor="#338F86"
                children="Next"
                width="100%"
              />
            </div>
          </div>
        </div>
      }
    />
  );
};

export default LoanAgreement;
