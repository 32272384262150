import { NavLink } from "react-router-dom";

const HeaderInvestorDashboard = () => {
  return (
    <header>
      <section className={"headerPart"}>
        <div className="container-fluid">
          <div className="headerInn">
            <ul className="headerUl">
              <li>
                <NavLink to="/investor-dashboard/investordashboard">
                  Overall View
                </NavLink>
              </li>
              <li>
                <NavLink to="/investor-dashboard/investanalysis">
                  Investment Analysis
                </NavLink>
              </li>
              <li>
                <NavLink to="/investor-dashboard/snsummaryview">
                  SN Summary View
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="btmheader">
        <div className="container-fluid">
          <p className="headerPara">*All amounts are in SGD</p>
        </div>
      </section>
    </header>
  );
};

export default HeaderInvestorDashboard;
