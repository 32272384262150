import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";
import axios from "axios";

interface GetVaBalanceRequest {
  token: string;
}

const getVaBalance = async ({ token }: GetVaBalanceRequest) => {
  try {
    const response = await axios.get(`${BASE_URL}/payment/account/balance`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    });

    const result = {
      qc: response.data.qc,
      sf: response.data.sf,
      asf: response.data.asf,
    };

    return result;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);

    throw new Error(message);
  }
};

export default getVaBalance;
