import { createStyles } from "@mantine/core";

export const useStyles = createStyles(() => ({
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: "20px",
    marginBottom: "20px",
  },

  rowSelection: {
    marginRight: "10px",
    width: "80px",
  },

  search: {
    maxWidth: "150px",
  },

  noData: {
    marginTop: "10px",
    textAlign: "center",
    width: "100%",
  },
}));
