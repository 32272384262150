import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

const Header = () => {
  const [isSticky, setSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 50) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <section className={`headerPart ${isSticky ? "sticky" : ""}`}>
        <div
          className="container-fluid"
          style={isSticky ? { marginLeft: "130px" } : {}}
        >
          <div className="headerInn">
            <ul className="headerUl">
              <li>
                <NavLink to="/merchant-dashboard/Overall">Overall View</NavLink>
              </li>
              <li>
                <NavLink to="/merchant-dashboard/Repayment">
                  Repayment Tracking
                </NavLink>
              </li>
            </ul>
            <div
              className="headmenuBtn"
              onClick={() => {
                document.querySelector(".sidebarPart").classList.toggle("show");
              }}
            >
              <i class="fa-solid fa-bars"></i>
            </div>

            {/* <div 
              onClick={handleLogOut}
            >
              <buttton
                className="btn1"
                style={{
                  color: "red",
                  backgroundColor: "rgb(240, 225, 153)",
                  padding: "5px",
                  boxShadow: "3px 4px 8px 0px rgba(0, 0, 0, 0.5)", 
                  borderRadius:"5px",
                  cursor:"pointer"
                }}
              >
                <i className="fa-solid fa-right-from-bracket mr-1"></i> Log Out
              </buttton>
            </div> */}
          </div>
        </div>
      </section>

      <section className="btmheader">
        <div className="container-fluid">
          <p className="headerPara">*All amounts are in SGD</p>
        </div>
      </section>
    </>
  );
};

export default Header;
