import {
  Button,
  InputGroup,
  InputSelect,
  SWIFT_CODE_REGEX,
} from "@funded-here-interface/common";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStyle } from "../../styles/BankDetailsCss";
import { useDispatch } from "react-redux";
import {
  setBankNameRedux,
  setAccountHolderNameRedux,
  setBankNoRedux,
  setSwiftCodeRedux,
  setAccountTypeRedux,
} from "../../features/distributor/bank";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { RootState } from "../../store";
import { useSelector } from "react-redux";
import { NO_REGEX } from "@funded-here-interface/common";
import { bankAccountTypes } from "../../constants/bank";
import useCreateOrg from "../../hooks/useCreateOrg";
import { Persona } from "@funded-here-interface/common/src/constant/enum";
import { getCode } from "country-list";
import { Industry, LegalStructure } from "../../constants/enums";
import BankAccNameDisclaimer from "../../components/BankAccNameDisclaimer/BankAccNameDisclaimer";
import { countryCodes } from "@funded-here-interface/common/src/constant/country";

const DistributorBank: FC = () => {
  const { classes } = useStyle();
  const Navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const {
    bankName: bankNameRedux,
    accountNumber: accountNoRedux,
    accountType: accountTypeRedux,
    accountHolderName: accountHolderNameRedux,
    swiftCode: swiftCodeRedux,
  } = useSelector((state: RootState) => state.disBank);

  const {
    company,
    regNumber,
    legalStructure,
    legalStructureOthers,
    industryGroup,
    typeOfInstitute,
    industryOthers,
    country,
    address,
    postal,
    userFirstName,
    userLastName,
  } = useSelector((state: RootState) => state.disBiz);

  const token = useSelector((state: RootState) => state.auth.token);
  const [bankName, setBankName] = useState(bankNameRedux || "");
  const [accountNumber, setAccountNumber] = useState(accountNoRedux || "");
  const [accountType, setAccountType] = useState(accountTypeRedux || "");
  const [swiftCode, setSwiftCode] = useState(swiftCodeRedux || "");
  const [accountHolderName, setAccountHolderName] = useState(
    accountHolderNameRedux || ""
  );

  const mutation = useCreateOrg();

  const [error, setError] = useState({
    bankName: "",
    accountHolderName: "",
    bankAddress: "",
    swiftCode: "",
    accountNumber: "",
    accountType: "",
  });

  const dispatch = useDispatch();

  const handleBack = () => {
    dispatch(setBankNameRedux({ bankName }));
    dispatch(setBankNoRedux({ accountNumber }));
    dispatch(setAccountHolderNameRedux({ accountHolderName }));
    dispatch(setAccountTypeRedux({ accountType }));
    dispatch(setSwiftCodeRedux({ swiftCode }));

    Navigate("/issuing-spv-biz-profile");
  };

  const handleNumericInputChange = (actualOnChange: any) => (value: any) => {
    const numericValue = value.replace(NO_REGEX, "");
    actualOnChange(numericValue);
  };

  const handleNext = async () => {
    const customError = { ...error };
    let valid = true;

    if (!bankName) {
      customError.bankName = "Bank Name is required";
      valid = false;
    }
    if (!accountNumber) {
      customError.accountNumber = "Account Number is required";
      valid = false;
    }
    if (!accountType) {
      customError.accountType = "Account Type is required";
      valid = false;
    }
    if (!swiftCode) {
      customError.swiftCode = "Swift Code is required";
      valid = false;
    }
    if (!accountHolderName) {
      customError.accountHolderName = "Account Holder Name is required";
      valid = false;
    }

    if (company) {
      if (accountHolderName !== company) {
        valid = false;
        toast.warn("Account Holder Name should be the same as company name");
      }
    }

    if (valid) {
      const bankNameResponse = dispatch(setBankNameRedux({ bankName }));
      const bankNumberResponse = dispatch(setBankNoRedux({ accountNumber }));
      const bankHolderNameResponse = dispatch(
        setAccountHolderNameRedux({ accountHolderName })
      );
      const bankAccTypeResponse = dispatch(
        setAccountTypeRedux({ accountType })
      );
      const bankSwiftCodeResponse = dispatch(setSwiftCodeRedux({ swiftCode }));

      const bank = {
        bankName: bankNameResponse.payload.bankName,
        swiftCode: bankSwiftCodeResponse.payload.swiftCode,
        accountNo: bankNumberResponse.payload.accountNumber,
        accountType: bankAccTypeResponse.payload.accountType.toLowerCase(),
        acountHolderName: bankHolderNameResponse.payload.accountHolderName,
      };

      let countryCode = null;

      if (country && country in countryCodes) {
        countryCode = countryCodes[country as keyof typeof countryCodes];
      } else {
        getCode(country);
      }

      setIsLoading(true);
      mutation.mutate(
        {
          bank,
          token,
          onBoardingUserType: Persona.DISTRIBUTOR,
          companyName: company as string,
          companyRegistrationNo: regNumber as string,
          countryISOCode: countryCode,
          countryOfIncorporation: country as string,
          firstName: userFirstName as string,
          lastName: userLastName as string,
          industryGroup: industryGroup as string,
          industry:
            typeOfInstitute === Industry.OTHERS
              ? (industryOthers as string)
              : (typeOfInstitute as string),
          legalEntityAddress: address as string,
          legalStructure:
            legalStructure === LegalStructure.OTHERS
              ? (legalStructureOthers as string)
              : (legalStructure as string),
          postalCode: parseInt(`${postal}`),
        },
        {
          onSuccess: () => {
            setIsLoading(false);
            Navigate("/thanks-page");
          },
          onError: (e) => {
            setIsLoading(false);
            toast.error((e as Error).message);
          },
        }
      );
    } else {
      setError(customError);
    }
  };

  return (
    <div style={{ marginTop: "70px" }}>
      <InputGroup
        isErrored={error.bankName}
        onChange={(value: any) => {
          error.bankName = "";
          setBankName(value);
        }}
        labelText="Bank Name"
        id="bank_name"
        value={bankName}
      />
      <InputGroup
        isErrored={error.accountNumber}
        onChange={handleNumericInputChange((value: any) => {
          error.accountNumber = "";
          setAccountNumber(value.replace(NO_REGEX, ""));
        })}
        labelText="Bank Account Number"
        id="account_number"
        value={accountNumber}
      />
      <InputSelect
        isErrored={error.accountType}
        onChange={(value: any) => {
          error.accountType = "";
          setAccountType(value);
        }}
        labelText="Account type"
        id="accountType"
        value={accountType}
        data={bankAccountTypes}
      />
      <InputGroup
        isErrored={error.swiftCode}
        onChange={(value: any) => {
          error.swiftCode = "";
          setSwiftCode(value.replace(SWIFT_CODE_REGEX, ""));
        }}
        labelText="Bank Swift Code"
        value={swiftCode}
        id="swift_code"
      />
      <BankAccNameDisclaimer />
      <InputGroup
        isErrored={error.accountHolderName}
        onChange={(value: any) => {
          error.accountHolderName = "";
          setAccountHolderName(value);
        }}
        labelText="Account Holder Name (should match with institution name)"
        value={accountHolderName}
        id="account_holder_name"
      />
      <div className={classes.buttonDiv}>
        <div className={classes.buttonWrapper}>
          <Button
            onClick={handleBack}
            backgroundColor="#ffff"
            textColor="#338F86"
            children="Back"
            border="1px solid #338F86"
            width="100%"
          />
        </div>

        <ToastContainer />
        <div className={classes.buttonWrapper}>
          <Button
            disabled={isLoading}
            onClick={handleNext}
            textColor="#ffff"
            backgroundColor="#338F86"
            children="Next"
            width="100%"
          />
        </div>
      </div>
    </div>
  );
};

export default DistributorBank;
