import React from "react";
import { useStyle } from "../../Screens/LTVacceptance-Confirmed/LoanAcceptance.styles";
import { formatPercentageFromRatio } from "../../Utils/formatter";
import { formatNumbersWithThousandSeperators } from "@funded-here-interface/common/src/Utils/formatter";

interface TableRow {
  skuId: string;
  description: string;
  loanAmount2: string;
  interestRate: string;
  tenure: number;
  adminFee: string;
  transAndChannelFee: string;
  thirdPartyFee: string;
  loanApplicationCurrency: string;
}

interface MyTableProps {
  data: TableRow[];
  selectedSKUId: string[] | null;
  onCheckboxChange: (skuId: string) => void;
}

const MyTable: React.FC<MyTableProps> = ({ data }) => {
  const { classes } = useStyle();

  return (
    <table style={{ borderCollapse: "collapse", border: "none" }}>
      <thead>
        <tr>
          <th className={classes.thh}>SKU ID</th>
          <th className={classes.thh}>Description</th>
          <th className={classes.thh}>Loan Amount</th>
          <th className={classes.thh}>Indicative Interest Rate p.m </th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr key={index}>
            <td className={classes.th}>{row.skuId}</td>
            <td className={classes.th}>{row.description}</td>
            <td className={classes.th}>
              {" "}
              {row.loanApplicationCurrency +
                " " +
                formatNumbersWithThousandSeperators(
                  parseFloat(row.loanAmount2)
                )}
            </td>
            <td className={classes.th}>
              {" "}
              {formatPercentageFromRatio(row.interestRate) + "%"}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default MyTable;
