import { Button, color } from "@funded-here-interface/common";
import { Modal, Tabs, Text } from "@mantine/core";
import { FC, useState, useRef, useEffect, useMemo } from "react";
import { useStyle } from "./SkuLoanApplicationNew.style";
import axios from "axios";
import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DynamicTable, {
  DynamicTableSettings,
} from "@funded-here-interface/common/src/components/DynamicTable/DynamicTable";
import { useDispatch } from "react-redux";
import { setRegisterBulk } from "../../../features/sku/bulkSlice";
import downloadSvg from "../../../assets/download_24px.svg";

import uploadicon from "../../../assets/upload_24px_outlined (1).svg";
import useGetPendingSku from "@funded-here-interface/common/src/hooks/useGetPendingSku";
import { GetPendingSkuResponse } from "@funded-here-interface/common/src/services/getPendingSku";
import {
  MRT_ColumnDef,
  MantineReactTable,
  useMantineReactTable,
} from "mantine-react-table";
import UploadPo from "@funded-here-interface/common/src/components/UploadPo/UploadPo";
import useOcrUpload from "@funded-here-interface/common/src/hooks/useOcrUpload";
import { OcrType } from "@funded-here-interface/common/src/constant/enum";

interface ResponseType {
  deliveryDate: string;
  description: string;
  discount: string;
  errors: any[]; // If the structure of errors is known, replace 'any' with a more specific type
  expectedPaymentDate: string;
  nettInvoiceAmount: string;
  otherCosts: string;
  partialPaymentFirstPaymentPercentage: string;
  paymentTerm: string;
  poDate: string;
  poNumber: string;
  purchasePricePerUnit: string;
  quantityPurchased: string;
  sellingPricePerUnit: string;
  skuId: string;
  skuReturnMoreThanLimitReason: string;
  skuReturnPercentage: string;
  status: string;
  tax: string;
}

type LoanNewSummaryProps = {
  onEvent: (eventName: string) => void;
};

const New: FC<LoanNewSummaryProps> = ({ onEvent }) => {
  const { classes } = useStyle();
  const dispatch = useDispatch();
  const [popupScreen, setPopupScreen] = useState(false);
  const [isOpenSkuErrorsModal, setIsOpenSkuErrorsModal] = useState(false);
  const [selectedPoToUpload, setSelectedPoToUpload] = useState(null);
  const [isOpenUploadPoModal, setIsOpenUploadPoModal] = useState(false);
  const [displayCsvContents, setDisplayCsvContents] = useState(false);
  const [selectedPOFile, setselectedPOFile] = useState<File | null>(null);
  const [isPoUploaded, setIsPoUploaded] = useState(false);
  const [allSkusHaveNoErrors, setAllSkusHaveNoErrors] = useState(true);
  const [uploadRes, setuploadRes] = useState();
  const [isFileSelected, setisFileSelected] = useState(false);
  const { token, orgRoleId } = useSelector((state: RootState) => state.auth);
  const skusexists = useSelector((state: RootState) => state.bulk.skus);
  const [selectedSkusToUpload, setSelectedSkusToUpload] = useState<
    ResponseType[]
  >([]);
  const [skuErrorModalErrors, setSkuErrorModalErrors] = useState<string[]>([]);
  const hiddenPoFileInput = useRef<HTMLInputElement>(null);
  const hiddenCsvFileInput = useRef<HTMLInputElement>(null);
  const { data, error, isLoading } = useGetPendingSku(
    token,
    orgRoleId ?? undefined
  );
  const [isPoOcrUploadLoading, setIsPoOcrUploadLoading] = useState(false);
  const useOcrUploadMutation = useOcrUpload();

  enum NewLoanTab {
    PO = "po",
    CSV = "csv",
  }

  useEffect(() => {
    if (uploadRes) {
      setDisplayCsvContents(true);
    }
  }, [uploadRes]);

  if (error) {
    toast.error((error as Error).message);
  }

  const columns = useMemo<MRT_ColumnDef<GetPendingSkuResponse>[]>(
    () => [
      {
        accessorKey: "upc",
        id: "upc",
        header: "UPC",
      },
      {
        accessorKey: "description",
        id: "description",
        header: "Description",
      },
    ],
    []
  );

  const table = useMantineReactTable({
    enableColumnActions: false,
    enableColumnFilters: false,
    paginationDisplayMode: "pages",
    state: { isLoading },
    columns,
    data: data ?? [],
    initialState: { density: "xs" },
    mantineTableProps: {
      withBorder: true,
    },
  });

  // Handler for file selection
  const handleFileSelect = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.files) {
      try {
        if (event.target.files[0]) {
          const formData = new FormData();
          formData.append("file", event.target.files[0]);

          const res = await axios.post(
            `${BASE_URL}/sku-finance/loan-application/bulk-submit-sku-loan-application-csv`,
            {
              file: event.target.files[0],
            },
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${token}`,
              },
            }
          );

          setAllSkusHaveNoErrors(true);

          setisFileSelected(true);
          setuploadRes(res.data);

          //  Reset all data if the user uploads another CSV
          setSelectedSkusToUpload([]);
          setselectedPOFile(null);
          setIsPoUploaded(false);
        }
      } catch (err) {
        toast.error("Unable to upload CSV. Please try again later.");
      } finally {
        // Clear the value of the file input to ensure onChange is triggered if the same file is uploaded consecutively
        event.target.value = "";
      }
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length > 0
    ) {
      const file = event.target.files[0];
      if (file) {
        setselectedPOFile(file);
        setIsPoUploaded(true);
      }
    }
  };

  const dynamicTableSetting: DynamicTableSettings = {
    data: [],
    column: [
      {
        heading: "UPC",
        dataKey: "upc",
      },
      {
        heading: "Ref Id",
        dataKey: "skuId",
      },
      {
        heading: "Description",
        dataKey: "description",
      },
      {
        heading: "Status",
        dataKey: "status",
      },
      {
        heading: "PO Number",
        dataKey: "poNumber",
      },
      {
        heading: "PO Date (YYYY-MM-DD)",
        dataKey: "poDate",
      },
      {
        heading: "Delivery Date (YYYY-MM-DD)",
        dataKey: "deliveryDate",
      },
      {
        heading: "Expected Payment Date (YYYY-MM-DD)",
        dataKey: "expectedPaymentDate",
      },
      {
        heading: "Purchase Price Per Unit",
        dataKey: "purchasePricePerUnit",
      },
      {
        heading: "Selling Price Per Unit",
        dataKey: "sellingPricePerUnit",
      },
      {
        heading: "Quantity Order",
        dataKey: "quantityPurchased",
      },
      {
        heading: "Discount (If Any)",
        dataKey: "discount",
      },
      {
        heading: "Other Costs",
        dataKey: "otherCosts",
      },
      {
        heading: "Tax",
        dataKey: "tax",
      },
      {
        heading: "Total Invoice",
        dataKey: "nettInvoiceAmount",
      },
      {
        heading:
          "Payment Terms (cash-on-delivery / partial-payment / payment-on-purchase-order)",
        dataKey: "paymentTerm",
      },
      {
        heading:
          "Partial Payment First Payment % (Only needed if Payment Terms is partial-payment)",
        dataKey: "partialPaymentFirstPaymentPercentage",
      },
      {
        heading: "Sku Return Percentage",
        dataKey: "skuReturnPercentage",
      },
      {
        heading: "Sku Return Reason (Only Needed if Sku Return > 3%)",
        dataKey: "skuReturnMoreThanLimitReason",
      },
      {
        heading: "errors",
        dataKey: "errors",
        clickable: true,
        isButtonColumn: true,
        buttonText: "View",
      },
    ],
    hasCheckbox: true,
  };

  const receivedCheckboxChangedMessage = (receivedData: any) => {
    const skuid = receivedData.skuId;
    const poNumberSelected = receivedData.poNumber;

    setSelectedSkusToUpload((prevSkus) => {
      // Check if the skuid is already in the array
      const index = selectedSkusToUpload.findIndex(
        (sku) => sku["skuId"] === skuid
      );

      if (index === -1) {
        // If it's not in the array, add it
        if (!selectedPoToUpload) {
          setSelectedPoToUpload(poNumberSelected);
        } else {
          setSelectedPoToUpload(poNumberSelected);
        }
        return [...prevSkus, receivedData];
      } else {
        // If it's already in the array, remove it
        const newSkus = [...selectedSkusToUpload];
        newSkus.splice(index, 1);

        if (newSkus.length === 0) {
          setSelectedPoToUpload(null);
        }

        return newSkus;
      }
    });
  };

  const receivedAllCheckboxChangedMessage = (isSelectAll: any, data: any) => {
    if (isSelectAll === true) {
      setSelectedSkusToUpload(data);
      setSelectedPoToUpload(data[0].poNumber);
    } else {
      setSelectedSkusToUpload([]);
      setSelectedPoToUpload(null);
    }
  };

  const displaySkuErrors = (receivedData: any) => {
    setSkuErrorModalErrors(receivedData);
    setIsOpenSkuErrorsModal(true);
  };

  const handleCloseModal = () => {
    setIsOpenSkuErrorsModal(false);
  };

  const handleCloseUploadPoModal = () => {
    setIsOpenUploadPoModal(false);
  };

  const handleUploadCsv = async () => {
    hiddenCsvFileInput.current?.click();
  };
  const downloadCSV = (csvData: any) => {
    const blob = new Blob([csvData], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "loan_applications_data.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleDownloadCsv = async () => {
    const res = await axios.get(
      `${BASE_URL}/sku-finance/loan-application/download-invited-sku-loan-applications-csv`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    downloadCSV(res.data);
  };

  const handleUploadPo = () => {
    hiddenPoFileInput.current?.click();
  };

  const handleSubmit = async () => {
    try {
      const res = await axios.post(
        `${BASE_URL}/sku-finance/loan-application/bulk-submit-sku-loan-application`,
        {
          file: selectedPOFile,
          data: selectedSkusToUpload,
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (res.status === 200) {
        setPopupScreen(false);
        setIsOpenUploadPoModal(false);
        toast.success("Submitted successfully");

        //  Reset the displayed CSV data
        const currentCsvData = uploadRes as unknown as any;

        for (const submittedSku of selectedSkusToUpload) {
          for (const invoiceNumber in currentCsvData) {
            let invoiceSkus = currentCsvData[invoiceNumber];

            invoiceSkus = invoiceSkus.filter(
              (sku: { skuId: string }) => sku.skuId !== submittedSku.skuId
            );

            if (invoiceSkus.length > 0) {
              currentCsvData[invoiceNumber] = invoiceSkus;
            } else {
              delete currentCsvData[invoiceNumber];
            }
          }
        }

        setuploadRes(currentCsvData);
        setSelectedSkusToUpload([]);
        setSelectedPoToUpload(null);
        setselectedPOFile(null);
        setIsPoUploaded(false);
      }
    } catch (error) {
      setPopupScreen(false);
      setIsOpenUploadPoModal(false);

      toast.error("Error submitting the loan application");
    }
  };

  const enableSelectAllCheckbox = (skus: any) => {
    let enableSelectAllCheckbox = true;

    for (const sku of skus) {
      if (sku.errors.length > 0) {
        enableSelectAllCheckbox = false;
        break;
      }
    }

    return enableSelectAllCheckbox;
  };

  const disableAllCheckBoxes = (poNumberSelected: string) => {
    if (!selectedPoToUpload) {
      return false;
    }

    if (selectedPoToUpload === poNumberSelected) {
      return false;
    }

    return true;
  };

  const handleSKUSubmission = () => {
    if (selectedSkusToUpload.length > 0) {
      setIsOpenUploadPoModal(true);
      dispatch(
        setRegisterBulk({ skus: [...skusexists, ...selectedSkusToUpload] })
      );
    } else {
      toast.warn("No Sku's are selected");
    }
  };

  const handleOnPoOcrUpload = (file: File) => {
    setIsPoOcrUploadLoading(true);

    useOcrUploadMutation.mutate(
      { token, type: OcrType.PO, file, orgRoleId: orgRoleId! },
      {
        onSuccess: () => {
          toast.success("PO uploaded successfully");
          setIsPoOcrUploadLoading(false);
        },
        onError: (e) => {
          toast.error((e as Error).message);
          setIsPoOcrUploadLoading(false);
        },
      }
    );
  };

  return (
    <div className={classes.container}>
      <Text className={classes.para}>
        Apply a loan for the following UPC, steps are given below.
        <br /> Please be advised that these applications remain valid for a
        month.
      </Text>

      <div className={classes.table}>
        <MantineReactTable table={table} />
      </div>

      <Tabs
        styles={{
          tabsList: {
            borderBottom: "none",
          },
        }}
        defaultValue={NewLoanTab.PO}
      >
        <Tabs.List>
          <Tabs.Tab value={NewLoanTab.PO}>Upload Via Purchase Order</Tabs.Tab>
          <Tabs.Tab value={NewLoanTab.CSV}>Upload Via CSV</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value={NewLoanTab.PO} pt="xs">
          <UploadPo
            orgRoleId={orgRoleId ?? undefined}
            handleOnUpload={handleOnPoOcrUpload}
            isLoading={isPoOcrUploadLoading}
          />
        </Tabs.Panel>

        <Tabs.Panel value={NewLoanTab.CSV} pt="xs">
          {popupScreen && (
            <div className={classes.thankScreen}>
              <div className={classes.thankContainer}>
                <Text className={classes.heading}>Thank you!</Text>
                <Text className={classes.para}>
                  Upon receipt of your sinking fund payment, your loan request
                  will be placed in a queue to secure funding.
                </Text>

                <Text className={classes.para}>
                  Once the funding is successfully secured, an email
                  notification will be sent to you.
                </Text>
              </div>
            </div>
          )}

          <div className={classes.menyDiv}>
            <div
              className={classes.downloadDiv}
              onClick={() => handleDownloadCsv()}
            >
              <div className={classes.num}>1.</div>
              <div>
                <img src={downloadSvg} alt="" />
              </div>

              <div className={classes.downloadTest}>Download CSV Template</div>
            </div>
            <div className={classes.downloadDiv}>
              {" "}
              <div className={classes.num}>2.</div>
              <div className={classes.text}>
                Fill in the required Fields
              </div>{" "}
            </div>
            <div className={classes.downloadDiv} onClick={handleUploadCsv}>
              <input
                type="file"
                accept=".csv"
                ref={hiddenCsvFileInput}
                onChange={handleFileSelect}
                style={{ display: "none" }} // Make the file input invisible
              />
              <div className={classes.num}>3.</div>

              <div>
                <img src={uploadicon} alt="" />
              </div>

              <div className={classes.downloadTest}>Upload CSV</div>
            </div>
            <div className={classes.downloadDiv}>
              <div className={classes.num}>4.</div>
              <div className={classes.text}>
                Upload PO for the SKU batch
              </div>{" "}
            </div>
          </div>

          {displayCsvContents === true && isFileSelected === true && (
            <div>
              {Object.keys(uploadRes as unknown as any).map((key, index) => (
                <div key={index}>
                  <br />
                  <br />
                  <span>PO Number: {key}</span>
                  {uploadRes && (
                    <div style={{ overflow: "scroll" }}>
                      <DynamicTable
                        data={uploadRes[key]}
                        column={dynamicTableSetting.column}
                        hasCheckbox={dynamicTableSetting.hasCheckbox}
                        emitCheckBoxChangedMessage={
                          receivedCheckboxChangedMessage
                        }
                        emitAllCheckBoxChangedMessage={
                          receivedAllCheckboxChangedMessage
                        }
                        emitDatabaseViewButtonClickedMessage={displaySkuErrors}
                        enableHeaderCheckbox={enableSelectAllCheckbox(
                          uploadRes[key]
                        )}
                        disableAllCheckboxes={disableAllCheckBoxes(key)}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          )}

          {displayCsvContents === true && isFileSelected === true && (
            <div className={classes.buttonWrapper}>
              <Button
                onClick={handleSKUSubmission}
                backgroundColor={color.FHGREEN}
                textColor={color.WHITE}
                children="Submit"
                border={`1px solid ${color.FHGREEN}`}
                width="100%"
              />
            </div>
          )}
          <Modal
            opened={isOpenSkuErrorsModal}
            onClose={handleCloseModal}
            title="Sku Errors"
            size="55%"
          >
            {skuErrorModalErrors.length > 0 ? (
              <div style={{ padding: "35px" }}>
                <ol>
                  {skuErrorModalErrors.map((error, index) => (
                    <li key={index} style={{ color: "#ff0000" }}>
                      {error}
                    </li>
                  ))}
                </ol>
              </div>
            ) : (
              <p>There are no errors.</p>
            )}
          </Modal>

          <Modal
            opened={isOpenUploadPoModal}
            onClose={handleCloseUploadPoModal}
            title="Upload PO"
            size="45%"
          >
            Please upload a PO in pdf format that contains the selected loan
            application details for our verification
            <div className={classes.buttonWrapper}>
              {isPoUploaded === false ? (
                <>
                  <Button
                    onClick={() => handleUploadPo()}
                    backgroundColor={color.FHGREEN}
                    textColor={color.WHITE}
                    children="Upload PO"
                    border={`1px solid ${color.FHGREEN}`}
                    width="150%"
                  />

                  <input
                    type="file"
                    accept="application/pdf"
                    ref={hiddenPoFileInput}
                    onChange={handleFileChange}
                    style={{ display: "none" }} // Hide the file input
                  />
                </>
              ) : (
                <Button
                  onClick={() => handleSubmit()}
                  backgroundColor={color.FHGREEN}
                  textColor={color.WHITE}
                  children="Submit"
                  border={`1px solid ${color.FHGREEN}`}
                  width="150%"
                />
              )}
            </div>
          </Modal>
        </Tabs.Panel>
      </Tabs>
    </div>
  );
};

export default New;
