import { createSlice } from "@reduxjs/toolkit";

const bizSlice = createSlice({
  name: "prof",
  initialState: {
    phoneNumber: null,
    industryGroup: null,
    industryDropdownValues: null,
    industry: null,
    industryOthers: null,
    occupation: null,
    experience: null,
    nationality: null,
    gender: null,
    employmentStatus: null,
    employmentPosition: null,
    dateOfBirth: null,
    countryOfBirth: null,
    countryOfResidence: null,
    address: null,
    postalCode: null,
    investmentAmount: null,
    investorDeclarationQualified: null,
  },
  reducers: {
    setIndustryGroupRedux: (state, { payload: { industryGroup } }) => {
      state.industryGroup = industryGroup;
    },
    setIndustryDropdownValuesRedux: (
      state,
      { payload: { industryDropdownValues } }
    ) => {
      state.industryDropdownValues = industryDropdownValues;
    },
    setIndustryRedux: (state, { payload: { industry } }) => {
      state.industry = industry;
    },
    setIndustryOthersRedux: (state, { payload: { industryOthers } }) => {
      state.industryOthers = industryOthers;
    },
    setOccupationeRedux: (state, { payload: { occupation } }) => {
      state.occupation = occupation;
    },
    setExperienceRedux: (state, { payload: { experience } }) => {
      state.experience = experience;
    },
    setPhoneRedux: (state, { payload: { phoneNumber } }) => {
      state.phoneNumber = phoneNumber;
    },
    setNationalityRedux: (state, { payload: { nationality } }) => {
      state.nationality = nationality;
    },
    setGenderRedux: (state, { payload: { gender } }) => {
      state.gender = gender;
    },
    setEmploymentStatusRedux: (state, { payload: { employmentStatus } }) => {
      state.employmentStatus = employmentStatus;
    },
    setEmploymentPositionRedux: (
      state,
      { payload: { employmentPosition } }
    ) => {
      state.employmentPosition = employmentPosition;
    },
    setdateOfBirthRedux: (state, { payload: { dateOfBirth } }) => {
      state.dateOfBirth = dateOfBirth;
    },
    setCountryOfBirthRedux: (state, { payload: { countryOfBirth } }) => {
      state.countryOfBirth = countryOfBirth;
    },
    setCountryOfResidenceRedux: (
      state,
      { payload: { countryOfResidence } }
    ) => {
      state.countryOfResidence = countryOfResidence;
    },
    setAddressRedux: (state, { payload: { address } }) => {
      state.address = address;
    },
    setPostalCodeRedux: (state, { payload: { postalCode } }) => {
      state.postalCode = postalCode;
    },
    setInvestmentAmountRedux: (state, { payload: { investmentAmount } }) => {
      state.investmentAmount = investmentAmount;
    },
    setInvestorDeclarationQualifiedRedux: (
      state,
      { payload: { investorDeclarationQualified } }
    ) => {
      state.investorDeclarationQualified = investorDeclarationQualified;
    },
  },
});

export const {
  setIndustryGroupRedux,
  setIndustryDropdownValuesRedux,
  setIndustryRedux,
  setOccupationeRedux,
  setExperienceRedux,
  setPhoneRedux,
  setNationalityRedux,
  setGenderRedux,
  setEmploymentStatusRedux,
  setEmploymentPositionRedux,
  setdateOfBirthRedux,
  setCountryOfBirthRedux,
  setCountryOfResidenceRedux,
  setAddressRedux,
  setPostalCodeRedux,
  setIndustryOthersRedux,
  setInvestmentAmountRedux,
  setInvestorDeclarationQualifiedRedux,
} = bizSlice.actions;

export default bizSlice.reducer;
