import { color } from "@funded-here-interface/common";
import { MantineTheme, createStyles } from "@mantine/styles";

export const useStyle = createStyles((theme: MantineTheme) => ({
  heading: {
    fontSize: "24px",
    fontWeight: 700,
  },
  links: {
    cursor: "pointer",
    marginRight: "26px",
  },
  stepContent: {
    paddingLeft: "44px",
  },
  optionContainer: {
    display: "flex",
    marginTop: "24px",
    marginBottom: "16px",
  },
  optionTextHeading: {
    fontWeight: 700,
  },
  uline: {
    width: "40px",
    height: "3px",
    background: color.FHGREEN,
    marginLeft: "0px",
  },
  para: {
    fontSize: "16px",
    marginBottom: "30px",
    color: color.LOANTEXT,
  },
  menyDiv: {
    display: "flex",
    gap: "50px",
  },
  downloadDiv: {
    display: "flex",
    gap: "10px",
  },
  buttonWrapper: {
    width: "205px",
    marginTop: "40px",
  },
  thankScreen: {
    backgroundColor: "rgba(255, 255, 255, .5)",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  thankContainer: {
    width: "600px",
    height: "277px",
    backgroundColor: color.WHITE,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "left",
    boxShadow:
      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
  },
  downloadTest: {
    color: color.FHGREEN,
    cursor: "pointer",
    fontWeight: 700,
    fontSize: "16px",
    display: "flex",
    flexWrap: "wrap",
    alignContent: "center",
  },
  num: {
    fontWeight: 700,
    fontSize: "24px",
    color: "black",
    lineHeight: "28px",
  },
  text: {
    display: "flex",
    flexWrap: "wrap",
    alignContent: "center",
  },
  container: {
    marginTop: "20px",
  },

  table: {
    maxWidth: "600px",
    marginBottom: 40,
  },
}));
