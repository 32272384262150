import axios from "axios";
import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";

interface DownloadMembershipRequest {
  token: string;
  isSpv?: boolean;
}

const downloadMembership = async ({
  token,
  isSpv = false,
}: DownloadMembershipRequest) => {
  try {
    const query = `?isSpv=${isSpv}`;

    const response = await axios.get(
      `${BASE_URL}/trade-directory/upload/download-membership${query}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
        withCredentials: true,
      }
    );
    return response.data;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);
    throw new Error(message);
  }
};

export default downloadMembership;
