import { Button } from "@funded-here-interface/common";
import { Text } from "@mantine/core";
import { FC, useEffect, useRef, useState } from "react";
import { useStyle } from "./LoanSigning.styles";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import axios from "axios";
import { UserType } from "../../types/login.type";
import { ToastContainer, toast } from "react-toastify";
import { REST_SERVER, WEB_DOMAIN } from "../../constants/domain";
import "react-toastify/dist/ReactToastify.css";
import BorrowerLayout from "../../layouts/BorrowerLayout/BorrowerLayout";

const getLoanAgreement = async ({ token }: UserType) => {
  try {
    const response = await axios.get(
      `${BASE_URL}/sku-finance/sn/sku-loans-funded`,
      {
        headers: {
          "Content-Type": "application/json",
          //   "authentication": token,
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (e) {
    throw new Error("Network response was not ok");
  }
};

const LoanSigning: FC = () => {
  const { classes } = useStyle();
  const [loandata, setLoanData] = useState<any>([]);
  const token = useSelector((state: RootState) => state.auth.token);

  useEffect(() => {
    getLoanAgreement({ token })
      .then((userDetail) => {
        setLoanData(userDetail);
      })
      .catch((error) => {
        console.error("Error fetching user details:", error);
      });
  }, []);
  function loadDocuSignScript() {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = "https://js-d.docusign.com/bundle.js";
      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);
    });
  }
  const [isDocuSignLoaded, setIsDocuSignLoaded] = useState(false);

  useEffect(() => {
    loadDocuSignScript()
      .then(() => {
        setIsDocuSignLoaded(true);
      })
      .catch((error) => {
        console.error("Failed to load DocuSign script", error);
      });
  }, []);

  const agreementRef = useRef(null);

  const handleOK = async () => {
    if (!isDocuSignLoaded) {
      console.error("DocuSign script not loaded yet");
      return;
    }

    try {
      const postData = {
        poolingSkuId: 1,
        webDomain: WEB_DOMAIN,
        redirectUrl: `${REST_SERVER}/document-management/docusign/webhook`,
      };

      const response = await axios.post(
        `${BASE_URL}/sku-finance/sn/get-loan-agreement-url`,
        postData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const { docuSignEnvelopeId, url } = response.data;
      const docusign = await window.DocuSign.loadDocuSign(docuSignEnvelopeId);

      const signing = docusign.signing({
        url: url,
        displayFormat: "focused",
        style: {
          branding: {
            primaryButton: {
              backgroundColor: "#333",
              color: "#fff",
            },
          },
          signingNavigationButton: {
            finishText: "You have finished the document! Hooray!",
            position: "bottom-center",
          },
        },
      });

      signing.on("ready", (event: any) => {});

      signing.on("sessionEnd", async (event: any) => {
        await postSubscriptionAgreementSigned();
      });
      if (agreementRef.current) {
        signing.mount(agreementRef.current);
      } else {
        toast.error("Agreement container not found");
      }
    } catch (ex) {
      console.error("Error initializing DocuSign", ex);
    }
  };

  const postSubscriptionAgreementSigned = async () => {
    try {
      const postData1 = {
        structuredNoteId: 1,
      };
      await axios.post(
        `${BASE_URL}/sku-finance/sn/subscription-agreement-signed`,
        postData1,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("Document Signed Successfully");
    } catch (error) {
      console.error("Error in postSubscriptionAgreementSigned", error);
      toast.error("Document is Already Signed");
    }
  };

  const handleNext = () => {};

  return (
    <BorrowerLayout
      content={
        <>
          <div
            className={classes.stepContent}
            style={{ marginTop: "0px", paddingTop: "0px" }}
          >
            <Text className={classes.headerText} style={{ marginTop: "10px" }}>
              View Loans
            </Text>
            <Text className={classes.subHeading}>
              To move forward with your loan application for the following
              SKU’s, we just need a signature from you on the Loan agreement
            </Text>
            <div style={{ width: "1200px" }}>
              <div className={classes.loanDataDiv}>
                <Text className={classes.loanData}>SKU Id </Text>
                <Text className={classes.loanData}>Invoice Value</Text>
                <Text className={classes.loanData}>LTV</Text>
                <Text className={classes.loanData}>Loan Amount</Text>
                <Text className={classes.loanData}>Interest Rate</Text>
                <Text className={classes.loanData}>Tenure</Text>
                <Text className={classes.loanData}>Start date</Text>
                <Text className={classes.loanData}>End date</Text>
              </div>
              {loandata.map((val: any) => {
                return (
                  <div className={classes.loanDataDiv}>
                    <Text className={classes.loanData}>FG {val?.skuId}</Text>
                    <Text className={classes.loanData}>
                      SGD {val?.invoiceValue}
                    </Text>
                    <Text className={classes.loanData}>
                      {val?.loanToValue}%
                    </Text>
                    <Text className={classes.loanData}>{val?.loanAmount}</Text>
                    <Text className={classes.loanData}>
                      {val?.interestRate}%
                    </Text>
                    <Text className={classes.loanData}>{val?.tenure} days</Text>
                    <Text className={classes.loanData}>{val?.StartDate}</Text>
                    <Text className={classes.loanData}>{val?.EndDate}</Text>
                  </div>
                );
              })}
            </div>
            <Text onClick={handleOK} className={classes.linkText}>
              Sign with Facial recognition & Docusign
            </Text>
            <div ref={agreementRef} id="agreement">
              {/* DocuSign will be mounted here */}
            </div>
            <ToastContainer />
            <div className={classes.buttonWrapper}>
              <Button
                onClick={handleNext}
                backgroundColor="#338F86"
                textColor="#ffff"
                children="Next"
                border="1px solid #338F86"
                width="254px"
              />
            </div>
          </div>
        </>
      }
    />
  );
};

export default LoanSigning;
