import { Button, color } from "@funded-here-interface/common";
import { Anchor, Checkbox, Group, MantineProvider, Modal } from "@mantine/core";
import { FC, useState } from "react";
import { useStyle } from "./MembershipAgreement.styles";
import useMemberShip from "../../hooks/useMemberShip";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { downloadFile } from "@funded-here-interface/common/src/Utils/file";
import useCompleteMembershipAgreement from "../../hooks/useCompleteMembershipAgreement";
import {
  setAgreedToMembershipAgreement,
  setAgreedToSpvMembershipAgreement,
} from "../../features/auth/authSlice";
import useCompleteOnboarding from "../../hooks/useCompleteOnboarding";
import { FileType } from "@funded-here-interface/common/src/constant/enum";

interface IProps {
  opened: boolean;
  close: () => void;
  onError: (value: string) => void;
  toCompleteOnboard?: boolean;
  isSpv?: boolean;
}

const MembershipAgreement: FC<IProps> = (props) => {
  const { classes } = useStyle();
  const { opened, close, onError, toCompleteOnboard, isSpv = false } = props;
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const useMemberShipMutation = useMemberShip();
  const token = useSelector((state: RootState) => state.auth.token);
  const dispatch = useDispatch();
  const useCompleteOnboardingMutation = useCompleteOnboarding();
  const useCompleteMembershipAgreementMutation =
    useCompleteMembershipAgreement();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.currentTarget.checked);
  };

  const handleAgree = () => {
    setIsLoading(true);
    useCompleteMembershipAgreementMutation.mutate(
      { token, isSpv },
      {
        onSuccess: () => {
          if (isSpv) {
            dispatch(
              setAgreedToSpvMembershipAgreement({
                agreedToSpvMembershipAgreement: true,
              })
            );
          } else {
            dispatch(
              setAgreedToMembershipAgreement({
                agreedToMembershipAgreement: true,
              })
            );
          }

          if (toCompleteOnboard) {
            useCompleteOnboardingMutation.mutate(
              { token },
              {
                onSuccess: () => {
                  setIsLoading(false);
                  close();
                },
                onError: (e) => {
                  onError((e as Error).message);
                },
              }
            );
          } else {
            setIsLoading(false);
            close();
          }
        },
        onError: (e) => {
          setIsLoading(false);
          onError((e as Error).message);
        },
      }
    );
  };

  const handleDownload = () => {
    useMemberShipMutation.mutate(
      { token, isSpv },
      {
        onSuccess: (data: Blob) => {
          downloadFile(data, "Membership Agreement", FileType.PDF);
        },
        onError: (e) => {
          onError((e as Error).message);
        },
      }
    );
  };

  return (
    <Modal
      withCloseButton={false}
      closeOnClickOutside={false}
      size="40rm"
      opened={opened}
      onClose={close}
    >
      <div style={{ padding: "24px" }}>
        <Group position="left" spacing="xs" noWrap>
          <MantineProvider theme={{ cursorType: "pointer" }}>
            <Checkbox onChange={handleChange} />
          </MantineProvider>
          <div style={{ fontSize: "14px" }}>
            I acknowledge I have read the{" "}
            <Anchor
              color={color.FHGREEN}
              underline={false}
              onClick={handleDownload}
              className={classes.agreement}
            >
              Membership Agreement
            </Anchor>
          </div>
        </Group>
        <div className={classes.buttonGroup}>
          <Button
            disabled={!isChecked || isLoading}
            width="100%"
            textColor={color.WHITE}
            backgroundColor={color.FHGREEN}
            children="Agree"
            onClick={handleAgree}
          />
        </div>
      </div>
    </Modal>
  );
};

export default MembershipAgreement;
