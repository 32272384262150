import {
  InputGroup,
  PasswordField,
  NAME_REGEX,
  EMAIL_REGEX,
  color,
} from "@funded-here-interface/common";
import { Text, Radio } from "@mantine/core";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStyle } from "./Registration.styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { isPasswordValid } from "../../Utils/formValidation";
import useCrateUserMutation from "../../hooks/useCreateUser";
import {
  EntityTypeEnum,
  Persona,
} from "@funded-here-interface/common/src/constant/enum";
import SignupCheckbox from "../../components/SignupCheckbox/SignupCheckbox";
import Turnstile from "react-turnstile";
import { CLOUDFLARE_TURNSTILE_SITE_KEY } from "@funded-here-interface/common/src/constant/constant";

const Registration: FC = () => {
  const { classes } = useStyle();
  const [entityType, setEntityType] = useState(EntityTypeEnum.INSTITUTION);
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState({
    email_error: "",
    pass_error: "",
    fname_error: "",
    lname_error: "",
  });
  const [isChecked, setIsChecked] = useState(false);

  const navigate = useNavigate();
  const mutation = useCrateUserMutation();

  const handleRadioChange = (value: EntityTypeEnum) => {
    setEntityType(value);
  };

  const handleSubmit = () => {
    if (email && isPasswordValid(password) && isChecked) {
      mutation.mutate(
        {
          email,
          password,
          entityType,
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          persona: Persona.INVESTOR,
        },
        {
          onSuccess: () => {
            navigate("/otp-verification", { state: { email } });
          },
          onError: (e) => {
            toast.error((e as Error).message);
          },
        }
      );
    } else {
      const newError = { ...error };
      if (!email) {
        newError.email_error = "Email is required";
      }
      if (!isPasswordValid(password)) {
        newError.pass_error = "Password is not valid";
      }
      if (!firstName) {
        newError.fname_error = "First Name is required";
      }
      if (!lastName) {
        newError.lname_error = "Last Name is required";
      }
      setError(newError);
    }
  };

  const handleCheckboxChange = (isChecked: boolean) => {
    setIsChecked(isChecked);
  };

  useEffect(() => {
    const newError = { ...error };
    if (email) {
      newError.email_error = "";
    }
    if (isPasswordValid(password)) {
      newError.pass_error = "";
    }
    if (firstName) {
      newError.fname_error = "";
    }
    if (lastName) {
      newError.lname_error = "";
    }
    setError(newError);
  }, [email, password, firstName, lastName]);

  const [verified, setVerified] = useState(false);

  // Cloudflare Turnstile Site Key
  const site_key: string = (CLOUDFLARE_TURNSTILE_SITE_KEY as string);

  return (
    <div className={classes.parentContainer}>
      <div className={classes.container}>
        <Text className={classes.headingContainer}>Investor</Text>
        <div className={classes.radioGroup}>
          <Radio
            label="Institution"
            name="org_type"
            value="institution"
            onChange={() => handleRadioChange(EntityTypeEnum.INSTITUTION)}
            checked={entityType === EntityTypeEnum.INSTITUTION}
          />
          <Radio
            label="Individual"
            name="org_type"
            value="individual"
            onChange={() => handleRadioChange(EntityTypeEnum.INDIVIDUAL)}
            checked={entityType === EntityTypeEnum.INDIVIDUAL}
          />
        </div>

        <div className={classes.inputGroup}>
          <InputGroup
            onChange={(value: any) => {
              setFirstName(value.replace(NAME_REGEX, ""));
            }}
            value={firstName}
            isErrored={error?.fname_error}
            labelText="First / given name (as in passport)"
            id="fname"
            placeHolder="Enter Your First / Given Name"
          />
          <InputGroup
            onChange={(value: any) =>
              setLastName(value.replace(NAME_REGEX, ""))
            }
            value={lastName}
            isErrored={error?.lname_error}
            labelText=" Last name (as in passport)"
            id="lname"
            placeHolder="Enter Your Last Name"
          />
          <InputGroup
            onChange={(value: any) =>
              setEmail(value.replace(EMAIL_REGEX, "").trim())
            }
            value={email}
            isErrored={error?.email_error}
            labelText="Email"
            id="email"
            placeHolder="Enter Your Email Id"
          />
          <div className={classes.tooltipContainer}></div>
          <PasswordField
            error={error.pass_error}
            onChange={setPassword}
            label="Password"
            name="password"
            tooltipContent="At least one lowercase letter, one uppercase letter, one number, one special character, minimum of 8 characters."
            className={classes.inputbox}
          />
        </div>
        <div className={classes.inputGroup}>
          <Turnstile
            sitekey={site_key}
            theme="light"
            size="flexible"
            onVerify={() => setVerified(true)}
          />
        </div>
        <div className={classes.agreeGroup}>
          <SignupCheckbox onChange={handleCheckboxChange} />
        </div>
        <div className={classes.buttonGroup}>
          <button
            disabled={!isChecked || !verified}
            style={{
              color: "white",
              backgroundColor: isChecked && verified ? color.FHGREEN : "#BDBDBD",

              fontWeight: 800,
              height: "50px",
              borderRadius: "5px",
              outline: "none",
              border: "none",
            }}
            className={classes.btn}
            children="Register"
            onClick={handleSubmit}
          />
          <p className={classes.subtext}>
            *Please note that only{" "}
            <span style={{ fontWeight: "bold", fontSize: "15px" }}>
              accredited investors
            </span>{" "}
            are eligible to participate in investment opportunities presented on
            this website.
          </p>
        </div>

        <ToastContainer />
      </div>
    </div>
  );
};

export default Registration;
