import axios from "axios";
const BASE_URL = "https://api-uat.dashboard.fundedhere.com/api/kafka";

export const sendKafkaGetUser = async (token: string) => {
  try {
    const response = await axios.post(
      `${BASE_URL}/get-user`,
      {
        token,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response;
  } catch (e) {
    if (axios.isAxiosError(e)) {
      return e.response?.status;
    }
  }
};
