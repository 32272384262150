import React, { useEffect, useState } from "react";
import HttpClientXml from "../../../../Utils/HttpClientXmInv";
import toast from "react-hot-toast";
import moment from "moment";
import { useSelector } from "react-redux";
import ErrorBoundary from "@funded-here-interface/common/src/dashboard/components/ErrorBoundry";
import HttpClientXmInv from "../../../../Utils/HttpClientXmInv";

function getDate() {
  const today = new Date();
  const options = { day: "numeric", month: "short", year: "numeric" };
  const formattedDate = today.toLocaleDateString(undefined, options);
  return formattedDate;
}

const StructureNote = () => {
  const [dropDownData, setDropDownData] = useState([]);
  const [currentDate, setCurrentDate] = useState(getDate());
  const [isopen, setIsopen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [AlltypeAmount, setAlltypeAmount] = useState([]);
  const token = useSelector((state) => state.auth.token);

  const handleDropdownChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedOption(selectedValue);
    setIsopen(false);
  };

  const loop = [
    {
      id: 1,
      name: "Issue Date",
      date:
        AlltypeAmount.length > 0
          ? moment(AlltypeAmount[0]?.issueDate).format("DD-MM-YYYY")
          : "",
    },
    {
      id: 1,
      name: "Tenure (in days)",
      date: AlltypeAmount.length > 0 ? AlltypeAmount[0]?.tenure : "",
    },
    {
      id: 1,
      name: "Maturity Date",
      date:
        AlltypeAmount.length > 0
          ? moment(AlltypeAmount[0]?.maturityDate).format("DD-MM-YYYY")
          : "",
    },
    {
      id: 1,
      name: "Days to Maturity",
      date:
        AlltypeAmount?.[0]?.daysToMaturity > 0
          ? parseFloat(AlltypeAmount?.[0].daysToMaturity.toFixed(2))
          : "",
    },
    {
      id: 1,
      name: "Total Merchants",
      date: AlltypeAmount?.[0]?.totalMerchants,
    },
    { id: 1, name: "Total SkUs", date: AlltypeAmount?.[0]?.totalSKU },
  ];

  const dataloop = [
    {
      id: 1,
      point: "Invested Amount",
      number: AlltypeAmount?.[0]?.investedAmount?.toLocaleString("en-US"),
    },
    {
      id: 2,
      point: "Repaid Amount",
      number: AlltypeAmount?.[0]?.repaidAmount?.toLocaleString("en-US"),
    },
    {
      id: 3,
      point: "Balance Amount",
      number: AlltypeAmount?.[0]?.balanceAmount?.toLocaleString("en-US"),
    },
    {
      id: 4,
      point: "Effective ROR",
      number: `${AlltypeAmount?.[0]?.effectiveROR?.toLocaleString("en-US")}%`,
    },
    {
      id: 5,
      point: "Net Interest",
      number: AlltypeAmount?.[0]?.interest?.toLocaleString("en-US"),
    },
  ];

  //get date and tenure
  const getDateTenure = async () => {
    setIsLoading(true);
  };

  //Get All type amount
  const getAlltypeAmount = async () => {
    setIsLoading(true);
    const snId = selectedOption;

    const res = await HttpClientXml.requestData(
      `view-note-summary-bySnId/${snId}`,
      {},
      token,
      "POST"
    );

    if (res && res.status) {
      setIsLoading(false);
      setAlltypeAmount(res.data);
    } else {
      toast.error("Failed to fetch amount data");
      setIsLoading(false);
    }
  };

  const fetchDropDownValue = async () => {
    const res = await HttpClientXmInv.requestData(
      `get-sn-drop-down-investor`,
      {},
      token,
      "GET"
    );
    if (res && res?.status) {
      setDropDownData(res?.data);
      setSelectedOption(res?.data?.[0]?._id);
    }
  };

  useEffect(() => {
    fetchDropDownValue();
  }, []);

  useEffect(() => {
    getAlltypeAmount();
    getDateTenure();
  }, [selectedOption]);

  useEffect(() => {
    // getData();
    setCurrentDate(getDate());
  }, []);
  return (
    <ErrorBoundary>
      <div className="summaryPart">
        <div className="summaryPartTop">
          <div className="summaryPartLeft">
            <p className="summaryTophead">
              Note Summary as on <span>{currentDate}</span>{" "}
            </p>
          </div>
          <div className="summaryPartRight">
            <h5>*Select Note ID from dropdown as Summary View. </h5>
            <div className="summaryDropDiv">
              <select
                className="SumDropbtn"
                onChange={handleDropdownChange}
                value={selectedOption}
              >
                {dropDownData.map((drop, ind) => (
                  <option key={ind} value={drop?._id}>
                    {drop?._id}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </div>

        <div className="summaryPartBtm">
          <div className="summaryCardText">
            {loop.map((details, index) => {
              return (
                <div className="summaryCardDiv" key={index}>
                  <div className="summaryCardName">
                    <p>{details.name}</p>
                  </div>
                  <div className="summaryCardDate">
                    <p>{details.date}</p>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="summaryCardData">
            {dataloop.map((item, index) => {
              return (
                <div className="summaryCardDataInn" key={index}>
                  <p className="sumPointName">{item.point}</p>
                  <p className="sumDataNum">{item.number}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default StructureNote;
