import { useQuery } from "react-query";
import { getWithdrawalFund } from "../services/getWithdrawalFund";

function useWithdrawalFund(token: string, currency: string) {
  return useQuery(
    ["get-withdrawal-fund", token],
    () => getWithdrawalFund({ token, currency }),
    {
      enabled: false,
    }
  );
}

export default useWithdrawalFund;
