import { FC, useEffect, useState } from "react";
import Graphamount from "./MerchantDashboard/Graphamount";
import HorizontalBar from "./MerchantDashboard/HorizontalBar";
import Chart from "./MerchantDashboard/Chart";
import Header from "@funded-here-interface/common/src/dashboard/components/Header";
import "./dashboard.css";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { sendKafkaGetUser } from "@funded-here-interface/common/src/services/dashboard";
import { ToastContainer, toast } from "react-toastify";
import MembershipAgreement from "../../components/MembershipAgreement/MembershipAgreement";
import { useDisclosure } from "@mantine/hooks";

const BorrowerDashboard: FC = () => {
  const {
    token,
    agreedToMembershipAgreement,
    isAuthRep,
    spvDetails,
    agreedToSpvMembershipAgreement,
  } = useSelector((state: RootState) => state.auth);
  const isSpv = !!spvDetails;
  const agreedToMembership = isSpv
    ? agreedToSpvMembershipAgreement === true
    : agreedToMembershipAgreement === true;
  const [toastShown, setToastShown] = useState(false);
  const [opened, { close }] = useDisclosure(
    isAuthRep === true && !agreedToMembership
  );

  useEffect(() => {
    sendKafkaGetUser(token as unknown as string)
      .then((result) => {
        if (result === 404 && !toastShown) {
          toast.error("User not in Dashboard");
          setToastShown(true);
        }
      })
      .catch((error) => {
        console.error("An error occurred:", error);
      });
  }, [token]);
  return (
    <>
      <MembershipAgreement
        isSpv={isSpv}
        onError={(value: string) => {
          toast.error(value);
        }}
        opened={opened}
        close={close}
      />
      <Header />

      <ToastContainer />
      <div className="RestPage">
        <div className="container-fluid">
          <div className="graphPart">
            {/* <p className='overallChartPara'>*All amounts are in USD</p> */}
            <Graphamount />
            <HorizontalBar />
          </div>
          <div className="overallPageChart">
            <Chart />
          </div>
        </div>
      </div>
    </>
  );
};

export default BorrowerDashboard;
