import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";
import axios from "axios";

interface GetActivePaymentTrackingRequest {
  token: string;
  limit: string;
  page: string;
  search?: string;
  sortBy?: string;
  sortOrder?: "ASC" | "DESC";
}

const getActivePaymentTracking = async ({
  token,
  limit,
  page,
  search,
  sortBy,
  sortOrder,
}: GetActivePaymentTrackingRequest) => {
  try {
    let url = `${BASE_URL}/sku-finance/dashboard/active-payment-tracking?page=${page}&limit=${limit}`;

    if (search) {
      url = `${url}&search=${search}`;
    }

    if (sortBy && sortOrder) {
      url = `${url}&sortOrder=${sortOrder}&sortBy=${sortBy}`;
    }

    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      withCredentials: true,
    });

    return response.data;
  } catch (e) {
    const message = getApiResponseErrorMsg(e);
    throw new Error(message);
  }
};

export default getActivePaymentTracking;
