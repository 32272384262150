import React, { useState } from "react";
import { useStyle } from "./LoanAcceptance.styles";
import { formatPercentageFromRatio } from "../../Utils/formatter";

interface TableRow {
  skuId: string;
  description: string;
  loanAmount2: string;
  loanAmount2OtherCurrency: string;
  otherCurrency: string;
  loanToValueSku: string;
  interestRate: string;
  tenure: number;
  adminFee: string;
  acquirerFees: string;
  loanApplicationCurrency: string;
}

interface MyTableProps {
  data: TableRow[];
  onCheckboxChange: (skuId: string) => void;
}

const MyTable: React.FC<MyTableProps> = ({ data, onCheckboxChange }) => {
  const { classes } = useStyle();
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [selectedRowIndices, setSelectedRowIndices] = useState<number[]>([]);

  const handleCheckboxChange = (rowId: number) => {
    const id = data[rowId].skuId;

    onCheckboxChange(id);

    const updatedSelectedRowIndices = [...selectedRowIndices];
    if (updatedSelectedRowIndices.includes(rowId)) {
      // If rowId is already selected, remove it
      updatedSelectedRowIndices.splice(
        updatedSelectedRowIndices.indexOf(rowId),
        1
      );
      setSelectAllChecked(false);
    } else {
      // If rowId is not selected, add it
      updatedSelectedRowIndices.push(rowId);

      if (data.length === updatedSelectedRowIndices.length) {
        setSelectAllChecked(true);
      }
    }
    setSelectedRowIndices(updatedSelectedRowIndices);
  };

  const handleAllCheckboxSelected = () => {
    const newSelectAllChecked = !selectAllChecked;
    setSelectAllChecked(newSelectAllChecked);

    if (newSelectAllChecked) {
      // If "Select All" is checked, select all rows
      setSelectedRowIndices(data.map((_, index) => index));
    } else {
      // If "Select All" is unchecked, deselect all rows
      setSelectedRowIndices([]);
    }

    data.forEach((row, index) => {
      // You might need to adjust this part depending on how you're handling selected rows
      onCheckboxChange(row.skuId);
    });
  };

  return (
    <table
      style={{ borderCollapse: "collapse", width: "100%", border: "none" }}
    >
      <thead>
        <tr>
          <th className={classes.thh}>
            <input
              type="checkbox"
              checked={selectAllChecked}
              onChange={handleAllCheckboxSelected}
            />
          </th>
          <th className={classes.thh}>SKU ID</th>
          <th className={classes.thh}>Description</th>
          <th className={classes.thh}>Indicative Loan Amount</th>
          <th className={classes.thh}>
            Indicative Loan Amount (Other Currency)
          </th>
          <th className={classes.thh}>Indicative Loan LTV %</th>
          <th className={classes.thh}>Indicative Interest Rate p.m</th>
          <th className={classes.thh}>Indicative Tenure</th>
          <th className={classes.thh}>Management Fees</th>
          <th className={classes.thh}>Admin Fees</th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <tr key={index}>
            <td className={classes.th} style={{ border: "none" }}>
              <input
                type="checkbox"
                checked={selectedRowIndices.includes(index)}
                onChange={() => handleCheckboxChange(index)}
              />
            </td>
            <td className={classes.th}>{row.skuId}</td>
            <td className={classes.th}>{row.description}</td>
            <td className={classes.th}>
              {row.loanApplicationCurrency + " " + row.loanAmount2}
            </td>
            <td className={classes.th}>
              {row.loanAmount2OtherCurrency
                ? row.otherCurrency + " " + row.loanAmount2OtherCurrency
                : "-"}
            </td>
            <td className={classes.th}>
              {formatPercentageFromRatio(row.loanToValueSku) + "%"}
            </td>
            <td className={classes.th}>
              {formatPercentageFromRatio(row.interestRate) + "%"}
            </td>
            <td className={classes.th}>{row.tenure + " days"}</td>
            <td className={classes.th}>
              {row.loanApplicationCurrency + " " + row.acquirerFees}
            </td>
            <td className={classes.th}>
              {row.loanApplicationCurrency + " " + row.adminFee}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default MyTable;
