import DataTableownInterest from "../components/DataTableownInterest";
import StructureNote from "./StructureNote";
import ErrorBoundary from "@funded-here-interface/common/src/dashboard/components/ErrorBoundry";

const SnSummaryView = () => {
  return (
    <ErrorBoundary>
      <div className="RestPage">
        <div className="container-fluid">
          <div className="row investRow2 mb-3">
            <div className="col">
              <div className="eStateTableDiv">
                <p className="eStateHead">Repayment Analysis</p>
                <div className="dataTableDiv snDataTable">
                  <DataTableownInterest />
                </div>
              </div>
              {/* <SnSummaryNewChart /> */}
            </div>
          </div>
          <div className="structurenoteDiv">
            <StructureNote />
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default SnSummaryView;
