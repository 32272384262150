import { FC, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "@funded-here-interface/common/src/constant/constant";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { useStyle } from "./SFpayment.style";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MyTable from "./Table";
import { DataType } from "../../types/SfPayment";
import { setDecimalPlaces } from "../../Utils/formatter";
type Props = {};

const SFpayment: FC<Props> = () => {
  const { classes } = useStyle();
  const Navigate = useNavigate();

  const token = useSelector((state: RootState) => state.auth.token);

  const [data, setData] = useState<DataType | undefined>(undefined);

  const getLoans = async () => {
    try {
      const response = await axios.get(
        `${BASE_URL}/sku-finance/loan-application/pending-funding`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data as DataType;
    } catch (e) {
      throw new Error("Network response was not ok");
    }
  };

  useEffect(() => {
    getLoans()
      .then((skus) => {
        setData(skus);
      })
      .catch((error) => {
        console.error("Error fetching user details:", error);
      });
  }, []);

  return (
    <div>
      <div>
        <p>
          To initiate the Loan process we need you to make the following
          payments
        </p>
        <br />
        {data && data.skus && data.totalsByCurrency && (
          <MyTable data={data.skus} total={data.totalsByCurrency} />
        )}
      </div>
      <ToastContainer />
      {data && data.bankAccountDetails && (
        <div>
          <div className={classes.payment}>
            <div className={classes.paymentTo}>
              <h3>Payment To</h3>
            </div>

            <div>
              <span>Bank Name: {data.bankAccountDetails.bankName}</span>
            </div>

            <div>
              <span>
                Account Name: {data.bankAccountDetails.bankAccountHolder}{" "}
              </span>
            </div>

            <div>
              <span>Swift Code: {data.bankAccountDetails.swiftCode} </span>
            </div>
          </div>
          <div className={classes.paymentDetails}>
            <div className={classes.internalPayment}>
              <div className={classes.acchead}>
                <span>
                  Total QC in SGD:
                  {" " +
                    setDecimalPlaces(
                      data.totalsByCurrency?.SGD.moe as string,
                      2
                    )}
                </span>
              </div>
              <div>
                <span className={classes.block}>Account #</span>
                <span className={classes.block}>
                  {data.bankAccountDetails.moeBankAccountId}
                </span>
              </div>
            </div>
            <div className={classes.internalPayment}>
              <div className={classes.acchead}>
                <span>
                  Total SF in USD :
                  {" " +
                    setDecimalPlaces(
                      data.totalsByCurrency?.USD.sinkingFund as string,
                      2
                    )}
                </span>
              </div>
              <div>
                <span className={classes.block}>Account #</span>
                <span className={classes.block}>
                  {data.bankAccountDetails.sfUsdBankAccountId}
                </span>
              </div>
            </div>
            <div className={classes.internalPayment}>
              <div className={classes.acchead}>
                <span>
                  Total SF in SGD :
                  {" " +
                    setDecimalPlaces(
                      data.totalsByCurrency?.SGD.sinkingFund as string,
                      2
                    )}
                </span>
              </div>
              <div>
                <span className={classes.block}>Account #</span>
                <span className={classes.block}>
                  {data.bankAccountDetails.sfSgdBankAccountId}
                </span>
              </div>
            </div>
            <div className={classes.internalPayment}>
              <div className={classes.acchead}>
                <span>
                  Total ASF in USD :
                  {" " +
                    setDecimalPlaces(
                      data.totalsByCurrency?.USD
                        .additionalSinkingFund as string,
                      2
                    )}
                </span>
              </div>
              <div>
                <span className={classes.block}>Account #</span>
                <span className={classes.block}>
                  {data.bankAccountDetails.asfUsdBankAccountId}
                </span>
              </div>
            </div>
            <div className={classes.internalPayment}>
              <div className={classes.acchead}>
                <span>
                  Total ASF in SGD :
                  {" " +
                    setDecimalPlaces(
                      data.totalsByCurrency?.SGD
                        .additionalSinkingFund as string,
                      2
                    )}
                </span>
              </div>
              <div>
                <span className={classes.block}>Account #</span>
                <span className={classes.block}>
                  {data.bankAccountDetails.asfSgdBankAccountId}
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SFpayment;
