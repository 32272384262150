import { Center, Loader, Text } from "@mantine/core";
import { FC, useEffect, useRef, useState } from "react";
import { useStyle } from "./BMCASigning.styles";
import { Button } from "@funded-here-interface/common";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { REST_SERVER, WEB_DOMAIN } from "../../constants/domain";
import VQRVerifyPage from "../../components/VQR/VQRVerifyPage";
import useCompleteOnboarding from "../../hooks/useCompleteOnboarding";
import { useMasterSkuBorrowerSign } from "../../hooks/useDocuSign";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";
import { useDisclosure } from "@mantine/hooks";
import useSignedDocument from "../../hooks/useSignedDocument";
import SignQRModal from "../../components/SignQRModal/SignQRModal";
import DocViewer from "@cyntler/react-doc-viewer";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

const BMCASigning: FC = () => {
  const location = useLocation();
  const skipDidVerify = location.state?.skipDidVerify;
  const { classes } = useStyle();
  const Navigate = useNavigate();
  const token = useSelector((state: RootState) => state.auth.token);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(
    skipDidVerify ?? false
  );
  const masterSkuBorrowerMutation = useMasterSkuBorrowerSign();
  const useCompleteOnboardingMutation = useCompleteOnboarding();
  const signedDocument = useSignedDocument();
  const [completedAt, setCompletedAt] = useState("");

  const [realUrl, setRealUrl] = useState("");
  const [documentId, setDocumentId] = useState(0);

  const [opened, { open, close }] = useDisclosure(false);

  const docs = [
    {
      uri: realUrl,
      fileType: "pdf",
    },
  ];

  const [viewerKey, setViewerKey] = useState(0);

  const handleSign = (isAuthenticated: boolean) => {
    if (isAuthenticated) {
      close();
      setRealUrl("");
      signedDocument.mutate(
        { documentId, token },
        {
          onSuccess: (data) => {
            useCompleteOnboardingMutation.mutate(
              { token },
              {
                onSuccess: () => {
                  toast.success("Document Signed Successfully");
                  setRealUrl(data.url);
                  setDocumentId(data.documentId);
                  setCompletedAt(data.completedAt);
                  setViewerKey((prevKey) => prevKey + 1);
                },
                onError: (e) => {
                  toast.error((e as Error).message);
                },
              }
            );
          },
          onError: (e) => {
            toast.error((e as Error).message);
          },
        }
      );
    }
  };

  const handleAuthenticatedChange = (isAuthenticated: boolean) => {
    setIsAuthenticated(isAuthenticated);
    if (isAuthenticated) {
      handleOK();
    }
  };

  useEffect(() => {
    // loadDocuSignScript();
    if (skipDidVerify) {
      handleOK();
      setViewerKey((prevKey) => prevKey + 1);
    }
  }, []);

  const handleOK = async () => {
    toast.info("Document is loading");
    try {
      masterSkuBorrowerMutation.mutate(
        {
          token,
          webDomain: WEB_DOMAIN,
          redirectUrl: `${REST_SERVER}/document-management/docusign/webhook`,
        },
        {
          onSuccess: async (data) => {
            setRealUrl(data.url);
            setDocumentId(data.documentId);
            setCompletedAt(data.completedAt);
          },
          onError: (e) => {
            toast.error((e as Error).message);
          },
        }
      );
    } catch (ex) {
      toast.error("Error initializing DocuSign");
    }
  };

  const agreementRef = useRef(null);

  // TODO: remove once docu sign is setup but please make sure to make this endpoint call to complete onboarding
  const handleNext = async () => {
    useCompleteOnboardingMutation.mutate(
      { token },
      {
        onSuccess: () => {
          // if (ENV === "production") {
          //   Navigate("/merchant-complete-onboarding");
          // } else {
          toast.success("Document Signed Successfully");
          // }
          setViewerKey((prevKey) => prevKey + 1);
        },
        onError: (e) => {
          toast.error((e as Error).message);
        },
      }
    );
  };

  const navigateNext = () => {
    Navigate("/merchant-complete-onboarding");
  };

  const handleOnVerifyMeNext = () => {
    setIsAuthenticated(true);
  };

  const openModal = () => {
    setViewerKey((prevKey) => prevKey + 1);
    open();
  };

  const closeModal = () => {
    setViewerKey((prevKey) => prevKey + 1);
    close();
  };

  return (
    <>
      {!isAuthenticated && (
        <VQRVerifyPage
          onAuthenticatedChange={handleAuthenticatedChange}
          onNext={handleOnVerifyMeNext}
        />
      )}
      {isAuthenticated && (
        <div className={classes.tempWideDisplay}>
          <Text className={classes.headerText}>
            Borrower Master Credit Agreement
          </Text>
          <Text>
            This Borrower Service Agreement outlines the terms and conditions
            governing our lending services. Please download, review, and sign to
            signify your acceptance.
          </Text>
          <div className={classes.agreeGroup2}>
            <label className={classes.disclaimerAccept2} htmlFor="agree">
              Borrower Service Agreement
            </label>
          </div>
          {realUrl !== "" && (
            <>
              <DocViewer
                documents={docs}
                style={{ width: "100%", height: 500, overflow: "auto" }}
                className={classes.docviewer}
                key={viewerKey}
                config={{
                  header: {
                    disableHeader: false,
                    disableFileName: true,
                    retainURLParams: false,
                  },
                  pdfVerticalScrollByDefault: true, // false as default
                }}
              />

              {completedAt === "" && (
                <div className={classes.buttonContainer}>
                  <Button
                    onClick={openModal}
                    textColor="white"
                    backgroundColor="#338F86"
                    children="Proceed to Sign"
                  />
                </div>
              )}

              {completedAt !== "" && (
                <div className={classes.buttonContainer}>
                  <Button
                    onClick={navigateNext}
                    textColor="white"
                    backgroundColor="#338F86"
                    children="Okay"
                  />
                </div>
              )}
            </>
          )}

          {realUrl == "" && (
            <div>
              <Center maw={400} h={500} mx="auto">
                <Loader />
              </Center>
            </div>
          )}

          <SignQRModal
            opened={opened}
            close={closeModal}
            onSignVerify={handleSign}
          ></SignQRModal>
        </div>
      )}
    </>
  );
};

export default BMCASigning;
