import { Button, color } from "@funded-here-interface/common";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { RootState } from "@funded-here-interface/investor-onboarding/src/store";
import { useStyle } from "../../styles/BorrowerDocUpload";
import { investorinddata } from "./Data";
import useCreateOrg from "../../hooks/useCreateOrg";
import {
  InvestmentMode,
  Persona,
} from "@funded-here-interface/common/src/constant/enum";
import { Industry } from "../../constants/enums";
import { getCode } from "country-list";
import FileUploadComponent from "../../components/FileUpload/FileUpload";
import { Bank } from "../../types/org.type";
import { countryCodes } from "@funded-here-interface/common/src/constant/country";

const IndInvestorDoc: FC = () => {
  const { classes } = useStyle();
  const [isLoading, setIsLoading] = useState(false);
  const {
    proof: proofOfAddress,
    salaryProof,
    bank: bankStatement,
  } = useSelector((state: RootState) => state.bordoc);

  const {
    industryGroup,
    industry,
    industryOthers,
    occupation,
    experience,
    nationality,
    gender,
    employmentStatus,
    employmentPosition,
    dateOfBirth,
    countryOfBirth,
    countryOfResidence,
    address,
    postalCode,
    investmentAmount,
    phoneNumber,
  } = useSelector((state: RootState) => state.prof);

  const { bankName, accountNumber, accountType, swiftCode, accountHolderName } =
    useSelector((state: RootState) => state.bankInd);
  const createOrgmutation = useCreateOrg();

  const { token, userFirstName, userLastName } = useSelector(
    (state: RootState) => state.auth
  );

  const Navigate = useNavigate();
  const handleBack = () => {
    Navigate("/bank-details-ind");
  };

  const handleNext = async () => {
    const missingFiles = [];

    if (proofOfAddress.length === 0) missingFiles.push("proof of address file");
    if (salaryProof.length === 0) missingFiles.push("salary proof file");
    if (bankStatement.length === 0) missingFiles.push("bank statement file");

    if (missingFiles.length > 0) {
      toast.error(`Please upload ${missingFiles.join(", ")}`);

      return;
    }

    const bank: Bank = {
      bankName: bankName! as string,
      swiftCode: swiftCode! as string,
      accountNo: accountNumber! as string,
      accountType: (accountType! as string).toLowerCase(),
      acountHolderName: accountHolderName! as string,
    };

    const proofFilePaths = proofOfAddress.map((f) => f.path);
    const salaryFilePaths = salaryProof.map((f) => f.path);
    const bankFilePaths = bankStatement.map((f) => f.path);

    const documentsUpload = {
      proofOfAddress: proofFilePaths,
      salaryProof: salaryFilePaths,
      bankStatement: bankFilePaths,
    };

    const investmentPreferences = {
      investmentSize: investmentAmount! as number,
      investmentMode: InvestmentMode.MANUAL,
    };

    let countryCode = null;

    if (countryOfResidence && countryOfResidence in countryCodes) {
      countryCode = countryCodes[countryOfResidence];
    } else {
      getCode(countryOfResidence);
    }

    setIsLoading(true);
    createOrgmutation.mutate(
      {
        bank,
        token,
        companyPhoneNumber: phoneNumber! as string,
        onBoardingUserType: Persona.INVESTOR,
        nameOfAuthorisedRepresentiative: `${userFirstName} ${userLastName}`,
        occupation: occupation! as string,
        numberofYears: parseInt(experience!),
        legalEntityAddress: address! as string,
        countryISOCode: countryCode,
        countryOfIncorporation: countryOfResidence! as string,
        industryGroup: industryGroup! as string,
        industry:
          industry === Industry.OTHERS
            ? (industryOthers! as string)
            : (industry! as string),
        postalCode: parseInt(`${postalCode}`),
        nationality: nationality! as string,
        gender: gender! as string,
        employmentStatus: employmentStatus! as string,
        employmentPosition: employmentPosition! as string,
        dateOfBirth: dateOfBirth! as string,
        countryOfBirth: countryOfBirth! as string,
        documentsUpload,
        investmentPreferences,
      },
      {
        onSuccess: () => {
          setIsLoading(false);
          Navigate("/identify-me");
        },
        onError: (e) => {
          setIsLoading(false);
          toast.error((e as Error).message);
        },
      }
    );
  };

  return (
    <>
      <div className={classes.topDiv}>
        <p className={classes.headinfo}>
          All uploads must be in PDF format. The size should not exceed 10 MB
          each
        </p>
      </div>

      <div
        className={classes.stepContent}
        style={{ marginTop: "0px", background: "#fff" }}
      >
        {investorinddata.map((item) => (
          <FileUploadComponent
            key={item.number}
            number={item.number}
            fileId={item.id}
            FileName={item.heading}
          />
        ))}

        <div className={classes.buttonDiv2}>
          <div className={classes.buttonWrapper}>
            <Button
              onClick={handleBack}
              backgroundColor={color.WHITE}
              textColor={color.FHGREEN}
              children="Back"
              border="1px solid #338F86"
              width="210px"
            />
          </div>
          <div className={classes.buttonWrapper}>
            <Button
              disabled={isLoading}
              onClick={handleNext}
              textColor={color.WHITE}
              backgroundColor={color.FHGREEN}
              children="Next"
              width="210px"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default IndInvestorDoc;
