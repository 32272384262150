import { Button, color } from "@funded-here-interface/common";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { RootState } from "@funded-here-interface/investor-onboarding/src/store";
import { useStyle } from "../../styles/InvestorDocUpload";
import { fundInvestorData, investordata } from "./Data";
import { Industry, LegalStructure } from "../../constants/enums";
import { Funds } from "../../constants/dropdownValues";
import useCreateOrg from "../../hooks/useCreateOrg";
import { Bank } from "../../types/org.type";
import { getCode } from "country-list";
import OnboardingLayout from "../../layouts/OnboardingLayout/OnboardingLayout";
import {
  CompanyRole,
  InvestmentMode,
  Persona,
} from "@funded-here-interface/common/src/constant/enum";
import FileUploadComponent from "../../components/FileUpload/FileUpload";
import { DocumentsUpload } from "@funded-here-interface/common/src/constant/type";
import { countryCodes } from "@funded-here-interface/common/src/constant/country";

const InvestorDocsUpload: FC = () => {
  const { classes } = useStyle();
  const [isLoading, setIsLoading] = useState(false);
  const createOrgmutation = useCreateOrg();

  const {
    proof: proofOfAddress,
    share: shareholdersDirectorsRegisters,
    financialStatement,
    business: businessProfile,
    investmentMandate,
    fundMangerLicense,
  } = useSelector((state: RootState) => state.bordoc);

  const {
    company,
    regNumber,
    legalStructure,
    legalStructureOthers,
    countryOfDomicile,
    countryOfIncorp,
    address,
    postal,
    phoneNumber,
    website,
    industryGroup,
    industry,
    industryOthers,
    investmentAmount,
    incorporationDate,
  } = useSelector((state: RootState) => state.biz);

  const {
    repFirstName,
    repLastName,
    repTitle,
    repEmail,
    userTitle,
    shareholdersDirectors,
  } = useSelector((state: RootState) => state.rep);

  const shareholders = shareholdersDirectors
    ? shareholdersDirectors.filter(
        (user) => user.role === CompanyRole.SHAREHOLDER
      )
    : null;
  const directors = shareholdersDirectors
    ? shareholdersDirectors.filter(
        (user) =>
          user.role === CompanyRole.BOTH || user.role === CompanyRole.DIRECTOR
      )
    : null;

  const { bankName, swiftCode, accountHolderName, accountNumber, accountType } =
    useSelector((state: RootState) => state.bank);

  const { token, type, userEmail } = useSelector(
    (state: RootState) => state.auth
  );

  const documents = Funds.includes(legalStructure as LegalStructure)
    ? [...fundInvestorData]
    : [...investordata];

  const Navigate = useNavigate();
  const handleBack = () => {
    Navigate("/bank-details");
  };

  const handleNext = async () => {
    const missingFiles = [];

    if (businessProfile.length === 0)
      missingFiles.push("business profile file");
    if (shareholdersDirectorsRegisters.length === 0)
      missingFiles.push("shareholder and directors registers file");
    if (financialStatement.length === 0)
      missingFiles.push("financial statement file");
    if (proofOfAddress.length === 0) missingFiles.push("proof of address file");

    if (Funds.includes(legalStructure as LegalStructure)) {
      if (investmentMandate.length === 0)
        missingFiles.push("investment mandate file");
      if (fundMangerLicense.length === 0)
        missingFiles.push("fund manager file");
    }

    if (missingFiles.length > 0) {
      toast.error(`Please upload ${missingFiles.join(", ")}`);

      return;
    }

    const bank: Bank = {
      bankName: bankName as string,
      swiftCode: swiftCode as string,
      accountNo: accountNumber as string,
      acountHolderName: accountHolderName as string,
      accountType: (accountType as string).toLowerCase(),
    };

    const proofFilePaths = proofOfAddress.map((f) => f.path);
    const businessFilePaths = businessProfile.map((f) => f.path);
    const shareHolderFilePaths = shareholdersDirectorsRegisters.map(
      (f) => f.path
    );
    const fiancialFilePaths = financialStatement.map((f) => f.path);
    const investmentMandateFilePaths =
      investmentMandate.length > 0
        ? investmentMandate.map((f) => f.path)
        : undefined;

    const fundMangerLicenseFilePaths =
      fundMangerLicense.length > 0
        ? fundMangerLicense.map((f) => f.path)
        : undefined;

    const documentsUpload: DocumentsUpload = {
      proofOfAddress: proofFilePaths,
      businessProfile: businessFilePaths,
      shareHoldersDirectorsRegisters: shareHolderFilePaths,
      financialStatement: fiancialFilePaths,
      investmentMandate: investmentMandateFilePaths,
      fundMangerLicense: fundMangerLicenseFilePaths,
    };

    let investmentPreferences = null;

    if (type === Persona.INVESTOR && investmentAmount) {
      investmentPreferences = {
        investmentSize: investmentAmount,
        investmentMode: InvestmentMode.MANUAL,
      };
    }

    let countryCode = null;

    if (countryOfDomicile && countryOfDomicile in countryCodes) {
      countryCode =
        countryCodes[countryOfDomicile as keyof typeof countryCodes];
    } else {
      getCode(countryOfDomicile);
    }

    setIsLoading(true);
    createOrgmutation.mutate(
      {
        bank,
        token,
        onBoardingUserType: type as string,
        nameOfAuthorisedRepresentiative: `${repFirstName} ${repLastName}`,
        emailOfAuthorisedRepresentiative: repEmail?.toLowerCase(),
        jobTitle: userTitle,
        legalEntityAddress: address as string,
        countryISOCode: countryCode,
        postalCode: parseInt(`${postal}`),
        documentsUpload,
        investmentPreferences,
        companyName: company as string,
        companyRegistrationNo: regNumber as string,
        companyPhoneNumber: phoneNumber as string,
        titleOfAuthorisedRepresentiative: repTitle as string,
        countryOfIncorporation: countryOfIncorp as string,
        website: website as string,
        industryGroup: industryGroup as string,
        industry:
          industry === Industry.OTHERS
            ? (industryOthers as string)
            : (industry as string),
        legalStructure:
          legalStructure === LegalStructure.OTHERS
            ? (legalStructureOthers as string)
            : (legalStructure as string),
        shareHolders: shareholders
          ? shareholders.map((shareholder) => {
              return {
                firstName: shareholder.firstName,
                lastName: shareholder.lastName,
                email: shareholder.email.toLowerCase(),
              };
            })
          : null,
        boardOfDirectors: directors
          ? directors.map((director) => {
              return {
                firstName: director.firstName,
                lastName: director.lastName,
                email: director.email.toLowerCase(),
                isShareholder: director.role === CompanyRole.BOTH,
                isAuthRep:
                  director.email.toLowerCase() === repEmail?.toLowerCase(),
              };
            })
          : null,
        companyStartDate: incorporationDate,
      },
      {
        onSuccess: () => {
          setIsLoading(false);

          if (type === Persona.INVESTOR) {
            if (userEmail === repEmail) {
              Navigate("/identify-me");
            } else {
              Navigate("/thanks-page");
            }
          } else if (type === Persona.ISPV) {
            Navigate("/thanks-page");
          } else {
            Navigate("/identify-me");
          }
        },
        onError: (e) => {
          setIsLoading(false);
          toast.error((e as Error).message);
        },
      }
    );
  };

  return (
    <OnboardingLayout
      showSteps={true}
      step={4}
      content={
        <div>
          <p className={classes.headinfo}>
            All uploads must be in PDF format. The size should not exceed 10 MB
            each
          </p>
          <div className={classes.stepContent} style={{ background: "#fff" }}>
            {documents.map((item) => (
              <FileUploadComponent
                key={item.number}
                number={item.number}
                fileId={item.id}
                FileName={item.heading}
              />
            ))}

            <div className={classes.buttonDiv2}>
              <div className={classes.buttonWrapper}>
                <Button
                  onClick={handleBack}
                  backgroundColor={color.WHITE}
                  textColor={color.FHGREEN}
                  children="Back"
                  border="1px solid #338F86"
                  width="210px"
                />
              </div>
              <div className={classes.buttonWrapper}>
                <Button
                  disabled={isLoading}
                  onClick={handleNext}
                  textColor={color.WHITE}
                  backgroundColor={color.FHGREEN}
                  children="Next"
                  width="210px"
                />
              </div>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default InvestorDocsUpload;
