import { MantineTheme, createStyles } from "@mantine/styles";
export const useStyles = createStyles((theme: MantineTheme) => ({
  container: {
    marginLeft: 50,
  },
  heading: {
    color: "#338F86",
    fontWeight: 800,
    marginTop: 40,
    paddingLeft: 50,
  },
  dividerTop: {
    backgroundColor: "#338F86",
    width: "80%",
    marginLeft: 50,
    height: 20,
    marginTop: 40,
  },
  divider: {
    backgroundColor: "#338F86",
    width: "100%",
    height: 20,
    marginTop: 40,
  },
  contentBox: {
    backgroundColor: "#F1F3F6",
    marginBottom: 10,
    width: "80%",
  },
  contentPadding: {
    margin: 50,
    borderTop: "20px solid #338F86",
    borderBottom: "7px solid #338F86",
    minHeight: "600px",
    height: "800px",
  },
  subTitle: {
    fontWeight: 800,
    marginTop: 20,
    fontSize: 20,
  },
  textSection: {
    marginTop: 25,
  },
  boldText: {
    fontWeight: 800,
  },
  resolutionSection: {
    marginTop: 25,
  },
  listItem: {
    display: "flex",
    flexDirection: "row",
    gap: 5,
    marginTop: 10,
  },
  signSection: {
    marginTop: 25,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  signBox: {
    display: "flex",
    flexDirection: "column",
    gap: 340,
  },
  buttonContainer: {
    margin: "20px 50px",
    paddingBottom: "50px",
  },
  docviewer: {
    "#msdoc-renderer": {
      height: "450px",
    },

    "#pdf-controls": {
      display: "none",
    },
  },
}));
