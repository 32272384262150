import { FC, useState } from "react";
import {
  Button,
  InputGroup,
  InputSelect,
  NAME_REGEX,
  NO_REGEX,
} from "@funded-here-interface/common";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "@funded-here-interface/investor-onboarding/src/store";
import { useDispatch } from "react-redux";
import { countries } from "@funded-here-interface/common/src/constant/country";
import {
  industry as industryOtherOption,
  IndustryGroupMap,
  LegalStructures,
} from "../../constants/dropdownValues";
import {
  setAddressRedux,
  setCompanyRedux,
  setCountryRedux,
  setIndustryGroupRedux,
  setIndustryDropdownValuesRedux,
  setIndustryOthersRedux,
  setLegalStructureOthersRedux,
  setLegalStructureRedux,
  setPostalRedux,
  setRegNumberRedux,
  setTypeOfInstituteRedux,
  setUserFirstNameRedux,
  setUserLastNameRedux,
} from "../../features/distributor/biz";
import { Industry, LegalStructure, IndustryGroup } from "../../constants/enums";
import { camelCaseToNormalCase } from "../../Utils/formatter";

const DistributorBizProfile: FC = () => {
  const Navigate = useNavigate();

  const {
    company: comp,
    regNumber: regno,
    legalStructure: legalStructureRedux,
    legalStructureOthers: legalStructureOthersRedux,
    industryGroup: industryGroupRedux,
    industryDropdownValues: industryDropdownValuesRedux,
    typeOfInstitute,
    industryOthers: industryOthersRedux,
    country: countryRedux,
    address: addressRedux,
    postal: postalRedux,
    userFirstName: userFirstNameRedux,
    userLastName: userLastNameRedux,
  } = useSelector((state: RootState) => state.disBiz);

  const [companyName, setCompany] = useState(comp || "");
  const [registrationNumber, setRegNumber] = useState(regno || "");
  const [legalStructure, setLegStr] = useState(legalStructureRedux || "");
  const [isOtherLegalStructure, setIsOtherLegalStructure] = useState(
    legalStructureRedux === LegalStructure.OTHERS
  );
  const [legalStructureOthers, setLegalStructurOthers] = useState(
    legalStructureOthersRedux || ""
  );
  const [institutionType, setInstitute] = useState(typeOfInstitute || "");
  const [industryGroup, setIndustryGroup] = useState(industryGroupRedux || "");
  const [industryDropdownValues, setIndustryDropdownValues] = useState<
    Industry[]
  >(industryDropdownValuesRedux || []);
  const [isOtherIndustry, setIsOtherIndustry] = useState(
    typeOfInstitute === Industry.OTHERS
  );
  const [industryOthers, setIndustryOthers] = useState(
    industryOthersRedux || ""
  );
  const [country, setCountry] = useState(countryRedux || "");
  const [address, setAddress] = useState(addressRedux || "");
  const [postalcode, setPostalCode] = useState(postalRedux || "");
  const [firstName, setUserFirstName] = useState(userFirstNameRedux || "");
  const [lastName, setUserLastName] = useState(userLastNameRedux || "");

  const errorFields = {
    companyName: "",
    registrationNumber: "",
    legalStructure: "",
    legalStructureOthers: "" as string | undefined,
    industryGroup: "",
    institutionType: "" as string | undefined,
    industryOthers: "" as string | undefined,
    country: "",
    address: "",
    postalcode: "",
    firstName: "",
    lastName: "",
  };

  const [error, setError] = useState(errorFields);

  const dispatch = useDispatch();

  const handleSubmit = () => {
    const customError = { ...error };
    let valid = true;

    // Remove custom message fields from default error fields
    const defaultErrorFields = { ...errorFields };
    delete defaultErrorFields.legalStructureOthers;
    delete defaultErrorFields.institutionType;
    delete defaultErrorFields.industryOthers;

    for (const key in defaultErrorFields) {
      let fieldExists = false;
      try {
        // eval to check if variable exists
        // eslint-disable-next-line no-eval
        fieldExists = eval(key);
      } catch (e) {
        // Do Nothing
      }

      if (!fieldExists) {
        valid = false;
        customError[key as keyof typeof errorFields] =
          camelCaseToNormalCase(key) + ` is required`;
      }
    }

    if (isOtherLegalStructure && !legalStructureOthers) {
      valid = false;
      customError.legalStructureOthers =
        "Legal Structure Specification is required";
    }

    if (!institutionType) {
      valid = false;
      customError.institutionType = "Industry is required";
    }

    if (isOtherIndustry && !industryOthers) {
      valid = false;
      customError.industryOthers = "Industry Specification is required";
    }

    if (valid) {
      dispatch(setCompanyRedux({ company: companyName }));
      dispatch(setRegNumberRedux({ regNumber: registrationNumber }));
      dispatch(setLegalStructureRedux({ legalStructure: legalStructure }));
      dispatch(
        setLegalStructureOthersRedux({
          legalStructureOthers,
        })
      );
      dispatch(
        setIndustryOthersRedux({
          industryOthers,
        })
      );
      dispatch(setCountryRedux({ country: country }));
      dispatch(setAddressRedux({ address: address }));
      dispatch(setIndustryGroupRedux({ industryGroup: industryGroup }));
      dispatch(setIndustryDropdownValuesRedux({ industryDropdownValues }));
      dispatch(setTypeOfInstituteRedux({ typeOfInstitute: institutionType }));
      dispatch(setPostalRedux({ postal: postalcode }));
      dispatch(setUserFirstNameRedux({ userFirstName: firstName.trim() }));
      dispatch(setUserLastNameRedux({ userLastName: lastName.trim() }));

      Navigate("/issuing-spv-bank-details");
    } else {
      setError(customError);
    }
  };

  const onLegalStructureChange = (value: LegalStructure) => {
    if (value === LegalStructure.OTHERS) {
      setIsOtherLegalStructure(true);
    } else {
      setIsOtherLegalStructure(false);
      setLegalStructurOthers("");
    }

    setLegStr(value);
    error.legalStructure = "";
  };

  const onIndustryGroupChange = (value: IndustryGroup) => {
    setIndustryDropdownValues([]);
    setInstitute("");

    let industry: Industry[] = [];

    industry = IndustryGroupMap[value];

    setIndustryGroup(value);
    setIndustryDropdownValues([...industry, ...industryOtherOption]);
  };

  const onIndustryChange = (value: Industry) => {
    if (value === Industry.OTHERS) {
      setIsOtherIndustry(true);
    } else {
      setIsOtherIndustry(false);
      setIndustryOthers("");
    }

    setInstitute(value);
    error.institutionType = "";
  };

  return (
    <div style={{ marginTop: "70px" }}>
      <InputGroup
        isErrored={error.firstName}
        onChange={(value: string) => {
          error.firstName = "";
          setUserFirstName(value.replace(NAME_REGEX, ""));
        }}
        labelText="First Name"
        id="firstName"
        value={firstName}
      />
      <InputGroup
        isErrored={error.lastName}
        onChange={(value: string) => {
          error.lastName = "";
          setUserLastName(value.replace(NAME_REGEX, ""));
        }}
        labelText="Last Name"
        id="lastName"
        value={lastName}
      />
      <InputGroup
        isErrored={error.companyName}
        onChange={(value: any) => {
          error.companyName = "";
          setCompany(value);
        }}
        labelText="Company Name"
        id="company"
        value={companyName}
      />
      <InputGroup
        isErrored={error.registrationNumber}
        onChange={(value: string) => {
          error.registrationNumber = "";
          setRegNumber(value);
        }}
        labelText="Registration Number"
        id="registrationNumber"
        value={registrationNumber}
      />
      <InputSelect
        isErrored={error.legalStructure}
        onChange={onLegalStructureChange}
        labelText="Legal Structure"
        id="legalStructure"
        value={legalStructure}
        data={LegalStructures}
      />
      {isOtherLegalStructure && (
        <InputGroup
          isErrored={error.legalStructureOthers}
          onChange={(value: string) => {
            error.legalStructureOthers = "";
            setLegalStructurOthers(value);
          }}
          labelText="Please Specify Legal Structure"
          id="legalStructureOthers"
          value={legalStructureOthers}
        />
      )}
      <InputSelect
        isErrored={error.industryGroup}
        onChange={onIndustryGroupChange}
        labelText="Industry Group"
        value={industryGroup}
        id="industryGroup"
        data={Object.values(IndustryGroup)}
      />
      <InputSelect
        isErrored={error.institutionType}
        onChange={onIndustryChange}
        labelText="Industry"
        value={institutionType}
        id="institutionType"
        data={industryDropdownValues}
      />
      {isOtherIndustry && (
        <InputGroup
          isErrored={error.industryOthers}
          onChange={(value: string) => {
            error.industryOthers = "";
            setIndustryOthers(value);
          }}
          labelText="Please Specify Industry"
          id="industryOthers"
          value={industryOthers}
        />
      )}
      <InputSelect
        isErrored={error.country}
        onChange={(value: any) => {
          error.country = "";
          setCountry(value);
        }}
        labelText="Country"
        id="country"
        value={country}
        data={countries}
      />

      <InputGroup
        isErrored={error.address}
        onChange={(value: string) => {
          error.address = "";
          setAddress(value);
        }}
        labelText="Address"
        id="address"
        value={address}
      />
      <InputGroup
        isErrored={error.postalcode}
        onChange={(value: any) => {
          error.postalcode = "";
          setPostalCode(value.replace(NO_REGEX, ""));
        }}
        labelText="Postal Code"
        id="postalcode"
        value={postalcode}
      />
      <Button
        onClick={handleSubmit}
        textColor="#FFFF"
        backgroundColor="#338F86"
        children="Next"
        width="100%"
      />
    </div>
  );
};

export default DistributorBizProfile;
