import React, { useEffect, useState } from "react";
import upDollPic from "../Images/upDollPic.png";
import upDownHndPic from "../Images/upDownHndPic.png";
import HttpClientXml from "../../../../Utils/HttpClientXmInv";
import moment from "moment";
import toast from "react-hot-toast";
import { useSelector } from "react-redux";

const GraphAmountSec = () => {
  const [AllData, setAllData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const token = useSelector((state) => state.auth.token);

  const getData = async () => {
    setIsLoading(true);
    try {
      const res = await HttpClientXml.requestData(
        `get-investor-all-detail`,
        {},
        token,
        "GET"
      );
      if (res && res.status) {
        setIsLoading(false);
        setAllData(
          res.data.map((item) => ({
            ...item,
            maturityDate: moment(item.maturityDate).format("DD-MM-YYYY"),
          }))
        );
      } else {
        setAllData([]);
        toast.error(res?.message || "Something Wrong");
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Failed to fetch data");
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <div className="graphAmtSec">
        <div className="graphAmtDiv">
          <div className="graphInner">
            {AllData.map((details, index) => {
              return (
                <>
                  <div className="graphAmtDivInn" key={index}>
                    <h5 className="graphPoint">Total Asset</h5>
                    <p className="graphNum">
                      {details?.totalAssets?.toLocaleString("en-US")}
                    </p>
                  </div>
                  <div className="graphAmtDivInn" key={index}>
                    <h5 className="graphPoint">Cash Balance</h5>
                    <p className="graphNum">
                      {details?.cashBalance?.toLocaleString("en-US")}
                    </p>
                  </div>
                  <div className="graphAmtDivInn" key={index}>
                    <h5 className="graphPoint">
                      Active & Closed Notes Investment
                    </h5>
                    <p className="graphNum">
                      {details?.totalInvestment?.toLocaleString("en-US")}
                    </p>
                  </div>
                </>
              );
            })}
          </div>
          <figure className="graphamtFig">
            <img src={upDollPic} alt="img" />
          </figure>
        </div>

        <div className="graphAmtDiv">
          <div className="graphInner">
            {AllData.map((details, index) => {
              return (
                <>
                  <div className="graphAmtDivInn" key={index}>
                    <h5 className="graphPoint">Invested Notes Count</h5>
                    <p className="graphNum">{details?.totalInvestedSnCount}</p>
                  </div>
                  <div className="graphAmtDivInn" key={index}>
                    <h5 className="graphPoint mb-4">
                      Weighted Average Effective Return
                    </h5>
                    <p className="graphNum">{`${details?.snEffectiveReturn}%`}</p>
                  </div>
                  <div className="graphAmtDivInn" key={index}>
                    <h5 className="graphPoint">Active Notes Investment</h5>
                    <p className="graphNum">
                      {details?.totalActiveInvestment?.toLocaleString("en-US")}
                    </p>
                  </div>
                </>
              );
            })}
          </div>
          <figure className="graphamtFig">
            <img src={upDownHndPic} alt="img" />
          </figure>
        </div>
      </div>
    </>
  );
};

export default GraphAmountSec;
