import { useEffect, FC, useState } from "react";
import { Tabs, Text } from "@mantine/core";
import { useStyles } from "./SkuLoanApplication.styles";
import { useNavigate, useParams } from "react-router-dom";
import LTVAcceptanceSKU from "../LTVacceptance/LTVAcceptanceSKU";
import SFpayment from "../SFpayment/SFpayment";
import LTVAcceptanceSKUConfirm from "../LTVacceptance-Confirmed/LTVAcceptanceSKUConfirm";
import SkuLoanApplicationSubmitted from "./Tabs/Submitted";
import Review from "./Tabs/Review";
import New from "./Tabs/New";

const SkuLoanApplication: FC = () => {
  const { classes } = useStyles();
  const { tabValue } = useParams();
  const [activeTab, setActiveTab] = useState(tabValue || "new");
  const [rerender, setRerender] = useState(true);

  const navigate = useNavigate();

  const handleTabChange = (value: string) => {
    setActiveTab(value);
  };

  const handleTabChangeReload = (value: string) => {
    navigate(`/sku-loan-application/${value}`);
    window.location.reload();
  };

  useEffect(() => {
    navigate(`/sku-loan-application/${activeTab}`);
  }, [activeTab]);

  return (
    <div className={classes.loanApplicationContainer}>
      <Text className={classes.heading}>Loan Application</Text>
      {rerender && (
        <Tabs defaultValue={tabValue || "new"} onTabChange={handleTabChange}>
          <Tabs.List>
            <Tabs.Tab value="new">New</Tabs.Tab>
            <Tabs.Tab value="review">Review</Tabs.Tab>
            <Tabs.Tab value="submitted">Submitted</Tabs.Tab>
            <Tabs.Tab value="indicative-offer">Indicative Offer</Tabs.Tab>
            <Tabs.Tab value="loan-payment"> Deposit</Tabs.Tab>
            <Tabs.Tab value="confirmed">Agreement</Tabs.Tab>
          </Tabs.List>
          <br />

          {activeTab === "new" && (
            <Tabs.Panel value="new">
              <New onEvent={handleTabChangeReload} />
            </Tabs.Panel>
          )}

          {activeTab === "review" && (
            <Tabs.Panel value="review">
              <Review />
            </Tabs.Panel>
          )}

          {activeTab === "submitted" && (
            <Tabs.Panel value="submitted">
              <SkuLoanApplicationSubmitted />
            </Tabs.Panel>
          )}

          {activeTab === "indicative-offer" && (
            <Tabs.Panel value="indicative-offer">
              <LTVAcceptanceSKU onEvent={handleTabChangeReload} />
            </Tabs.Panel>
          )}

          {activeTab === "loan-payment" && (
            <Tabs.Panel value="loan-payment">
              <SFpayment />
            </Tabs.Panel>
          )}

          {activeTab === "confirmed" && (
            <Tabs.Panel value="confirmed">
              <LTVAcceptanceSKUConfirm onEvent={handleTabChangeReload} />{" "}
            </Tabs.Panel>
          )}
        </Tabs>
      )}
    </div>
  );
};

export default SkuLoanApplication;
