import { color } from "@funded-here-interface/common";
import { MantineTheme, createStyles } from "@mantine/styles";

export const useStyle = createStyles((theme: MantineTheme) => ({
  headerText: {
    width: 361,
    height: 46,
    top: 95,
    left: 259,
    fontSize: 24,
    fontWeight: 700,
    lineHeight: "normal",
    letterSpacing: "0em",
    textAlign: "left",
    fontStyle: "normal",
  },
  stepContent: {
    height: 337,
    top: 158,
    left: 490,
    gap: 25,
    paddingBottom: 30,
    marginLeft: "90px",
  },
  optionContainer: {
    display: "flex",
    marginTop: "24px",
    marginBottom: "16px",
  },
  optionTextHeadingDiv: {
    marginRight: "26px",
    cursor: "pointer",
  },
  optionTextHeading: {
    fontWeight: 700,
  },
  uline: {
    width: "40px",
    height: "3px",
    background: color.FHGREEN,
    marginLeft: "0px",
  },
  usline: {
    width: "80px",
    height: "3px",
    background: color.FHGREEN,
    marginLeft: "0px",
  },
  list: {
    marginTop: "3rem",
    border: "black",
    boxShadow: "initial",
    borderRadius: "10px",
    marginBottom: "3rem",
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,0.5)",
    display: " flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  document: {
    background: "white",
    padding: "20px",
    borderRadius: "5px",
    maxHeight: "80vh",
    overflowY: "auto",
    width: "50%",
    height: "100%",
  },
  buttonWrapper: {
    marginTop: "3rem",
  },
  linkText: {
    color: "#338F86",
    fontWeight: 700,
  },
  SubscribeOutter: {
    width: "1000px",
  },
  subscriptionAgreement: {
    display: "flex",
    alignItems: "center",
    marginBottom: "10px",
  },
  aggrementTitle: {
    fontSize: "1.5rem",
    fontWeight: 700,
    width: "400px",
  },
  fundAccount: {
    marginTop: "45px",
  },
  fundAccountText: {
    fontSize: "1.5rem",
    fontWeight: 700,
  },
  paymentDetails: {
    marginTop: "15px",
  },
  pieChart: {
    width: "12.37rem",
    height: "12.37rem",
    marginBottom: "15px",
  },
  pieChartDiv: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginTop: "30px",
  },
  pieItems: {
    display: "flex",
    marginBottom: "10px",
  },
  pieItemColor: {
    width: "2.3rem",
    marginRight: "15px",
  },
  listControl: {
    listStylePosition: "inside",
  },
  buttonContainer: {
    margin: "20px 50px",
    paddingBottom: "50px",
  },
  docviewer: {
    "#msdoc-renderer": {
      height: "450px",
    },

    "#pdf-controls": {
      display: "none",
    },
  },
}));
