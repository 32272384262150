import { useQuery } from "react-query";
import GetSkusToNoteIdMap from "../services/getSkusToNoteIdMap";

function useGetSkusToNoteIdMap(token: string) {
  return useQuery(
    ["get-skus-to-note-id-map", token],
    () =>
      GetSkusToNoteIdMap({
        token,
      }),
    {
      retry: false,
    }
  );
}

export default useGetSkusToNoteIdMap;
