import { MantineTheme, createStyles } from "@mantine/styles";

export const useStyle = createStyles((theme: MantineTheme) => ({
  stepsToComplete: {
    display: "flex",
    flexDirection: "row",
    left: "259px",
  },
  instruction: {
    width: "508px",
    height: "50px",
    top: "140px",
    left: "259px",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "normal",
    fontStyle: "normal",
    letterSpacing: "0em",
    textAlign: "left",
  },
  subHeading: {
    width: "48px",
    height: "19px",

    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "normal",
    letterSpacing: "0em",
    textAlign: "left",
    marginTop: "30px",
  },
  text: {
    height: "19px",
    fontStyle: "normal",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "normal",
    color: "#11A77A",
    marginTop: "15px",
    marginLeft: "25px",
  },
  list: {
    width: "100%",
    height: "19px",
    top: "352px",
    left: "286px",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "normal",
    marginBottom: "8px",
    paddingLeft: "0px",
  },
  progress: {
    //position:'absolute',
    display: "flex",
    flexDirection: "column",
    paddingLeft: "0px",
    position: "absolute",
    left: "10px",
  },
  eclipse: {
    width: "10px",
    height: "10px",
    top: "380px",
    left: "271px",
    marginTop: "8px",
  },
  line: {
    width: "10px",
    height: "20px",
    justifyContent: "center",
    border: "1px",
    rotate: "none",
    paddingLeft: "4px",
  },
  buttonDiv: {
    display: "flex",
    flexDirection: "row",
    width: "250%",
    justifyContent: "start",
    marginTop: "80px",
    msFlexDirection: "row-reverse",
  },
  buttonWrapper: {
    paddingRight: "30px",
  },
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "rgba(0,0,0,0.5)",
    display: " flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
  },
  document: {
    background: "white",
    padding: "20px",
    borderRadius: "5px",
    maxHeight: "80vh",
    overflowY: "auto",
    width: "60%",
    height: "100%",
  },
  download: {
    display: "flex",
    gap: "10px",
    marginTop: "10px",
    alignItems: "center",
    marginBottom: "70px",
  },
  doc: {
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  docSign: {
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    color: "#338F86",
  },
  checkbox: {
    display: "flex",
    gap: "10px",
    height: "25px",
    marginBottom: "50px",
    marginTop: "30px",
  },
  cbox: {
    height: "23px",
    width: "23px",
  },
  ctext: {
    paddingTop: "3px",
  },

  buttonClass: {
    width: "365px",
    height: "45px",
    padding: "13px 87px 13px 87px",
    border: "none",
    gap: "10px",
    fontSize: "16px",

    letterSpacing: "0em",
    textAlign: "center",
  },
  verifyIcon: {
    width: "422px",
    height: "364px",
  },
  verifyOuterContainer: {
    backgroundColor: "#FFFAF2",
    display: "flex",
    width: "1300px",
    alignItems: "center",
  },
  verifyInnerContainer: {
    // position:'absolute',

    zIndex: 12345678987655,
    padding: "40px",
    display: "flex",
    flexDirection: "column",
    marginLeft: "30px",
    textAlign: "left",
  },
  verifyText: {
    fontWeight: 700,
    fontSize: "28px",
    color: "#338F86",
    textAlign: "left",
  },
  verifyPara: {
    fontSize: "16px",
    marginTop: "30px",
    width: "370px",
    textAlign: "left",
  },
  qrcode: {
    marginBottom: "10px",
    marginTop: "10px",
  },
  loading: {
    backgroundColor: "#414244B2",
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loadingContainer: {
    backgroundColor: "#D9DCE1",
    width: "345px",
    height: "237px",
    padding: "30px",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  stepContent: {
    height: 337,
    top: 158,
    left: 370,
    gap: 25,
    paddingBottom: 30,
  },

  headerText: {
    height: 46,
    left: 259,
    fontSize: 24,
    fontWeight: 700,
    lineHeight: "normal",
    letterSpacing: "0em",
    textAlign: "left",
    fontStyle: "normal",
  },
  buttonContainer: {
    margin: "20px 50px",
    paddingBottom: "50px",
  },
  docviewer: {
    "#msdoc-renderer": {
      height: "450px",
    },
    "#pdf-controls": {
      display: "none",
    },
  },
}));
