import { useQuery } from "react-query";
import getClosedPaymentTracking from "../services/getClosedPaymentTracking";

function useGetClosedPaymentTracking(
  token: string,
  limit: string,
  page: string,
  search?: string,
  sortBy?: string,
  sortOrder?: "ASC" | "DESC"
) {
  return useQuery(
    [
      "get-closed-payment-tracking",
      token,
      limit,
      page,
      search,
      sortBy,
      sortOrder,
    ],
    () =>
      getClosedPaymentTracking({
        token,
        limit,
        page,
        search,
        sortBy,
        sortOrder,
      }),
    {
      retry: false,
    }
  );
}

export default useGetClosedPaymentTracking;
