import { FC, useEffect, useMemo, useState } from "react";
import useGetSkuPoOcrReview from "@funded-here-interface/common/src/hooks/useGetSkuPoOcrReview";
import { RootState } from "../../../store";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_RowSelectionState,
  MRT_TableOptions,
  useMantineReactTable,
} from "mantine-react-table";
import { GetSkuPoOcrReviewResponse } from "@funded-here-interface/common/src/services/getSkuPoOcrReview";
import { Button, MantineProvider, Modal, Text } from "@mantine/core";
import { formatNumbersWithThousandSeperators } from "@funded-here-interface/common/src/Utils/formatter";
import { color } from "@funded-here-interface/common";
import useDownloadSkuPoOcrDoc from "@funded-here-interface/common/src/hooks/downloadSkuPoOcr";
import { downloadFile } from "@funded-here-interface/common/src/Utils/file";
import { FileType } from "@funded-here-interface/common/src/constant/enum";
import { validatePoInput } from "@funded-here-interface/common/src/Utils/poInputValidator";
import useDeleteSkuPoOcr from "@funded-here-interface/common/src/hooks/useDeleteSkuPoOcr";
import { useDisclosure } from "@mantine/hooks";
import DeleteModal from "@funded-here-interface/common/src/components/DeleteModal/DeleteModal";
import useSubmitSkuPoOcr from "@funded-here-interface/common/src/hooks/useSubmitSkuPoOcr";
import EditColumn from "@funded-here-interface/common/src/components/EditColumn/EditColumn";
import useUpdateSkuPoOcr from "@funded-here-interface/common/src/hooks/useUpdateSkuPoOcr";
import useEditSkuPoOcrColumn from "@funded-here-interface/common/src/hooks/useEditSkuPoOcrColumn";
import { useStyle } from "./Review.styles";

interface SelectedRows {
  id: number;
  isCompleted: boolean;
}

const SkuLoanApplicationReview: FC = () => {
  const { token, orgRoleId } = useSelector((state: RootState) => state.auth);
  const { data, error, isLoading, refetch } = useGetSkuPoOcrReview(
    token,
    orgRoleId!
  );
  const { classes } = useStyle();
  const [rowSelection, setRowSelection] = useState<MRT_RowSelectionState>({});
  const [selectedRows, setSelectedRows] = useState<SelectedRows[]>([]);
  const useDownloadSkuPoOcrDocMutation = useDownloadSkuPoOcrDoc();
  const [isSubmitLoading, setSubmitLoading] = useState<boolean>(false);
  const [isDeleteLoading, setDeleteLoading] = useState<boolean>(false);
  const useUpdateSkuPoOcrMutation = useUpdateSkuPoOcr();
  const useDeleteSkuPoOcrMutation = useDeleteSkuPoOcr();
  const [opened, { open, close }] = useDisclosure(false);
  const useSubmitSkuPoOcrMutation = useSubmitSkuPoOcr();
  const useEditSkuPoOcrColumnMutation = useEditSkuPoOcrColumn();

  useEffect(() => {
    const selectedIds = Object.keys(rowSelection)
      .filter((key) => rowSelection[key])
      .map(Number);

    const updatedSelectedRows = selectedIds.map((id) => {
      const rowData = data?.find((row) => row.id === id);

      const isCompleted = rowData
        ? [
            "deliveryDate",
            "discount",
            "expectedPaymentDate",
            "nettInvoiceAmount",
            "otherCosts",
            "paymentTerm",
            "poDate",
            "poNumber",
            "purchasePricePerUnit",
            "quantityPurchased",
            "sellingPricePerUnit",
            "skuReturnPercentage",
            "tax",
            "upc",
          ].every(
            (field) => rowData[field as keyof GetSkuPoOcrReviewResponse] != null
          )
        : false;

      return { id, isCompleted };
    });

    setSelectedRows(updatedSelectedRows);
  }, [rowSelection, setSelectedRows, data]);

  if (error) {
    toast.error((error as Error).message);
  }

  const downloadDocument = async (skuId: number) => {
    useDownloadSkuPoOcrDocMutation.mutate(
      { token, skuId: skuId.toString() },
      {
        onSuccess: (data: Blob) => {
          downloadFile(data, "PO", FileType.PDF);
        },
        onError: (e) => {
          toast.error((e as Error).message);
        },
      }
    );
  };

  const handleOnColumnEdit = (
    ids: number[],
    column: string,
    value: string,
    onCallBack: (success: boolean) => void
  ) => {
    const values = {
      [column]: value,
    };
    const { hasErrors, errors } = validatePoInput(values);

    if (hasErrors) {
      for (const error of errors) {
        toast.error(error);
      }
      onCallBack(false);

      return;
    }

    useEditSkuPoOcrColumnMutation.mutate(
      {
        token,
        ids,
        column,
        value,
      },
      {
        onSuccess: () => {
          refetch();
          toast.success(`Column updated successfully`);
          onCallBack(true);
        },
        onError: (e) => {
          toast.error((e as Error).message);
          onCallBack(false);
        },
      }
    );
  };

  const columns = useMemo<MRT_ColumnDef<GetSkuPoOcrReviewResponse>[]>(
    () => [
      {
        accessorKey: "id",
        header: "Uploaded PO",
        enableEditing: false,
        enableSorting: false,
        Cell: ({ row }) => {
          const skuId = row.getValue<number>("id");
          const documentName = row.original.documentName;

          return skuId ? (
            <p className={classes.view} onClick={() => downloadDocument(skuId)}>
              {documentName}
            </p>
          ) : (
            <p>-</p>
          );
        },
      },
      {
        accessorKey: "poNumber",
        Cell: ({ cell }) => <p>{cell.getValue<string>() ?? "-"}</p>,
        header: "PO Number",
        Header: () => (
          <EditColumn
            columnName="Po Number"
            selectedRows={selectedRows.map((row) => row.id)}
            handleOnColumnEdit={(
              value: string,
              onCallBack: (success: boolean) => void
            ) =>
              handleOnColumnEdit(
                selectedRows.map((row) => row.id),
                "poNumber",
                value,
                onCallBack
              )
            }
          />
        ),
      },
      {
        accessorKey: "upc",
        Cell: ({ cell }) => <p>{cell.getValue<string>() ?? "-"}</p>,
        header: "UPC",
        Header: () => (
          <EditColumn
            columnName="UPC"
            selectedRows={selectedRows.map((row) => row.id)}
            handleOnColumnEdit={(
              value: string,
              onCallBack: (success: boolean) => void
            ) =>
              handleOnColumnEdit(
                selectedRows.map((row) => row.id),
                "upc",
                value,
                onCallBack
              )
            }
          />
        ),
      },
      {
        accessorKey: "poDate",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? new Date(cell.getValue<string>()).toISOString().split("T")[0]
              : "-"}
          </p>
        ),
        header: "Po Date",
        Header: () => (
          <EditColumn
            columnName="Po Date"
            selectedRows={selectedRows.map((row) => row.id)}
            handleOnColumnEdit={(
              value: string,
              onCallBack: (success: boolean) => void
            ) =>
              handleOnColumnEdit(
                selectedRows.map((row) => row.id),
                "poDate",
                value,
                onCallBack
              )
            }
          />
        ),
      },
      {
        accessorKey: "deliveryDate",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? new Date(cell.getValue<string>()).toISOString().split("T")[0]
              : "-"}
          </p>
        ),
        header: "Delivery Date",
        Header: () => (
          <EditColumn
            columnName="Delivery Date"
            selectedRows={selectedRows.map((row) => row.id)}
            handleOnColumnEdit={(
              value: string,
              onCallBack: (success: boolean) => void
            ) =>
              handleOnColumnEdit(
                selectedRows.map((row) => row.id),
                "deliveryDate",
                value,
                onCallBack
              )
            }
          />
        ),
      },
      {
        accessorKey: "expectedPaymentDate",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? new Date(cell.getValue<string>()).toISOString().split("T")[0]
              : "-"}
          </p>
        ),
        header: "Expected Payment Date",
        Header: () => (
          <EditColumn
            columnName="Expected Payment Date"
            selectedRows={selectedRows.map((row) => row.id)}
            handleOnColumnEdit={(
              value: string,
              onCallBack: (success: boolean) => void
            ) =>
              handleOnColumnEdit(
                selectedRows.map((row) => row.id),
                "expectedPaymentDate",
                value,
                onCallBack
              )
            }
          />
        ),
      },
      {
        accessorKey: "paymentTerm",
        Cell: ({ cell }) => <p>{cell.getValue<string>() ?? "-"}</p>,
        header: "Payment Term",
        Header: () => (
          <EditColumn
            columnName="Payment Term"
            selectedRows={selectedRows.map((row) => row.id)}
            handleOnColumnEdit={(
              value: string,
              onCallBack: (success: boolean) => void
            ) =>
              handleOnColumnEdit(
                selectedRows.map((row) => row.id),
                "paymentTerm",
                value,
                onCallBack
              )
            }
          />
        ),
      },
      {
        accessorKey: "partialPaymentFirstPaymentPercentage",
        Cell: ({ cell }) => <p>{cell.getValue<string>() ?? "-"}</p>,
        header: "Partial Payment's First Payment %",
        Header: () => (
          <EditColumn
            columnName="Partial Payment's First Payment %"
            selectedRows={selectedRows.map((row) => row.id)}
            handleOnColumnEdit={(
              value: string,
              onCallBack: (success: boolean) => void
            ) =>
              handleOnColumnEdit(
                selectedRows.map((row) => row.id),
                "partialPaymentFirstPaymentPercentage",
                value,
                onCallBack
              )
            }
          />
        ),
      },
      {
        accessorKey: "quantityPurchased",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<number>()
              ? formatNumbersWithThousandSeperators(cell.getValue<number>())
              : "-"}
          </p>
        ),
        header: "Quantity",
        Header: () => (
          <EditColumn
            columnName="Quantity"
            selectedRows={selectedRows.map((row) => row.id)}
            handleOnColumnEdit={(
              value: string,
              onCallBack: (success: boolean) => void
            ) =>
              handleOnColumnEdit(
                selectedRows.map((row) => row.id),
                "quantityPurchased",
                value,
                onCallBack
              )
            }
          />
        ),
      },
      {
        accessorKey: "purchasePricePerUnit",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
        header: "Price Per Unit",
        Header: () => (
          <EditColumn
            columnName="Price Per Unit"
            selectedRows={selectedRows.map((row) => row.id)}
            handleOnColumnEdit={(
              value: string,
              onCallBack: (success: boolean) => void
            ) =>
              handleOnColumnEdit(
                selectedRows.map((row) => row.id),
                "purchasePricePerUnit",
                value,
                onCallBack
              )
            }
          />
        ),
      },
      {
        accessorKey: "nettInvoiceAmount",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
        header: "Total Amount",
        Header: () => (
          <EditColumn
            columnName="Total Amount"
            selectedRows={selectedRows.map((row) => row.id)}
            handleOnColumnEdit={(
              value: string,
              onCallBack: (success: boolean) => void
            ) =>
              handleOnColumnEdit(
                selectedRows.map((row) => row.id),
                "nettInvoiceAmount",
                value,
                onCallBack
              )
            }
          />
        ),
      },
      {
        accessorKey: "sellingPricePerUnit",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
        header: "Selling Price Per Unit",
        Header: () => (
          <EditColumn
            columnName="Selling Price Per Unit"
            selectedRows={selectedRows.map((row) => row.id)}
            handleOnColumnEdit={(
              value: string,
              onCallBack: (success: boolean) => void
            ) =>
              handleOnColumnEdit(
                selectedRows.map((row) => row.id),
                "sellingPricePerUnit",
                value,
                onCallBack
              )
            }
          />
        ),
      },
      {
        accessorKey: "discount",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
        header: "Discount",
        Header: () => (
          <EditColumn
            columnName="Discount"
            selectedRows={selectedRows.map((row) => row.id)}
            handleOnColumnEdit={(
              value: string,
              onCallBack: (success: boolean) => void
            ) =>
              handleOnColumnEdit(
                selectedRows.map((row) => row.id),
                "discount",
                value,
                onCallBack
              )
            }
          />
        ),
      },
      {
        accessorKey: "otherCosts",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
        header: "Other Costs",
        Header: () => (
          <EditColumn
            columnName="Other Costs"
            selectedRows={selectedRows.map((row) => row.id)}
            handleOnColumnEdit={(
              value: string,
              onCallBack: (success: boolean) => void
            ) =>
              handleOnColumnEdit(
                selectedRows.map((row) => row.id),
                "otherCosts",
                value,
                onCallBack
              )
            }
          />
        ),
      },
      {
        accessorKey: "tax",
        Cell: ({ cell }) => (
          <p>
            {cell.getValue<string>()
              ? formatNumbersWithThousandSeperators(
                  parseFloat(cell.getValue<string>())
                )
              : "-"}
          </p>
        ),
        header: "Tax",
        Header: () => (
          <EditColumn
            columnName="Tax"
            selectedRows={selectedRows.map((row) => row.id)}
            handleOnColumnEdit={(
              value: string,
              onCallBack: (success: boolean) => void
            ) =>
              handleOnColumnEdit(
                selectedRows.map((row) => row.id),
                "tax",
                value,
                onCallBack
              )
            }
          />
        ),
      },
      {
        accessorKey: "skuReturnPercentage",
        Cell: ({ cell }) => <p>{cell.getValue<string>() ?? "-"}</p>,
        header: "Return %",
        Header: () => (
          <EditColumn
            columnName="Return %"
            selectedRows={selectedRows.map((row) => row.id)}
            handleOnColumnEdit={(
              value: string,
              onCallBack: (success: boolean) => void
            ) =>
              handleOnColumnEdit(
                selectedRows.map((row) => row.id),
                "skuReturnPercentage",
                value,
                onCallBack
              )
            }
          />
        ),
      },
      {
        accessorKey: "skuReturnMoreThanLimitReason",
        Cell: ({ cell }) => <p>{cell.getValue<string>() ?? "-"}</p>,
        header: "Return Reason",
        Header: () => (
          <EditColumn
            columnName="Return Reason"
            selectedRows={selectedRows.map((row) => row.id)}
            handleOnColumnEdit={(
              value: string,
              onCallBack: (success: boolean) => void
            ) =>
              handleOnColumnEdit(
                selectedRows.map((row) => row.id),
                "skuReturnMoreThanLimitReason",
                value,
                onCallBack
              )
            }
          />
        ),
      },
    ],
    [selectedRows]
  );

  const handleSaveRow: MRT_TableOptions<GetSkuPoOcrReviewResponse>["onEditingRowSave"] =
    async ({ values }) => {
      const { hasErrors, errors } = validatePoInput(values);

      if (hasErrors) {
        for (const error of errors) {
          toast.error(error);
        }

        return;
      }

      if (values.quantityPurchased) {
        values.quantityPurchased = parseInt(values.quantityPurchased);
      }

      const { id, ...skuPoData } = values;

      useUpdateSkuPoOcrMutation.mutate(
        {
          token,
          id,
          ...skuPoData,
        },
        {
          onSuccess: () => {
            table.setEditingRow(null);
            refetch();
          },
          onError: (e) => {
            toast.error((e as Error).message);
          },
        }
      );
    };

  const handleOnSubmit = () => {
    const allCompleted = selectedRows.every((row) => row.isCompleted);

    if (!allCompleted) {
      toast.error(
        `Not all selected UPCs are completed. Please review and complete any missing details before continuing`
      );

      return;
    }

    const ids = selectedRows.map((row) => row.id);

    setSubmitLoading(true);
    useSubmitSkuPoOcrMutation.mutate(
      { token, ids, orgRoleId: orgRoleId! },
      {
        onSuccess: () => {
          toast.success("Submitted Successfully");
          setRowSelection({});
          refetch();
          setSubmitLoading(false);
        },
        onError: (e) => {
          toast.error((e as Error).message);
          setSubmitLoading(false);
        },
      }
    );
  };

  const handleOnDelete = () => {
    const ids = selectedRows.map((row) => row.id);

    setDeleteLoading(true);
    useDeleteSkuPoOcrMutation.mutate(
      { token, ids },
      {
        onSuccess: () => {
          close();
          toast.success("Deleted Successfully");
          setRowSelection({});
          refetch();
          setDeleteLoading(false);
        },
        onError: (e) => {
          toast.error((e as Error).message);
          setDeleteLoading(false);
        },
      }
    );
  };

  const table = useMantineReactTable({
    enableColumnActions: false,
    onRowSelectionChange: setRowSelection,
    getRowId: (row) => row?.id?.toString(),
    enableColumnFilters: false,
    enableRowSelection: true,
    paginationDisplayMode: "pages",
    state: { isLoading, rowSelection },
    columns,
    data: data ?? [],
    initialState: { density: "xs" },
    mantineTableProps: {
      withBorder: true,
    },
    editDisplayMode: "row",
    enableEditing: true,
    onEditingRowSave: handleSaveRow,
  });

  return (
    <div className={classes.wrapper}>
      <Text className={classes.header}>
        Please review the extracted data carefully. Make any necessary edits
        directly in the table and select the rows you wish to submit.
      </Text>
      <MantineProvider theme={{ cursorType: "pointer" }}>
        <MantineReactTable table={table} />
      </MantineProvider>
      <Button
        loading={isSubmitLoading}
        disabled={selectedRows.length === 0 || isDeleteLoading}
        onClick={handleOnSubmit}
        sx={{
          width: "200px",
          height: "50px",
          marginTop: "20px",
          backgroundColor: color.FHGREEN,
          "&:hover": {
            backgroundColor: color.FHGREEN,
          },
        }}
      >
        {isSubmitLoading ? "Submitting" : "Submit"}
      </Button>
      <Button
        loading={isDeleteLoading}
        disabled={selectedRows.length === 0 || isSubmitLoading}
        onClick={open}
        sx={{
          marginLeft: "20px",
          width: "200px",
          height: "50px",
          marginTop: "20px",
          backgroundColor: color.REJECT,
          "&:hover": {
            backgroundColor: color.REJECT,
          },
        }}
      >
        {isDeleteLoading ? "Deleting" : "Delete"}
      </Button>
      <Modal
        opened={opened}
        onClose={close}
        size="auto"
        withCloseButton={false}
      >
        <DeleteModal
          title={`You are about to delete ${selectedRows.length} UPCs. Do you want to proceed?`}
          isDeleteLoading={isDeleteLoading}
          handleOnDelete={handleOnDelete}
          close={close}
        />
      </Modal>
    </div>
  );
};

export default SkuLoanApplicationReview;
