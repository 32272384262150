import { FC, useEffect, useState } from "react";
import {
  Button,
  InputGroup,
  InputSelect,
  NO_REGEX,
  PhoneInputGroup,
  color,
} from "@funded-here-interface/common";
import { useNavigate } from "react-router-dom";
import { useStyles } from "../../styles/BizProfileCss";
import { useSelector } from "react-redux";
import { RootState } from "@funded-here-interface/investor-onboarding/src/store";
import { useDispatch } from "react-redux";
import {
  setCompanyRedux,
  setRegNumberRedux,
  setLegalStructureRedux,
  setAddressRedux,
  setPhoneNumberRedux,
  setCountryOfIncorpRedux,
  setLegalStructureOthersRedux,
  setCountryOfDomicileRedux,
  setPostalCodeRedux,
  setWebsiteRedux,
  setIndustryOthersRedux,
  setIndustryGroupRedux,
  setIndustryDropdownValuesRedux,
  setIndustryRedux,
  setInvestmentAmountRedux,
  setIncorporationDateRedux,
} from "../../features/institutionalInvestor/bizSlice";
import {
  countries,
  countryCodes,
  CountryCode,
} from "@funded-here-interface/common/src/constant/country";
import {
  LegalStructures,
  industry as industryOtherOption,
  IndustryGroupMap,
} from "../../constants/dropdownValues";
import { Industry, IndustryGroup, LegalStructure } from "../../constants/enums";
import OnboardingLayout from "../../layouts/OnboardingLayout/OnboardingLayout";
import { Persona } from "@funded-here-interface/common/src/constant/enum";
import { useDisclosure } from "@mantine/hooks";
import InvestorDeclarationModal from "./Components/InvestorDeclarationModal/InvestorDeclarationModal";
import { toast } from "react-toastify";
import { camelCaseToNormalCase } from "../../Utils/formatter";

const BizProfile: FC = () => {
  const { classes } = useStyles();
  const Navigate = useNavigate();
  const type = useSelector((state: RootState) => state.auth.type);

  const {
    company: comp,
    regNumber: reg,
    legalStructure: ls,
    legalStructureOthers: legalStructureOthersRedux,
    countryOfDomicile: countryOfDomicileRedux,
    countryOfIncorp: countryOfIncorpRedux,
    address: add,
    phoneNumber: pn,
    website: websiteRedux,
    postal: postalRedux,
    industryGroup: industryGroupRedux,
    industryDropdownValues: industryDropdownValuesRedux,
    industry: industryRedux,
    industryOthers: industryOthersRedux,
    investmentAmount: investmentAmountRedux,
    investorDeclarationQualified,
    incorporationDate: incorpDateRedux,
  } = useSelector((state: RootState) => state.biz);

  const isInvestor = type === Persona.INVESTOR;
  const [opened, { close }] = useDisclosure(
    isInvestor && !investorDeclarationQualified
  );
  const [industryGroup, setIndustryGroup] = useState(industryGroupRedux || "");
  const [industryDropdownValues, setIndustryDropdownValues] = useState<
    Industry[]
  >(industryDropdownValuesRedux || []);
  const [industry, setIndustry] = useState(industryRedux || "");
  const [isOtherIndustry, setIsOtherIndustry] = useState(
    industryRedux === Industry.OTHERS
  );
  const [industryOthers, setIndustryOthers] = useState(
    industryOthersRedux || ""
  );

  const [companyName, setCompany] = useState(comp || "");
  const [registrationNumber, setRegNumber] = useState(reg || "");
  const [legalStructure, setLegStr] = useState(ls || "");
  const [isOtherLegalStructure, setIsOtherLegalStructure] = useState(
    legalStructure === LegalStructure.OTHERS
  );
  const [legalStructureOthers, setLegalStructurOthers] = useState(
    legalStructureOthersRedux || ""
  );
  const [countryOfIncorporation, setCountryCountryOfIncorp] = useState(
    countryOfIncorpRedux || ""
  );
  const [countryOfDomicile, setCountryCountryOfDomicile] = useState(
    countryOfDomicileRedux || ""
  );
  const [address, setAddress] = useState(add || "");
  const [phoneNumber, setPhone] = useState(pn || "");
  const [postalCode, setPostalCode] = useState(postalRedux || "");
  const [website, setWebsite] = useState(websiteRedux || "");
  const [countryCode, setCountryCode] = useState<CountryCode | undefined>();
  const [investmentAmount, setInvestmentAmount] = useState(
    investmentAmountRedux || 0
  );
  const [incorporationDate, setIncorporationDate] = useState(
    incorpDateRedux || ""
  );

  const errorFields = {
    companyName: "",
    registrationNumber: "",
    legalStructure: "",
    legalStructureOthers: "" as string | undefined,
    countryOfIncorporation: "",
    countryOfDomicile: "",
    address: "",
    postalCode: "",
    website: "",
    phoneNumber: "",
    industryGroup: "",
    industry: "",
    industryOthers: "" as string | undefined,
    investmentAmount: "" as string | undefined,
    incorporationDate: "",
  };

  // Remove custom message fields from default error fields
  const defaultErrorFields = { ...errorFields };
  delete defaultErrorFields.legalStructureOthers;
  delete defaultErrorFields.industryOthers;
  delete defaultErrorFields.investmentAmount;

  const [error, setError] = useState(errorFields);
  const dispatch = useDispatch();

  useEffect(() => {
    if (investorDeclarationQualified === false) {
      Navigate("/unqualified-investor");
    }
  }, [investorDeclarationQualified]);

  const handleSubmit = () => {
    const customError = { ...error };
    let valid = true;

    for (const key in defaultErrorFields) {
      let fieldExists = false;
      try {
        // eval to check if variable exists
        // eslint-disable-next-line no-eval
        fieldExists = eval(key);
      } catch (e) {
        // Do Nothing
      }

      if (!fieldExists) {
        valid = false;
        customError[key as keyof typeof errorFields] =
          camelCaseToNormalCase(key) + ` is required`;
      }
    }

    if (isOtherLegalStructure && !legalStructureOthers) {
      valid = false;
      customError.legalStructureOthers =
        "Legal Structure Specification is required";
    }

    if (isOtherIndustry && !industryOthers) {
      valid = false;
      customError.industryOthers = "Industry Specification is required";
    }

    if (type === Persona.INVESTOR && !investmentAmount) {
      valid = false;
      customError.investmentAmount = "Planned Investment Amount is required";
    }

    if (valid) {
      dispatch(setCompanyRedux({ company: companyName }));
      dispatch(setRegNumberRedux({ regNumber: registrationNumber }));
      dispatch(setLegalStructureRedux({ legalStructure: legalStructure }));
      dispatch(setLegalStructureOthersRedux({ legalStructureOthers }));
      dispatch(
        setCountryOfIncorpRedux({ countryOfIncorp: countryOfIncorporation })
      );
      dispatch(setCountryOfDomicileRedux({ countryOfDomicile }));
      dispatch(setAddressRedux({ address }));
      dispatch(setPostalCodeRedux({ postal: postalCode }));
      dispatch(setWebsiteRedux({ website }));
      dispatch(setPhoneNumberRedux({ phoneNumber: phoneNumber }));
      dispatch(setIndustryGroupRedux({ industryGroup }));
      dispatch(setIndustryDropdownValuesRedux({ industryDropdownValues }));
      dispatch(setIndustryRedux({ industry }));
      dispatch(
        setIndustryOthersRedux({
          industryOthers,
        })
      );
      dispatch(
        setInvestmentAmountRedux({
          investmentAmount,
        })
      );
      dispatch(setIncorporationDateRedux({ incorporationDate }));

      if (type === Persona.INVESTOR) {
        Navigate("/institutional-rep");
      } else {
        Navigate("/representative-details");
      }
    } else {
      setError(customError);
    }
  };

  const handleCountryOfDomicileChange = (value: string) => {
    error.countryOfDomicile = "";
    setCountryCountryOfDomicile(value);
    const code = countryCodes[value as keyof typeof countryCodes];
    setCountryCode(code as CountryCode);
  };

  const onLegalStructureChange = (value: LegalStructure) => {
    if (value === LegalStructure.OTHERS) {
      setIsOtherLegalStructure(true);
    } else {
      setIsOtherLegalStructure(false);
      setLegalStructurOthers("");
    }

    setLegStr(value);
    error.legalStructure = "";
  };

  const onIndustryGroupChange = (value: IndustryGroup) => {
    setIndustryDropdownValues([]);
    setIndustry("");

    let industry: Industry[] = [];

    industry = IndustryGroupMap[value];

    setIndustryGroup(value);
    setIndustryDropdownValues([...industry, ...industryOtherOption]);
  };

  const onIndustryChange = (value: Industry) => {
    if (value === Industry.OTHERS) {
      setIsOtherIndustry(true);
    } else {
      setIsOtherIndustry(false);
      setIndustryOthers("");
    }

    setIndustry(value);
    error.industry = "";
  };

  return (
    <OnboardingLayout
      step={1}
      showSteps={true}
      content={
        <>
          <InvestorDeclarationModal
            onError={(value: string) => {
              toast.error(value);
            }}
            opened={opened}
            close={close}
          />
          <div style={{ marginTop: "70px" }}>
            <InputGroup
              isErrored={error?.companyName}
              onChange={(value: string) => {
                error.companyName = "";
                setCompany(value);
              }}
              labelText="Company Name"
              id="companyName"
              value={companyName}
            />
            <InputGroup
              isErrored={error?.registrationNumber}
              onChange={(value: string) => {
                error.registrationNumber = "";
                setRegNumber(value);
              }}
              labelText="Registration Number"
              id="registrationNumber"
              value={registrationNumber}
            />

            <InputGroup
              isErrored={error?.incorporationDate}
              onChange={(value: any) => {
                error.incorporationDate = "";
                setIncorporationDate(value);
              }}
              labelText="Date of incorporation"
              value={incorporationDate}
              id="incorporationDate"
              type="date"
            />
            <InputSelect
              isErrored={error?.legalStructure}
              onChange={onLegalStructureChange}
              labelText="Legal Structure"
              id="legalStructure"
              value={legalStructure}
              data={LegalStructures}
            />
            {isOtherLegalStructure && (
              <InputGroup
                isErrored={error.legalStructureOthers}
                onChange={(value: string) => {
                  error.legalStructureOthers = "";
                  setLegalStructurOthers(value);
                }}
                labelText="Please Specify Legal Structure"
                id="legalStructureOthers"
                value={legalStructureOthers}
              />
            )}
            <InputSelect
              isErrored={error.industryGroup}
              onChange={onIndustryGroupChange}
              labelText="Industry Group"
              value={industryGroup}
              id="industryGroup"
              data={Object.values(IndustryGroup)}
            />
            <InputSelect
              isErrored={error.industry}
              onChange={onIndustryChange}
              labelText="Industry"
              value={industry}
              id="industry"
              data={industryDropdownValues}
            />
            {isOtherIndustry && (
              <InputGroup
                isErrored={error.industryOthers}
                onChange={(value: string) => {
                  error.industryOthers = "";
                  setIndustryOthers(value);
                }}
                labelText="Please Specify Industry"
                id="industryOthers"
                value={industryOthers}
              />
            )}
            <InputSelect
              isErrored={error?.countryOfIncorporation}
              onChange={(value: string) => {
                error.countryOfIncorporation = "";
                setCountryCountryOfIncorp(value);
              }}
              labelText="Country of Incorporation"
              id="countryOfIncorporation"
              value={countryOfIncorporation}
              data={countries}
            />
            <InputSelect
              isErrored={error?.countryOfDomicile}
              onChange={handleCountryOfDomicileChange}
              labelText="Country of Domicile"
              id="countryOfDomicile"
              value={countryOfDomicile}
              data={countries}
            />
            <InputGroup
              isErrored={error?.address}
              onChange={(value: string) => {
                error.address = "";
                setAddress(value);
              }}
              labelText="Address"
              id="address"
              value={address}
            />
            <InputGroup
              isErrored={error.postalCode}
              onChange={(value: string) => {
                error.postalCode = "";
                setPostalCode(value.replace(NO_REGEX, ""));
              }}
              labelText="Postal Code"
              id="postalcode"
              value={postalCode}
            />
            <InputGroup
              isErrored={error.website}
              onChange={(value: string) => {
                error.website = "";
                setWebsite(value);
              }}
              labelText="Website"
              id="companywebsite"
              value={website}
            />
            {isInvestor && (
              <InputGroup
                isErrored={error.investmentAmount}
                onChange={(value: string) => {
                  error.investmentAmount = "";
                  setInvestmentAmount(parseInt(value.replace(NO_REGEX, "")));
                }}
                labelText="Planned Investment Amount"
                id="investmentAmount"
                value={`${investmentAmount || 0}`}
              />
            )}
            <div className={classes.phoneNumberWrapper}>
              <PhoneInputGroup
                isErrored={error?.phoneNumber}
                value={phoneNumber}
                country={countryCode}
                onChange={(value: string) => {
                  error.phoneNumber = "";
                  setPhone(value);
                }}
              />
            </div>
            <div className={classes.buttonWrapper}>
              <Button
                onClick={handleSubmit}
                textColor={color.WHITE}
                backgroundColor={color.FHGREEN}
                children="Next"
                width="100%"
              />
            </div>
          </div>
        </>
      }
    />
  );
};

export default BizProfile;
