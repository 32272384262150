import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import AuthServices from "../../../services/AuthServices";

const HorizontalBar = () => {
  const [chartHeight, setChartHeight] = useState(0);

  const token = useSelector((state) => state.auth.token);

  const [chartData, setChartData] = useState({
    series: [],
    options: {
      chart: {
        width: "100%",
        type: "waterfall",
        stacked: true,
        toolbar: {
          show: false,
        },
        zoom: {
          enabled: false,
        },
      },
      plotOptions: {
        bar: {
          horizontal: true,
          barHeight: "60%",
          barWidth: "10%",
          dataLabels: {
            hideOverflowingLabels: false,
          },
        },
      },
      dataLabels: {
        style: {
          colors: ["#151515"],
          fontSize: "12px",
          fontWeight: "600",
        },
        formatter: function (val) {
          return val === 0 ? "" : val;
        },
      },
      colors: ["#f0e199", "#92bbac"],
      states: {
        hover: {
          filter: {
            type: "none",
          },
        },
      },
      title: {
        text: "Amount Due Analysis",
        style: {
          fontSize: "8px",
          colors: "#5a5a5a",
        },
      },
      xaxis: {
        labels: {
          style: {
            fontSize: "8px",
          },
        },
      },
      fill: {
        opacity: 1,
      },
      legend: {
        position: "top",
        fontSize: "8px",
        fontWeight: "500",
        horizontalAlign: "left",
        markers: {
          shape: "circle",
          width: "8px",
          height: "8px",
          borderRadius: "50%",
        },
      },
      grid: {
        xaxis: {
          lines: {
            show: false,
          },
        },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      tooltip: {
        enabled: true,
        followCursor: true,
        style: {
          fontSize: "8px",
          fontWeight: "400",
        },
        custom: function ({ series, seriesIndex, dataPointIndex, w }) {
          const totalLoanAmount = (
            series[0][dataPointIndex] + series[1][dataPointIndex]
          ).toFixed(2);

          if (seriesIndex === 0) {
            // For the first part
            return (
              '<div class="arrow_box">' +
              '<span class="headspan">' +
              "SKU : " +
              "</span>" +
              '<span class="paraspan">' +
              w.globals.labels[dataPointIndex] +
              "</span>" +
              "</div>" +
              '<div class="arrow_box">' +
              '<span class="headspan">' +
              "Amount Due : " +
              "</span>" +
              '<span class="paraspan">' +
              series[seriesIndex][dataPointIndex] +
              "</span>" +
              "</div>" +
              '<div class="arrow_box">' +
              '<span class="headspan">' +
              "Total Loan Amount : " +
              "</span>" +
              '<span class="paraspan">' +
              totalLoanAmount +
              "</span>" +
              "</div>"
            );
          } else {
            return (
              '<div class="arrow_box">' +
              '<span class="headspan">' +
              "SKU : " +
              "</span>" +
              '<span class="paraspan">' +
              w.globals.labels[dataPointIndex] +
              "</span>" +
              "</div>" +
              '<div class="arrow_box">' +
              '<span class="headspan">' +
              "Repaid Amount : " +
              "</span>" +
              '<span class="paraspan">' +
              series[seriesIndex][dataPointIndex] +
              "</span>" +
              "</div>" +
              '<div class="arrow_box">' +
              '<span class="headspan">' +
              "Total Loan Amount : " +
              "</span>" +
              '<span class="paraspan">' +
              totalLoanAmount +
              "</span>" +
              "</div>"
            );
          }
        },
      },
    },
  });

  useEffect(() => {
    fetchAmountDueData();
  }, []);

  //    Amount due

  const fetchAmountDueData = async () => {
    try {
      const res = await AuthServices.amountdue(token);
      if (res && res.status) {
        const sortedData = res.data.sort(
          (a, b) => b.balance_amount - a.balance_amount
        );
        const chartHeight = Math.max(sortedData.length * 70, 150);
        setChartHeight(chartHeight);
        const series = [
          {
            name: "Amount Due",
            data: sortedData.map((item) => ({
              x: `${item.skuId}`,
              y: item.balance_amount,
            })),
          },
          {
            name: "Repaid Amount",
            data: sortedData.map((item) => ({
              x: `${item.skuId}`,
              y: item.rapidAmmout,
            })),
          },
        ];
        // Update state to include sorted data for all SKUs
        setChartData((prevChartData) => ({
          ...prevChartData,
          series,
          options: {
            ...prevChartData.options,
            xaxis: {
              ...prevChartData.options.xaxis,
              categories: sortedData.map((item) => `${item.description}`),
            },
            yaxis: {
              ...prevChartData.options.yaxis,
              title: {
                text: "SKU Name",
                style: {
                  fontSize: "12px",
                  fontWeight: "600",
                },
              },
            },
          },
        }));
      }
    } catch (error) {
      console.error("Error fetching amount due data:", error);
    }
  };

  return (
    <>
      <div className="overallchart">
        {/* Amount due analysis */}
        <div className="overallChartTop">
          <ReactApexChart
            options={chartData.options}
            series={chartData.series}
            height={chartHeight}
            type="bar"
          />
        </div>
      </div>
    </>
  );
};

export default HorizontalBar;
