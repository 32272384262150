import { Button } from "@funded-here-interface/common";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@funded-here-interface/investor-onboarding/src/store";
import { useStyle } from "../../styles/BorrowerDocUpload";
import { data } from "./Data";
import useCreateOrg from "../../hooks/useCreateOrg";
import { Bank } from "../../types/org.type";
import { Industry, LegalStructure } from "../../constants/enums";
import { getCode } from "country-list";
import {
  CompanyRole,
  Persona,
} from "@funded-here-interface/common/src/constant/enum";
import { DocumentsUpload } from "@funded-here-interface/common/src/constant/type";
import FileUploadComponent from "../../components/FileUpload/FileUpload";
import { countryCodes } from "@funded-here-interface/common/src/constant/country";
import {
  setUserFirstName,
  setUserLastName,
} from "../../features/auth/authSlice";

const BorrowerDocsUpload: FC = () => {
  const { classes } = useStyle();
  const dispatch = useDispatch();
  const { token, userEmail } = useSelector((state: RootState) => state.auth);
  const [isLoading, setLoading] = useState(false);
  const {
    company,
    legalStructure,
    legalStructureOthers,
    regNumber,
    industryGroup,
    typeOfInstitute,
    industryOthers,
    countryOfDomicile,
    countryOfIncorp,
    postal,
    address,
    website,
    turnover,
    phoneNumber,
  } = useSelector((state: RootState) => state.borBiz);

  const {
    repFirstName,
    repLastName,
    userFirstName,
    userLastName,
    repTitle,
    shareholdersDirectors,
    merchantTitle,
    repEmail,
  } = useSelector((state: RootState) => state.Borrep);

  const shareholders = shareholdersDirectors.filter(
    (user) => user.role === CompanyRole.SHAREHOLDER
  );
  const directors = shareholdersDirectors.filter(
    (user) =>
      user.role === CompanyRole.BOTH || user.role === CompanyRole.DIRECTOR
  );

  const { accountType, bankName, accountNumber, swiftCode, accountHolderName } =
    useSelector((state: RootState) => state.BorBank);

  const {
    business,
    share,
    bank: bankDoc,
    balance,
    proof,
  } = useSelector((state: RootState) => state.bordoc);

  const createOrgmutation = useCreateOrg();

  const Navigate = useNavigate();
  const handleBack = () => {
    Navigate("/bor-bank");
  };

  const handleNext = async () => {
    const missingFiles = [];

    if (business.length === 0) missingFiles.push("business profile file");
    if (share.length === 0)
      missingFiles.push("shareholder and directors registers file");
    if (bankDoc.length === 0) missingFiles.push("bank statement file");
    if (proof.length === 0) missingFiles.push("proof of address file");
    if (balance.length === 0)
      missingFiles.push("balance sheet & P&L statement file");

    if (missingFiles.length > 0) {
      toast.error(`Please upload ${missingFiles.join(", ")}`);

      return;
    }

    const bank: Bank = {
      bankName: bankName! as string,
      swiftCode: swiftCode! as string,
      accountNo: accountNumber! as string,
      accountType: (accountType! as string).toLowerCase(),
      acountHolderName: accountHolderName! as string,
    };

    const proofFilePaths = proof.map((f) => f.path);
    const businessFilePaths = business.map((f) => f.path);
    const shareFilePaths = share.map((f) => f.path);
    const bankFilePaths = bankDoc.map((f) => f.path);
    const balanceFilePaths = balance.map((f) => f.path);

    const documentsUpload: DocumentsUpload = {
      proofOfAddress: proofFilePaths,
      businessProfile: businessFilePaths,
      shareHoldersDirectorsRegisters: shareFilePaths,
      bankStatement: bankFilePaths,
      balanceSheetPAndLStatement: balanceFilePaths,
    };

    let countryCode = null;

    if (countryOfDomicile && countryOfDomicile in countryCodes) {
      countryCode = countryCodes[countryOfDomicile];
    } else {
      getCode(countryOfDomicile);
    }

    const onboardingType =
      countryOfDomicile !== "Singapore" || countryOfIncorp !== "Singapore"
        ? Persona.MERCHANT_SPV
        : Persona.MERCHANT;

    dispatch(
      setUserFirstName({
        userFirstName: userFirstName ?? null,
      })
    );
    dispatch(
      setUserLastName({
        userLastName: userLastName ?? null,
      })
    );

    setLoading(true);
    createOrgmutation.mutate(
      {
        token,
        bank,
        documentsUpload,
        firstName: userFirstName!,
        lastName: userLastName!,
        jobTitle: merchantTitle!,
        companyName: company!,
        companyRegistrationNo: regNumber!,
        legalStructure:
          legalStructure === LegalStructure.OTHERS
            ? legalStructureOthers!
            : legalStructure!,
        industryGroup: industryGroup!,
        industry:
          typeOfInstitute === Industry.OTHERS
            ? industryOthers!
            : typeOfInstitute!,
        countryISOCode: countryCode,
        countryOfIncorporation: countryOfIncorp!,
        companyPhoneNumber: phoneNumber!,
        nameOfAuthorisedRepresentiative: `${repFirstName} ${repLastName}`,
        legalEntityAddress: address!,
        postalCode: parseInt(`${postal}`),
        titleOfAuthorisedRepresentiative: repTitle!,
        emailOfAuthorisedRepresentiative: repEmail?.toLowerCase()!,
        onBoardingUserType: onboardingType,
        previousYearClosingInventory: turnover!,
        website: website!,
        shareHolders: shareholders.map((shareholder) => {
          return {
            firstName: shareholder.firstName,
            lastName: shareholder.lastName,
            email: shareholder.email.toLowerCase(),
          };
        }),
        boardOfDirectors: directors.map((director) => {
          return {
            firstName: director.firstName,
            lastName: director.lastName,
            email: director.email.toLowerCase(),
            isShareholder: director.role === CompanyRole.BOTH,
            isAuthRep: director.email.toLowerCase() === repEmail?.toLowerCase(),
          };
        }),
      },
      {
        onSuccess: () => {
          setLoading(false);

          if (userEmail === repEmail) {
            Navigate("/borrower-identify-me");
          } else {
            Navigate("/thanks-page");
          }
        },
        onError: (e) => {
          setLoading(false);
          toast.error((e as Error).message);
        },
      }
    );
  };

  return (
    <>
      <div className={classes.topDiv}>
        <p className={classes.headinfo}>
          All uploads must be in PDF format. The size should not exceed 10 MB
          each
        </p>
      </div>

      <div
        className={classes.stepContent}
        style={{ marginTop: "0px", background: "#fff" }}
      >
        {data.map((item) => (
          <FileUploadComponent
            key={item.number}
            number={item.number}
            fileId={item.id}
            FileName={item.heading}
          />
        ))}

        <div className={classes.buttonDiv2}>
          <div className={classes.buttonWrapper}>
            <Button
              onClick={() => handleBack()}
              backgroundColor="#ffff"
              textColor="#338F86"
              children="Back"
              border="1px solid #338F86"
              width="210px"
            />
          </div>
          <div className={classes.buttonWrapper}>
            <Button
              onClick={handleNext}
              disabled={isLoading}
              textColor="#ffff"
              backgroundColor="#338F86"
              children="Submit"
              width="210px"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default BorrowerDocsUpload;
