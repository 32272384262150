import { useQuery } from "react-query";
import getMerchantDashboardOverall from "../services/getMerchantDashboardOverall";

function useMerchantDashboardOverall(token: string) {
  return useQuery(
    ["get-merchant-dashboard-overall", token],
    () => getMerchantDashboardOverall({ token }),
    {
      retry: false,
    }
  );
}

export default useMerchantDashboardOverall;
