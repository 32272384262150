import { Center, Loader, Text } from "@mantine/core";
import { FC, useEffect, useRef, useState } from "react";
import { Button } from "@funded-here-interface/common";
import { useStyle } from "./LoanApplication.styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { COLORS } from "../../constants/colors";
import { useLoanSign } from "../../hooks/useDocuSign";
import { REST_SERVER, WEB_DOMAIN } from "../../constants/domain";
import axios from "axios";
import {
  BASE_URL,
  ENV,
} from "@funded-here-interface/common/src/constant/constant";
import useGetSkusPendingMerchantSigning from "../../hooks/useGetSkusPendingMerchantSigning";
import { DocuSignEvent, DocuSignEventHandler } from "../../constants/docuSign";
import { getApiResponseErrorMsg } from "@funded-here-interface/common/src/Utils/apiHelper";
import VQRVerifyPage from "../../components/VQR/VQRVerifyPage";
import { useNavigate } from "react-router-dom";
import SignQRModal from "../../components/SignQRModal/SignQRModal";
import { useDisclosure } from "@mantine/hooks";
import DocViewer from "@cyntler/react-doc-viewer";
import useSignedDocument from "../../hooks/useSignedDocument";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

const LoanApplication: FC = () => {
  const { classes } = useStyle();
  const [isLoading, setIsLoading] = useState(false);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const docuSignMutation = useLoanSign();
  const useGetSkusPendingMerchantSigningMutation =
    useGetSkusPendingMerchantSigning();
  const [skuIds, setSkuIds] = useState<any>([]);
  const navigate = useNavigate();
  const [realUrl, setRealUrl] = useState("");
  const [documentId, setDocumentId] = useState(0);
  const [opened, { open, close }] = useDisclosure(false);
  const [completedAt, setCompletedAt] = useState("");
  const signedDocument = useSignedDocument();
  const docs = [
    {
      uri: realUrl,
      fileType: "pdf",
    },
  ];

  const [viewerKey, setViewerKey] = useState(0);

  const { token, hasCreditLimit } = useSelector(
    (state: RootState) => state.auth
  );

  useEffect(() => {
    // loadDocuSignScript();
    setIsLoading(true);
    useGetSkusPendingMerchantSigningMutation.mutate(
      { token },
      {
        onSuccess: (data) => {
          const skuIds = data.map((sku: any) => sku.id.toString());
          setSkuIds(skuIds);
          setIsLoading(false);
        },
        onError: (e) => {
          setIsLoading(false);
          toast.error((e as Error).message);
        },
      }
    );
  }, []);
  const handleAuthenticatedChange = (isAuthenticated: boolean) => {
    setIsAuthenticated(isAuthenticated);

    if (isAuthenticated) {
      handleOK();
    }
  };
  const handleOnVerifyMeNext = () => {
    setIsAuthenticated(true);
  };

  const handleOK = async () => {
    toast.info("Document is loading");
    setIsOverlayVisible(true);

    docuSignMutation.mutate(
      {
        token,
        poolingSkuIds: skuIds,
        webDomain: WEB_DOMAIN,
        redirectUrl: `${REST_SERVER}/document-management/docusign/webhook`,
      },
      {
        onSuccess: async (data) => {
          setRealUrl(data.url);
          setDocumentId(data.documentId);
          setCompletedAt(data.completedAt);
          // }
        },
        onError: (e) => {
          toast.error((e as Error).message);
          setIsOverlayVisible(false);
        },
      }
    );
  };

  const handleLoanAgreementSigned = async () => {
    submitAgreements()
      .then(() => {
        toast.success("Document Signed Successfully");
        // if (ENV === "production") {
        //   setIsOverlayVisible(false);

        //   if (hasCreditLimit) {
        //     navigate("/sku-loan-application/confirmed");
        //   } else {
        //     navigate("/loan-agreement");
        //   }
        // }
      })
      .catch((error) => {
        toast.error("Unable to submit agreements");
      });
  };

  const navigateNext = () => {
    if (hasCreditLimit) {
      navigate("/sku-loan-application/confirmed");
    } else {
      navigate("/loan-agreement");
    }
  };

  const submitAgreements = async () => {
    try {
      await axios.post(
        `${BASE_URL}/sku-finance/sn/sku-loans-docs-signed`,
        {
          skuIds,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    } catch (e) {
      throw new Error("Network response was not ok");
    }
  };

  const agreementRef = useRef(null);

  const handleSign = (isAuthenticated: boolean) => {
    if (isAuthenticated) {
      close();
      setRealUrl("");
      submitAgreements().then(() => {
        signedDocument.mutate(
          { documentId, token },
          {
            onSuccess: async (data) => {
              setRealUrl(data.url);
              setDocumentId(data.documentId);
              setCompletedAt(data.completedAt);
              setViewerKey((prevKey) => prevKey + 1);

              toast.success("Document Signed Successfully");
            },
            onError: (e) => {
              toast.error((e as Error).message);
            },
          }
        );
      });
    }
  };

  const openModal = () => {
    setViewerKey((prevKey) => prevKey + 1);
    open();
  };

  const closeModal = () => {
    setViewerKey((prevKey) => prevKey + 1);
    close();
  };

  return (
    <>
      {!isAuthenticated && (
        <div>
          <VQRVerifyPage
            onAuthenticatedChange={handleAuthenticatedChange}
            onNext={handleOnVerifyMeNext}
          />
        </div>
      )}
      {isAuthenticated && (
        <div className={classes.stepContent} style={{ marginTop: "0px" }}>
          <Text
            className={classes.headerText}
            style={{ marginTop: "10px", width: "140%" }}
          >
            SKU Supplemental Agreement & Deed of Charge
          </Text>
          {/* {ENV === "production" && (
            <>
              <div className={classes.download}>
                <Text className={classes.docSign}>Sign with DocuSign</Text>
              </div>

              {isOverlayVisible && (
                <div className={classes.overlay}>
                  <div
                    className={classes.document}
                    id="agreement"
                    ref={agreementRef}
                  >
                  </div>
                </div>
              )}
              <div className={classes.buttonDiv}>
                <div className={classes.buttonWrapper}>
                  <Button
                    disabled={isLoading}
                    onClick={handleOK}
                    textColor="#ffff"
                    backgroundColor="#338F86"
                    children="Sign agreement"
                    width="100%"
                  />
                </div>
              </div>
            </>
          )} */}

          {realUrl !== "" && (
            <>
              <DocViewer
                documents={docs}
                style={{ width: "100%", height: 500, overflow: "auto" }}
                key={viewerKey}
                className={classes.docviewer}
                prefetchMethod="GET"
                config={{
                  header: {
                    disableHeader: false,
                    disableFileName: true,
                    retainURLParams: false,
                  },
                  pdfVerticalScrollByDefault: true, // false as default
                }}
              />
              {completedAt === "" && (
                <div className={classes.buttonContainer}>
                  <Button
                    onClick={openModal}
                    textColor="white"
                    backgroundColor="#338F86"
                    children="Proceed to Sign"
                  />
                </div>
              )}

              {completedAt !== "" && (
                <div className={classes.buttonContainer}>
                  <Button
                    onClick={navigateNext}
                    textColor="white"
                    backgroundColor="#338F86"
                    children="Okay"
                  />
                </div>
              )}
            </>
          )}

          {realUrl == "" && (
            <div>
              <Center maw={400} h={500} mx="auto">
                <Loader />
              </Center>
            </div>
          )}

          <SignQRModal
            opened={opened}
            close={closeModal}
            onSignVerify={handleSign}
          />
        </div>
      )}
      <ToastContainer />
    </>
  );
};

export default LoanApplication;
