import { Modal, Text } from "@mantine/core";
import { FC, useState, useEffect, useRef } from "react";
import { Button, color } from "@funded-here-interface/common";
import { useStyle } from "./UploadInvoice.styles";
import useGetSku from "../../hooks/useGetSku";
import useUploadSku from "../../hooks/useUploadSKU";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DynamicTable, {
  DynamicTableSettings,
} from "@funded-here-interface/common/src/components/DynamicTable/DynamicTable";
import { useDisclosure } from "@mantine/hooks";
// import MembershipAgreement from "../../components/MembershipAgreement/MembershipAgreement";
import DistributorLayout from "../../layouts/DistributorLayout/DistributorLayout";

interface ApiResponse {
  skuId: string;
  orgName: string;
  upc: string;
  description: string;
}

const UploadInvoice: FC = () => {
  const { classes } = useStyle();
  const [isOpenUploadPoModal, setIsOpenUploadPoModal] = useState(false);
  const [isPoUploaded, setIsPoUploaded] = useState(false);
  const [selectedPOFile, setselectedPOFile] = useState<File | null>(null);
  const [skuData, setSkuData] = useState<ApiResponse[]>([]);
  const [selectedSKUId, setSelectedSKUId] = useState<string[]>([]);
  const useGetSKUmutation = useGetSku();
  const uploadSkuMutation = useUploadSku();
  const { token, agreedToMembershipAgreement } = useSelector(
    (state: RootState) => state.auth
  );
  const [opened, { close }] = useDisclosure(
    agreedToMembershipAgreement !== true
  );

  const handleCloseUploadPoModal = () => {
    setIsOpenUploadPoModal(false);
  };

  const fetchData = async () => {
    useGetSKUmutation.mutate(
      { token },
      {
        onSuccess: (data) => {
          setSkuData(data.data);
        },
        onError: (error: any) => {
          toast.error(error);
        },
      }
    );
  };

  const handleSubmit = async () => {
    uploadSkuMutation.mutate(
      { selectedSKUId: selectedSKUId, selectedPOFile: selectedPOFile, token },
      {
        onSuccess: () => {
          setSelectedSKUId([]);
          setIsOpenUploadPoModal(false);
          setIsPoUploaded(false);
          toast.success("Submitted successfully");
          fetchData();
        },
        onError: (error) => {
          toast.error((error as Error).message);
        },
      }
    );
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length > 0
    ) {
      const file = event.target.files[0];
      if (file) {
        setselectedPOFile(file);
        setIsPoUploaded(true);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const receivedCheckboxChangedMessage = (receivedData: any) => {
    const skuid = receivedData.skuId;

    setSelectedSKUId((prevSkuids) => {
      // Check if the skuid is already in the array
      const index = prevSkuids.indexOf(skuid);

      if (index === -1) {
        // If it's not in the array, add it
        return [...prevSkuids, skuid];
      } else {
        // If it's already in the array, remove it
        return prevSkuids.filter((id) => id !== skuid);
      }
    });
  };

  const displaySkuErrors = (receivedData: any) => {};

  const dynamicTableSetting: DynamicTableSettings = {
    data: [],
    column: [
      {
        heading: "Sku Id",
        dataKey: "skuId",
      },
      {
        heading: "UPC",
        dataKey: "upc",
      },

      {
        heading: "Merchant Org Name",
        dataKey: "orgName",
      },
      {
        heading: "Description",
        dataKey: "description",
      },
    ],
    hasCheckbox: true,
    enableHeaderCheckbox: true,
  };

  const handleUploadPo = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const handleSelectSKU = () => {
    if (selectedSKUId.length === 0) {
      toast.warn("Please select at least one SKU to upload invoice");
      return;
    }
    setIsOpenUploadPoModal(true);
  };

  const receivedAllCheckboxChangedMessage = (isSelectAll: any) => {
    if (isSelectAll === true) {
      if (skuData) {
        setSelectedSKUId(skuData.map((sku) => sku.skuId));
      }
    } else {
      setSelectedSKUId([]);
    }
  };

  return (
    <DistributorLayout
      step={0}
      content={
        <div className={classes.stepContent}>
          {/* <MembershipAgreement
            toCompleteOnboard={true}
            onError={(value: string) => {
              toast.error(value);
            }}
            opened={opened}
            close={close}
          /> */}
          <Text className={classes.headerText} style={{ marginTop: "10px" }}>
            Upload Invoice
          </Text>

          <Text className={classes.subHeaderText} style={{ marginTop: "10px" }}>
            Please choose the SKUs and merchants for which you wish to upload
            the corresponding invoices.{" "}
          </Text>

          <DynamicTable
            data={skuData}
            column={dynamicTableSetting.column}
            hasCheckbox={dynamicTableSetting.hasCheckbox}
            enableHeaderCheckbox={dynamicTableSetting.enableHeaderCheckbox}
            emitCheckBoxChangedMessage={receivedCheckboxChangedMessage}
            emitAllCheckBoxChangedMessage={receivedAllCheckboxChangedMessage}
            emitDatabaseViewButtonClickedMessage={displaySkuErrors}
            isBorderRequired={false}
          />

          <ToastContainer />

          <div className={classes.btnContainer}>
            <Button
              onClick={handleSelectSKU}
              backgroundColor={color.FHGREEN}
              textColor={color.WHITE}
              children="Upload"
              border={`1px solid ${color.FHGREEN}`}
              width="30%"
            />
          </div>

          <Modal
            opened={isOpenUploadPoModal}
            onClose={handleCloseUploadPoModal}
            title="Upload Invoice"
            size="45%"
          >
            Please upload a invoice in pdf format that contains the selected
            loan application details for our verification
            <div className={classes.buttonWrapper}>
              {isPoUploaded === false ? (
                <>
                  <Button
                    onClick={() => handleUploadPo()}
                    backgroundColor={color.FHGREEN}
                    textColor={color.WHITE}
                    children="Upload Invoice"
                    border={`1px solid ${color.FHGREEN}`}
                    width="150%"
                  />

                  <input
                    type="file"
                    accept="application/pdf"
                    ref={hiddenFileInput}
                    onChange={handleFileChange}
                    style={{ display: "none" }} // Hide the file input
                  />
                </>
              ) : (
                <Button
                  onClick={() => handleSubmit()}
                  backgroundColor={color.FHGREEN}
                  textColor={color.WHITE}
                  children="Submit"
                  border={`1px solid ${color.FHGREEN}`}
                  width="150%"
                />
              )}
            </div>
          </Modal>
        </div>
      }
    />
  );
};

export default UploadInvoice;
